import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import {
  DeleteRequest,
  PutRequest,
  useSamplesAirMicrobialSampleSetDeleteMutation,
  useSamplesAirMicrobialSampleSetListQuery,
  useSamplesAirMicrobialSampleSetPutMutation,
  useSamplesAirMicrobialSampleSetShowQuery,
} from "@/services/backend/samples/sample/air-microbial-service";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  Mutation,
  Query,
  useArtefactDBDelete,
  useArtefactDBGet,
  useArtefactDBGetAll,
  useArtefactDBPut,
} from "@/shared/indexdb/artefact-db-hooks";
import { AirMicrobialSampleSet } from "@/services/backend/samples/sample/air-microbial-sample";
import { FilterMode } from "@/shared/lib/filter/filter";
import { useParams } from "react-router-dom";

export function usePutAirMicrobialSampleSet(): Mutation<
  AirMicrobialSampleSet,
  AirMicrobialSampleSet
> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [
    putAirMicrobialSampleSetMutation,
    { isLoading, error, isSuccess, reset },
  ] = useSamplesAirMicrobialSampleSetPutMutation();

  const [
    putAirMicrobialSampleSetIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBPut<PutRequest, AirMicrobialSampleSet>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "airMicrobialSampleSet",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        putAirMicrobialSampleSetMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        putAirMicrobialSampleSetIndexedDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Put of sample set not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}

export function useGetAirMicrobialSampleSet(
  id: string,
): Query<AirMicrobialSampleSet> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesAirMicrobialSampleSetShowQuery({
    id,
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGet<AirMicrobialSampleSet>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "airMicrobialSampleSet",
    id,
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useListAirMicrobialSampleSets() {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesAirMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrderId!],
    },
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGetAll<AirMicrobialSampleSet>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "airMicrobialSampleSet",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData?.sampleSets,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useDeleteAirMicrobialSampleSet(): Mutation<
  DeleteRequest,
  null
> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [
    deleteAirMicrobialSampleSetMutation,
    { isLoading, error, isSuccess, reset },
  ] = useSamplesAirMicrobialSampleSetDeleteMutation();

  const [
    deleteAirMicrobialSampleSetIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBDelete(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "airMicrobialSampleSet",
  );
  const deleteIdb = async (request: DeleteRequest) => {
    const result = await deleteAirMicrobialSampleSetIndexedDB(request.id);
    if (result) {
      return result;
    }
    // This should never happen.
    throw new Error("Delete of sample set failed.");
  };

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        deleteAirMicrobialSampleSetMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        deleteIdb,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Delete of sample set not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}
