import {
  CalculationDocumentation,
  PutServiceDocRequest,
  ServiceDocumentation,
} from "@/services/backend/qpm/calculations/types";
import t from "@/lang/lang";
import { H4 } from "@/shared/components/ui/typography";
import { Button } from "@/shared/components/ui/button";
import { X } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import {
  useQpmCalcDocumentationListQuery,
  useQpmCalcServiceDocListQuery,
  useQpmCalcServiceDocumentationDeleteMutation,
  useQpmCalcServiceDocumentationPutMutation,
} from "@/services/backend/qpm/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { useEffect, useState } from "react";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { CalculationServiceType } from "@/services/backend/qpm/calculations/enum";
import { v4 } from "uuid";
import {
  TimeNumberInput,
  TimeUnit,
} from "@/routes/gemex/processes/_components/ui/time-number-input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/shared/components/ui/command";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import { Label } from "@/shared/components/ui/label";

export type DocumentationComponentProps = {
  calculationId: string;
  serviceCostId: string;
  serviceTypeKey: CalculationServiceType;
};

export function DocumentationComponent({
  calculationId,
  serviceCostId,
  serviceTypeKey,
}: DocumentationComponentProps) {
  const {
    data: selectedDocumentationList,
    error,
    isLoading,
  } = useQpmCalcServiceDocListQuery({
    calculation: {
      active: true,
      values: [calculationId],
    },
    serviceType: {
      active: true,
      values: [serviceTypeKey],
    },
    serviceCost: {
      active: true,
      values: [serviceCostId],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <div className="flex flex-col gap-2 p-4">
        <H4>{t("Dokumentation")}</H4>
        <Skeleton className="h-8 w-full" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {selectedDocumentationList &&
        selectedDocumentationList.docs.map((doc) => (
          <DocumentationRow key={doc.id} doc={doc} />
        ))}
      <DocumentationSelectorComponent
        calculationId={calculationId}
        serviceTypeKey={serviceTypeKey}
        serviceCostId={serviceCostId}
      />
    </div>
  );
}

export type DocumentationRowProps = {
  doc: ServiceDocumentation;
};

export function DocumentationRow({ doc }: DocumentationRowProps) {
  const { toast } = useToast();
  const [
    setDocumentation,
    { error: errorSetDocumentation, isLoading, reset: resetSetDocumentation },
  ] = useQpmCalcServiceDocumentationPutMutation();

  const [deleteDoc, { error: errorDelete, reset: resetDelete }] =
    useQpmCalcServiceDocumentationDeleteMutation();

  useEffect(() => {
    if (errorSetDocumentation) {
      toast({ ...parseRTKQueryError(errorSetDocumentation) });
      resetSetDocumentation();
    }
  }, [errorSetDocumentation, resetSetDocumentation, toast]);

  useEffect(() => {
    if (errorDelete) {
      toast({ ...parseRTKQueryError(errorDelete) });
      resetDelete();
    }
  }, [errorDelete, resetDelete, toast]);

  const onDelete = () => {
    deleteDoc({
      calculationId: doc.calculationId,
      serviceDocumentationId: doc.id,
    });
  };

  return (
    <div className="flex justify-between gap-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="h-7 w-7"
              onClick={onDelete}
            >
              <X className="h-5 w-5" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t("Entfernen")}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div className="flex w-full items-center justify-between gap-2 text-left">
        <Label htmlFor={`${doc.id}-duration-input`}>{t(doc.name)}</Label>
        <TimeNumberInput
          id={`${doc.id}-duration-input`}
          value={doc.duration}
          onChange={(val) => setDocumentation({ ...doc, duration: val })}
          timeUnit={TimeUnit.Minutes}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

export type DocumentationSelectorComponentProps = {
  calculationId: string;
  serviceCostId: string;
  serviceTypeKey: CalculationServiceType;
};

export function DocumentationSelectorComponent({
  calculationId,
  serviceCostId,
  serviceTypeKey,
}: DocumentationSelectorComponentProps) {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const {
    data: docList,
    error,
    isLoading,
  } = useQpmCalcDocumentationListQuery({
    calculationId,
  });

  const {
    data: selectedDocumentationList,
    error: errorSelectedDocumentationList,
    isLoading: isLoadingSelectedDocumentationList,
  } = useQpmCalcServiceDocListQuery({
    calculation: {
      active: true,
      values: [calculationId],
    },
    serviceType: {
      active: true,
      values: [serviceTypeKey],
    },
    serviceCost: {
      active: true,
      values: [serviceCostId],
    },
  });

  const [
    setDocumentation,
    { error: errorSetDocumentation, reset: resetSetDocumentation },
  ] = useQpmCalcServiceDocumentationPutMutation();

  const [filteredDocList, setFilteredDocList] = useState<
    CalculationDocumentation[]
  >([]);

  useEffect(() => {
    if (errorSetDocumentation) {
      toast({ ...parseRTKQueryError(errorSetDocumentation) });
      resetSetDocumentation();
    }
  }, [errorSetDocumentation, resetSetDocumentation, toast]);

  // Filter docList to exclude documentations present in selectedDocumentationList
  useEffect(() => {
    if (docList && selectedDocumentationList) {
      const filtered = docList.documentations.filter(
        (doc) =>
          !selectedDocumentationList.docs.some(
            (selectedDoc) => selectedDoc.documentationId === doc.id,
          ),
      );
      setFilteredDocList(filtered);
    }
  }, [docList, selectedDocumentationList]);

  const onSelectDocumentation = (val: string) => {
    const selectedDoc = docList?.documentations.find((doc) => doc.key === val);
    if (selectedDoc) {
      const request: PutServiceDocRequest = {
        id: v4(),
        calculationId,
        serviceCostId,
        serviceTypeKey,
        documentationId: selectedDoc.id,
        key: selectedDoc.key,
        duration: selectedDoc.duration,
        name: selectedDoc.name,
        createdAt: new Date(),
      };
      setDocumentation(request);
    }
  };

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (errorSelectedDocumentationList) {
    return <RTKQueryErrorAlert error={errorSelectedDocumentationList} />;
  }

  if (isLoading || isLoadingSelectedDocumentationList) {
    return <Skeleton className="h-8 w-[200px]" />;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[250px] justify-between"
        >
          {t("Dokumentation hinzufügen")}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0">
        <Command className="max-h-72">
          <CommandInput
            placeholder={t("Dokumentation suchen...")}
            className="h-9"
          />
          <ScrollArea className="w-full overflow-auto overscroll-y-contain rounded-md border">
            <CommandGroup key="command-group" heading={t("Dokumentation")}>
              {filteredDocList &&
                filteredDocList.map((doc) => (
                  <CommandItem
                    key={doc.id}
                    value={doc.key}
                    onSelect={() => {
                      setOpen(false);
                      onSelectDocumentation(doc.key);
                    }}
                  >
                    {doc.name}
                  </CommandItem>
                ))}
            </CommandGroup>
            <CommandEmpty>{t("Dokumentation nicht vorhanden.")}</CommandEmpty>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
