// Enums

// TODO
// eslint-disable-next-line max-classes-per-file
export enum Status {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export enum MonitorTypeKey {
  CheckPoint = "checkpoint",
  FlyExterminator = "fly-exterminator",
  ALoRa = "alora",
}

export enum RiskTypeKey {
  UNSPECIFIED = "",
  Rodent = "rodent",
  CrawlingInsect = "crawling-insect",
  FlyingInsect = "flying-insect",
  Bird = "bird",
}

export enum RiskLevelKey {
  None = "none",
  Low = "low",
  Medium = "medium",
  High = "high",
}

export enum QPMSearchParams {
  CollapsibleAreasOpen = "open",
}

export enum ArtefactDocType {
  Requirement = "BA",
  Calculation = "K",
  Offer = "AN",
}

export enum ConsumableTypeKey {
  Adhesive = "adhesive",
  Tube = "tube",
  Bait = "bait",
  BaitTox = "bait_tox",
  BaitNonTox = "bait_non_tox",
}

export enum PropertyUnit {
  None = "none",
  MonthlyHours = "hours/month",
  Weeks = "weeks",
  Hours = "hours",
  TimesPerYear = "times/year",
}

export enum PropertyType {
  None = "none",
  Time = "time",
  TimeFraction = "time-fraction",
  TimeAfter = "time-after",
  TimePeriod = "time-period",
}
