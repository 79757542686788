import { H4 } from "@/shared/components/ui/typography";
import { Textarea } from "@/shared/components/ui/textarea";
import { useState } from "react";
import { RequirementAdditionalInformation } from "@/services/backend/qpm/requirements/types";

interface AdditionalCommentsComponentProps {
  additionalInformation: RequirementAdditionalInformation;
  onUpdate: (updatedInfo: Partial<RequirementAdditionalInformation>) => void;
}

export function AdditionalCommentsComponent({
  additionalInformation,
  onUpdate,
}: AdditionalCommentsComponentProps) {
  const [additionalComment, setAdditionalComment] = useState<string>(
    additionalInformation.additionalNotes,
  );

  const onChangeAdditionalInfoComment = (value: string) => {
    setAdditionalComment(value);
    onUpdate({ additionalNotes: value });
  };

  return (
    <div className="flex flex-col gap-2">
      <H4>Bemerkungen</H4>
      <div className="flex">
        <Textarea
          placeholder="Weitere Anmerkungen..."
          value={additionalComment}
          onChange={(e) => {
            onChangeAdditionalInfoComment(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
