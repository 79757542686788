import { H4 } from "@/shared/components/ui/typography";
import { PropertyFields } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/property-fields";
import { useState } from "react";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { Button } from "@/shared/components/ui/button";
import {
  RequirementAdditionalInformation,
  RequirementCycleType,
} from "@/services/backend/qpm/requirements/types";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import {
  MonitorTypeKey,
  PropertyUnit,
} from "@/services/backend/qpm/shared/enums";
import { CalculationServiceEntryUtil } from "@/services/backend/qpm/calculations/CalculationServiceEntryUtil";
import { CalculationServiceEntryType } from "@/services/backend/qpm/calculations/enum";
import { ServicePropertyUtil } from "@/services/backend/qpm/shared/servicePropertyUtil";

export type CycleType = {
  id: string;
  isSelected: boolean;
  cycleType: RequirementCycleType;
  name: string;
};

export type CycleTypeList = CycleType[];

export type CycleComponentProps = {
  additionalInformation: RequirementAdditionalInformation;
  onUpdate: (updatedInfo: Partial<RequirementAdditionalInformation>) => void;
};

export function CycleComponent({
  additionalInformation,
  onUpdate,
}: CycleComponentProps) {
  const initialCycleTypes: CycleTypeList = [
    {
      id: "1",
      isSelected:
        additionalInformation.cycleType === RequirementCycleType.InitialControl,
      cycleType: RequirementCycleType.InitialControl,
      name: "EB",
    },
    {
      id: "2",
      isSelected:
        additionalInformation.cycleType ===
        RequirementCycleType.InitialControlEradicationMonitoring,
      cycleType: RequirementCycleType.InitialControlEradicationMonitoring,
      name: "EB mit TK",
    },
  ];

  const [selectedCycleTypes, setSelectedCycleTypes] =
    useState<CycleTypeList>(initialCycleTypes);

  const handleClick = (type: RequirementCycleType) => {
    const newCycleTypes: CycleTypeList = selectedCycleTypes.map((ct) =>
      ct.cycleType === type
        ? { ...ct, isSelected: !ct.isSelected }
        : { ...ct, isSelected: false },
    );
    setSelectedCycleTypes(newCycleTypes);

    onUpdate({ cycleType: type });
  };

  return (
    <div className="flex w-full flex-col gap-2 border-b py-2">
      <H4>Turnus</H4>
      <div className="flex w-full items-center gap-4">
        {selectedCycleTypes.map((cycle) => (
          <div key={cycle.id} className="flex items-center gap-1 p-1">
            <Checkbox
              checked={cycle.isSelected}
              onClick={() => handleClick(cycle.cycleType)}
            />
            <Button
              className="bg-transparent p-1 text-base text-black  hover:bg-transparent hover:text-primary"
              onClick={() => handleClick(cycle.cycleType)}
            >
              {cycle.name}
            </Button>
          </div>
        ))}
        <div className="flex w-full gap-2">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={additionalInformation.qpmCycleAmountPerYear > 0}
              onClick={() =>
                additionalInformation.qpmCycleAmountPerYear > 0
                  ? onUpdate({
                      qpmCycleAmountPerYear: 0,
                    })
                  : onUpdate({
                      qpmCycleAmountPerYear: 1,
                    })
              }
              className=""
            />
            <div className="flex-nowrap font-semibold">QPM</div>
          </div>
          <PropertyFields
            value={additionalInformation.qpmCycleAmountPerYear}
            displayUnit={ServicePropertyUtil.toString(
              PropertyUnit.TimesPerYear,
            )}
            onValueChange={(val) => onUpdate({ qpmCycleAmountPerYear: val })}
          />
        </div>
        <div className="flex w-full gap-2">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={additionalInformation.aloraAmountOfMeasures > 0}
              onClick={() =>
                additionalInformation.aloraAmountOfMeasures > 0
                  ? onUpdate({
                      aloraAmountOfMeasures: 0,
                    })
                  : onUpdate({
                      aloraAmountOfMeasures: 1,
                    })
              }
              className=""
            />
            <div className="flex-nowrap font-semibold">
              {MonitorTypeUtil.toString(MonitorTypeKey.ALoRa)}
            </div>
          </div>
          <PropertyFields
            value={additionalInformation.aloraAmountOfMeasures}
            displayUnit={ServicePropertyUtil.toString(
              PropertyUnit.TimesPerYear,
            )}
            onValueChange={(val) => onUpdate({ aloraAmountOfMeasures: val })}
          />
        </div>
        <div className="flex w-full gap-2">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={additionalInformation.scbAmountOfMeasures > 0}
              onClick={() =>
                additionalInformation.scbAmountOfMeasures > 0
                  ? onUpdate({
                      scbAmountOfMeasures: 0,
                    })
                  : onUpdate({
                      scbAmountOfMeasures: 12,
                    })
              }
              className=""
            />
            <div className="flex-nowrap font-semibold">
              {CalculationServiceEntryUtil.toString(
                CalculationServiceEntryType.CheckPointSCB,
              )}
            </div>
          </div>
          <PropertyFields
            value={additionalInformation.scbAmountOfMeasures}
            displayUnit={ServicePropertyUtil.toString(
              PropertyUnit.TimesPerYear,
            )}
            onValueChange={(val) => onUpdate({ aloraAmountOfMeasures: val })}
            disabled
          />
        </div>
      </div>
    </div>
  );
}
