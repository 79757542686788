import { useStaleContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/stale-context";
import { useCallback, useMemo, useState } from "react";
import {
  artefactDbDelete,
  artefactDbGet,
  artefactDbGetAll,
  artefactDbPut,
} from "@/shared/indexdb/artefact-db";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";

/**
 * Mutation defines a consistent interface to encapsulate
 * rtk query mutations and indexedDB mutations.
 */
export type Mutation<Req, Res> = [
  (entity: Req) => Promise<{ data: Res } | { error: unknown }> | void,
  { isLoading: boolean; error: unknown; isSuccess: boolean; reset: () => void },
];

export type Query<Res> = {
  data: Res | undefined;
  isLoading: boolean;
  error: unknown;
};

// TODO
//  * the current state handling in the hooks allows inconsistencies
//  * It is possible for isLoading to be false, but the data is not yet
//    available.
//  * This must be refactored to be all in one state object.

export function useArtefactDBPut<Req, Res>(
  artefactKind: ArtefactKind,
  artefactId: string,
  storeName: string,
): Mutation<Req, Res> {
  const { addStale } = useStaleContext();
  const [state, setState] = useState<{
    isLoading: boolean;
    error: unknown;
    isSuccess: boolean;
  }>({
    isLoading: false,
    error: undefined,
    isSuccess: false,
  });

  const reset = useCallback(() => {
    setState({ isLoading: false, error: undefined, isSuccess: false });
  }, []);

  const put = useCallback(
    (request: Req) => {
      setState({ isLoading: true, error: undefined, isSuccess: false });
      artefactDbPut(artefactKind, artefactId, storeName, request)
        .then(() => {
          addStale(storeName);
          setState({ isLoading: false, error: undefined, isSuccess: true });
        })
        .catch(() => {
          setState({
            isLoading: false,
            error: { status: 500 },
            isSuccess: false,
          });
        });
    },
    [addStale, artefactId, artefactKind, storeName],
  );

  const stateValue = useMemo(
    () => ({
      isLoading: state.isLoading,
      error: state.error,
      isSuccess: state.isSuccess,
      reset,
    }),
    [state.isLoading, state.error, state.isSuccess, reset],
  );

  return [put, stateValue];
}

export function useArtefactDBGet<Res>(
  artefactKind: ArtefactKind,
  artefactId: string,
  storeName: string,
  key: string,
): Query<Res> {
  const { offlineState } = useOfflineContext();
  const { stale, removeStale } = useStaleContext();
  const [dataKey, setDataKey] = useState<string | undefined>();
  const [data, setData] = useState<Res | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  // Assumption: IndexedDB is only used when offline. Running
  // the query online without data or potentially
  // uninitialized database, errors could happen.
  // Updates are triggered by the stale context
  // similar "tags" in RTKQuery.
  if (
    offlineState === OfflineState.OFFLINE &&
    (stale.includes(storeName) || key !== dataKey) &&
    !isLoading
  ) {
    setIsLoading(true);
    artefactDbGet<Res>(artefactKind, artefactId, storeName, key)
      .then((result) => {
        if (result === null) {
          setError({ status: 404 });
        } else {
          setData(result);
          setDataKey(key);
        }
        removeStale(storeName);
      })
      .catch(() => {
        setError({ status: 500 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return { data, isLoading, error };
}

export function useArtefactDBGetAll<Res>(
  artefactKind: ArtefactKind,
  artefactId: string,
  storeName: string,
): Query<Res[]> {
  const { offlineState } = useOfflineContext();
  const { stale, removeStale } = useStaleContext();
  const [data, setData] = useState<Res[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  // Assumption: IndexedDB is only used when offline. Running
  // the query online without data or potentially
  // uninitialized database, errors could happen.
  // Updates are triggered by the stale context
  // similar "tags" in RTKQuery.
  if (
    offlineState === OfflineState.OFFLINE &&
    (stale.includes(storeName) || data === undefined) &&
    !isLoading
  ) {
    setIsLoading(true);
    artefactDbGetAll<Res>(artefactKind, artefactId, storeName)
      .then((result) => {
        setData(result);
        removeStale(storeName);
      })
      .catch(() => {
        setError({ status: 500 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return { data, isLoading, error };
}

export function useArtefactDBDelete(
  artefactKind: ArtefactKind,
  artefactId: string,
  storeName: string,
): Mutation<string, null> {
  const { addStale } = useStaleContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const [isSuccess, setIsSuccess] = useState(false);
  const reset = () => {
    setError(undefined);
    setIsSuccess(false);
  };

  const del = (key: string) => {
    setIsLoading(true);
    setError(undefined);
    setIsSuccess(false);

    artefactDbDelete(artefactKind, artefactId, storeName, key)
      .then(() => {
        addStale(storeName);
      })
      .catch(() => {
        setError({ status: 500 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [del, { isLoading, error, isSuccess, reset }];
}
