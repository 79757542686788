import { Soiling } from "@/services/backend/htz/inspection/soiling";
import { Item } from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import { EntityId, UUID } from "@/shared/nidavellir/types/entity-id";

export interface VisualAssessment {
  id: UUID;
  airHandlingUnitId: EntityId;
  componentId: string;
  element: string;
  soiling: Soiling;
  deficiency: string;
  recommendation: string;
  comment: string;
  createdAt: string;
}

export function newVisual(
  id: UUID,
  airHandlingUnitId: UUID,
  componentId: UUID,
): VisualAssessment {
  return {
    id,
    airHandlingUnitId,
    componentId,
    element: "",
    soiling: Soiling.None,
    deficiency: "",
    recommendation: "",
    comment: "",
    createdAt: new Date().toISOString(),
  };
}

export function fromCatalogItem(
  id: string,
  airHandlingUnitId: EntityId,
  componentId: string,
  item: Item,
): VisualAssessment {
  return {
    ...item,
    id,
    airHandlingUnitId,
    componentId,
    comment: "",
    createdAt: new Date().toISOString(),
  };
}
