import { H4 } from "@/shared/components/ui/typography";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { useEffect } from "react";
import { Button } from "@/shared/components/ui/button";
import {
  useQpmRequirementPestCategoryListQuery,
  useQpmRequirementSetPestPresentMutation,
} from "@/services/backend/qpm/requirements/service";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Requirement } from "@/services/backend/qpm/requirements/types";
import t from "@/lang/lang";

type PestComponentProps = {
  requirement: Requirement;
};

export function PestsComponent({ requirement }: PestComponentProps) {
  const { toast } = useToast();

  const { data: selectedPestCategories, error: errorSelectedPestCategory } =
    useQpmRequirementPestCategoryListQuery({
      artefactMetaId: requirement.artefactData.id,
    });

  const [
    setPestPresent,
    {
      error: errorAddRequirementPestCategory,
      reset: resetAddRequirementPestCategory,
    },
  ] = useQpmRequirementSetPestPresentMutation();

  useEffect(() => {
    if (errorAddRequirementPestCategory) {
      toast({ ...parseRTKQueryError(errorAddRequirementPestCategory) });
      resetAddRequirementPestCategory();
    }
  }, [errorAddRequirementPestCategory, resetAddRequirementPestCategory, toast]);

  const handleClick = (id: string, active: boolean) => {
    setPestPresent({
      id,
      pestPresent: active,
    });
  };

  if (errorSelectedPestCategory) {
    return <RTKQueryErrorAlert error={errorSelectedPestCategory} />;
  }

  if (!selectedPestCategories) {
    return <div>{t("Fehler beim Laden der Schädlingsarten")}</div>;
  }

  return (
    <div className="flex flex-col gap-2 border-b py-2">
      <H4>{t("Schädlinge")}</H4>
      <div className="flex flex-col md:grid md:grid-cols-3 xl:grid-cols-5">
        {selectedPestCategories.pestCategories.map((pestCategory) => (
          <div key={pestCategory.id} className="flex items-center gap-1 p-1">
            <Checkbox
              checked={pestCategory.pestPresent}
              onClick={() =>
                handleClick(pestCategory.id, !pestCategory.pestPresent)
              }
              disabled={pestCategory.immutable}
            />
            <Button
              className="bg-transparent p-1 text-base text-black hover:bg-transparent hover:text-primary"
              onClick={() =>
                handleClick(pestCategory.id, !pestCategory.pestPresent)
              }
              disabled={pestCategory.immutable}
            >
              {pestCategory.name}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
