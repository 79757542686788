import { PropertyUnit } from "@/services/backend/qpm/shared/enums";

export class ServicePropertyUtil {
  private static descriptions: { [key in PropertyUnit]: string } = {
    [PropertyUnit.None]: "",
    [PropertyUnit.Hours]: "hh:mm:ss",
    [PropertyUnit.Weeks]: "Wochen",
    [PropertyUnit.MonthlyHours]: "Std./Monat",
    [PropertyUnit.TimesPerYear]: "x jährlich",
  };

  static toString(propertyUnit: PropertyUnit) {
    return this.descriptions[propertyUnit];
  }

  static inputStep(unit: PropertyUnit): string {
    switch (unit) {
      case PropertyUnit.Weeks:
      case PropertyUnit.TimesPerYear:
        return "1";
      default:
        return "0.5";
    }
  }
}
