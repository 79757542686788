import {
  MaterialPrice,
  PutMaterialPriceRequest,
  UpdateServicePositionAmountRequest,
} from "@/services/backend/qpm/calculations/types";
import {
  useQpmCalcServiceCostPositionAmountPostMutation,
  useQpmCalculationGetMaterialCostQuery,
  useQpmCalculationSetMaterialPriceMutation,
} from "@/services/backend/qpm/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  FloatNumberInput,
  IntNumberInput,
  Value,
} from "@/routes/gemex/processes/_components/ui/number-input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useEffect, useState } from "react";

export type MaterialPriceListComponentProps = {
  calculationId: string;
  monitorTypeKey: MonitorTypeKey;
};

export function MaterialPriceListComponent({
  calculationId,
  monitorTypeKey,
}: MaterialPriceListComponentProps) {
  const { data: materials, error: isErrorMaterials } =
    useQpmCalculationGetMaterialCostQuery({
      calculationId: {
        active: true,
        values: [calculationId],
      },
      monitorType: {
        active: true,
        values: [monitorTypeKey],
      },
    });

  if (isErrorMaterials) {
    return <RTKQueryErrorAlert error={isErrorMaterials} />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/4">{t("Material")}</TableHead>
          <TableHead className="w-1/4 text-right">{t("Anzahl")}</TableHead>
          <TableHead className="w-1/4 text-right">
            {t("Preis pro Stück")}
          </TableHead>
          <TableHead className="w-1/4 text-right">{t("Gesamtpreis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {materials &&
          materials.materialCost[monitorTypeKey] &&
          materials.materialCost[monitorTypeKey].positions.map((material) => (
            <MaterialPriceRow
              key={material.monitor.id}
              calculationId={calculationId}
              material={material}
            />
          ))}
      </TableBody>
    </Table>
  );
}

export type MaterialPriceRowProps = {
  calculationId: string;
  material: MaterialPrice;
};

function MaterialPriceRow({ calculationId, material }: MaterialPriceRowProps) {
  const [amount, setAmount] = useState(material.amount);
  const [price, setPrice] = useState(material.basePrice);
  const [request, setRequest] = useState<PutMaterialPriceRequest>({
    calculationId,
    basePrice: material.basePrice,
    monitor: material.monitor,
  });

  const [updateAmountRequest, setUpdateAmountRequest] =
    useState<UpdateServicePositionAmountRequest>({
      calculationId,
      monitorId: material.monitor.id,
      amount: material.amount,
    });

  const [setMaterialPrice, { isLoading, error, reset, isSuccess }] =
    useQpmCalculationSetMaterialPriceMutation();

  const [
    updateAmount,
    {
      isLoading: isLoadingUpdateAmount,
      error: errorUpdateAmount,
      reset: resetUpdateAmount,
      isSuccess: isSuccessUpdateAmount,
    },
  ] = useQpmCalcServiceCostPositionAmountPostMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setMaterialPrice,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    updateAmountRequest,
    updateAmount,
    isLoadingUpdateAmount,
    errorUpdateAmount,
    isSuccessUpdateAmount,
    resetUpdateAmount,
    500,
  );

  useEffect(() => {
    // Update amount if the position prop changes
    setAmount(material.amount);
  }, [material.amount]);

  useEffect(() => {
    // Update price if the position prop changes
    setPrice(material.basePrice);
  }, [material.basePrice]);

  return (
    <TableRow>
      <TableCell className="py-2 font-medium">{request.monitor.name}</TableCell>
      <TableCell className="py-2 text-right">
        <IntNumberInput
          className="justify-self-end"
          value={amount}
          onChange={(val) => {
            const a = val < 0 ? 0 : val;
            // setAmount(a);
            setUpdateAmountRequest({
              ...updateAmountRequest,
              amount: a,
            });
          }}
          disabled={isLoading}
        />
      </TableCell>
      <TableCell className="py-2 text-right">
        <FloatNumberInput
          className="justify-self-end"
          value={price}
          fractions={2}
          onChange={(val) => {
            setRequest({
              ...request,
              basePrice: val,
            });
          }}
          disabled={isLoading}
        />
      </TableCell>
      <TableCell className="py-2 text-right">
        <Value value={material.totalPrice} />
      </TableCell>
    </TableRow>
  );
}
