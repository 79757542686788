import { useQpmCalculationShowQuery } from "@/services/backend/qpm/calculations/service";
import { Outlet, useParams } from "react-router-dom";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { SelectRequirementComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/select-requirement-component";
import { CalculationNavLinkType } from "@/services/backend/qpm/calculations/enum";

export function CalculationRoute() {
  const { calculationId, processId } = useParams();
  const {
    data: calculation,
    isLoading,
    error,
  } = useQpmCalculationShowQuery(
    { artefactMetaId: calculationId! },
    { skip: !calculationId },
  );

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    calculation && (
      <div className="flex flex-col">
        <SelectRequirementComponent
          calculation={calculation}
          customerId={calculation.artefactData.customerId}
          processId={processId!}
        />
        <PersistenceContextProvider>
          <MilestonePageContent>
            <MilestonePageContentMenu>
              <div className="flex w-full flex-col space-y-3">
                <div className="flex w-full items-center justify-between">
                  <TabsNavList>
                    <TabsNavLink to={CalculationNavLinkType.MaterialCost}>
                      {t("Materialkosten")}
                    </TabsNavLink>
                    <TabsNavLink to={CalculationNavLinkType.Installation}>
                      {t("Einrichtung")}
                    </TabsNavLink>
                    <TabsNavLink to={CalculationNavLinkType.Maintenance}>
                      {t("Service")}
                    </TabsNavLink>
                    <TabsNavLink to={CalculationNavLinkType.Rental}>
                      {t("Miete")}
                    </TabsNavLink>
                    <TabsNavLink to={CalculationNavLinkType.TotalOverview}>
                      {t("Gesamtübersicht")}
                    </TabsNavLink>
                  </TabsNavList>
                  <div className="mr-2">
                    <PersistenceStateUsingContext />
                  </div>
                </div>
              </div>
            </MilestonePageContentMenu>
            <Outlet />
          </MilestonePageContent>
        </PersistenceContextProvider>
      </div>
    )
  );
}
