import {
  Position,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";
import { StructuralAssessmentCatalog } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { VisualAssessmentCatalog } from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import { Offer } from "@/services/backend/htz/offers/offer";
import { Order } from "@/services/backend/htz/orders/order";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";
import {
  useHtzWorkOrderListPositionQuery,
  useHtzWorkOrderShowQuery,
} from "@/services/backend/htz/work-order/service";
import {
  useHtzPositionCatalogShowActiveCatalogQuery,
  useHtzPositionCatalogShowQuery,
} from "@/services/backend/htz/position-catalog/service";
import {
  useHtzAhuComponentCatalogShowActiveCatalogQuery,
  useHtzAhuComponentCatalogShowQuery,
} from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import {
  useHtzStructuralAssessmentCatalogShowActiveCatalogQuery,
  useHtzStructuralAssessmentCatalogShowQuery,
} from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog-service";
import {
  useHtzVisualAssessmentCatalogShowActiveCatalogQuery,
  useHtzVisualAssessmentCatalogShowQuery,
} from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog-service";
import { useHtzAirHandlingUnitListQuery } from "@/services/backend/htz/ahu/service";
import { useHtzOrderListQuery } from "@/services/backend/htz/orders/service";
import { useHtzOfferListQuery } from "@/services/backend/htz/offers/service";
import { Laboratory } from "@/services/backend/samples/lab/laboratory";
import { Device } from "@/services/backend/samples/device/device";
import { useSamplesLaboratoryListQuery } from "@/services/backend/samples/lab/service";
import { useSamplesDeviceListQuery } from "@/services/backend/samples/device/service";
import { artefactDbGet } from "@/shared/indexdb/artefact-db";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { useState } from "react";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { useStaleContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/stale-context";

export interface Dependency {
  name: string;
  value: Dependencies;
}

export interface Dependencies {
  ahuComponentCatalog: AhuComponentCatalog;
  devices: Device[];
  laboratories: Laboratory[];
  positionCatalog: PositionCatalog;
  processOffers: Offer[];
  processOrders: Order[];
  structuralAssessmentCatalog: StructuralAssessmentCatalog;
  visualAssessmentCatalog: VisualAssessmentCatalog;
}

export interface WorkOrderContextProviderDependencies {
  isLoading: boolean;
  error: unknown;
  // mutable entities
  airHandlingUnits: AirHandlingUnit[] | undefined;
  positions: Position[] | undefined;
  workOrder: WorkOrder | undefined;
  // pure dependencies
  ahuComponentCatalog: AhuComponentCatalog | undefined;
  devices: Device[] | undefined;
  laboratories: Laboratory[] | undefined;
  positionCatalog: PositionCatalog | undefined;
  processOffers: Offer[] | undefined;
  processOrders: Order[] | undefined;
  structuralAssessmentCatalog: StructuralAssessmentCatalog | undefined;
  visualAssessmentCatalog: VisualAssessmentCatalog | undefined;
}

export function useWorkOrderContextDependencies(workOrderId: string) {
  const { offlineState } = useOfflineContext();

  const {
    isLoading: onlineIsLoading,
    error: onlineError,
    workOrder: onlineWorkOrder,
    ahuComponentCatalog: onlineAhuComponentCatalog,
    devices: onlineDevices,
    laboratories: onlineLaboratories,
    processOffers: onlineProcessOffers,
    processOrders: onlineProcessOrders,
    structuralAssessmentCatalog: onlineStructuralAssessmentCatalog,
    visualAssessmentCatalog: onlineVisualAssessmentCatalog,
  } = useWorkOrderContextProviderDependenciesOnline(workOrderId);

  // TODO, remove offline -> only via hooks
  const {
    isLoading: offlineIsLoading,
    error: offlineError,
    workOrder: offlineWorkOrder,
    ahuComponentCatalog: offlineAhuComponentCatalog,
    devices: offlineDevices,
    laboratories: offlineLaboratories,
    processOffers: offlineProcessOffers,
    processOrders: offlineProcessOrders,
    structuralAssessmentCatalog: offlineStructuralAssessmentCatalog,
    visualAssessmentCatalog: offlineVisualAssessmentCatalog,
  } = useWorkOrderContextProviderDependenciesOffline(workOrderId);

  return {
    isLoading:
      offlineState === OfflineState.OFFLINE
        ? offlineIsLoading
        : onlineIsLoading,
    error: offlineState === OfflineState.OFFLINE ? offlineError : onlineError,
    workOrder:
      offlineState === OfflineState.OFFLINE
        ? offlineWorkOrder
        : onlineWorkOrder,
    ahuComponentCatalog:
      offlineState === OfflineState.OFFLINE
        ? offlineAhuComponentCatalog
        : onlineAhuComponentCatalog,
    devices:
      offlineState === OfflineState.OFFLINE ? offlineDevices : onlineDevices,
    laboratories:
      offlineState === OfflineState.OFFLINE
        ? offlineLaboratories
        : onlineLaboratories,
    processOffers:
      offlineState === OfflineState.OFFLINE
        ? offlineProcessOffers
        : onlineProcessOffers,
    processOrders:
      offlineState === OfflineState.OFFLINE
        ? offlineProcessOrders
        : onlineProcessOrders,
    structuralAssessmentCatalog:
      offlineState === OfflineState.OFFLINE
        ? offlineStructuralAssessmentCatalog
        : onlineStructuralAssessmentCatalog,
    visualAssessmentCatalog:
      offlineState === OfflineState.OFFLINE
        ? offlineVisualAssessmentCatalog
        : onlineVisualAssessmentCatalog,
  };
}

export function useWorkOrderContextProviderDependenciesOnline(
  workOrderId: string,
): WorkOrderContextProviderDependencies {
  const {
    data: workOrder,
    isLoading: workOrderIsLoading,
    error: workOrderError,
  } = useHtzWorkOrderShowQuery({ id: workOrderId });

  const {
    data: positionList,
    isLoading: positionsIsLoading,
    error: positionsError,
  } = useHtzWorkOrderListPositionQuery({
    workOrder: {
      active: true,
      values: [workOrderId],
    },
  });
  const positions = positionList?.positions ?? [];

  const {
    data: activePositionCatalog,
    isLoading: activePositionCatalogIsLoading,
    error: activePositionCatalogError,
  } = useHtzPositionCatalogShowActiveCatalogQuery(
    {
      companyId: workOrder?.companyId ?? "",
    },
    {
      skip: workOrder === undefined,
    },
  );

  const {
    data: positionCatalog,
    isLoading: positionCatalogIsLoading,
    error: positionCatalogError,
  } = useHtzPositionCatalogShowQuery(
    {
      id: activePositionCatalog?.catalogId ?? "",
    },
    {
      skip:
        activePositionCatalog === undefined ||
        activePositionCatalog?.catalogId === null,
    },
  );

  const {
    data: activeAhuComponentCatalog,
    isLoading: activeAhuComponentCatalogIsLoading,
    error: activeAhuComponentCatalogError,
  } = useHtzAhuComponentCatalogShowActiveCatalogQuery({});

  const {
    data: ahuComponentCatalog,
    isLoading: ahuComponentCatalogIsLoading,
    error: ahuComponentCatalogError,
  } = useHtzAhuComponentCatalogShowQuery(
    {
      id: activeAhuComponentCatalog?.catalogId ?? "",
    },
    {
      skip:
        activeAhuComponentCatalog === undefined ||
        activeAhuComponentCatalog?.catalogId === null,
    },
  );

  const {
    data: activeSACatalog,
    isLoading: activeSACatalogIsLoading,
    error: activeSACatalogError,
  } = useHtzStructuralAssessmentCatalogShowActiveCatalogQuery({});

  const {
    data: structuralAssessmentCatalog,
    isLoading: structuralAssessmentCatalogIsLoading,
    error: structuralAssessmentCatalogError,
  } = useHtzStructuralAssessmentCatalogShowQuery(
    {
      id: activeSACatalog?.catalogId ?? "",
    },
    {
      skip:
        activeSACatalog === undefined || activeSACatalog?.catalogId === null,
    },
  );

  const {
    data: activeVACatalog,
    isLoading: activeVACatalogIsLoading,
    error: activeVACatalogError,
  } = useHtzVisualAssessmentCatalogShowActiveCatalogQuery({});

  const {
    data: visualAssessmentCatalog,
    isLoading: visualAssessmentCatalogIsLoading,
    error: visualAssessmentCatalogError,
  } = useHtzVisualAssessmentCatalogShowQuery(
    {
      id: activeVACatalog?.catalogId ?? "",
    },
    {
      skip:
        activeVACatalog === undefined || activeVACatalog?.catalogId === null,
    },
  );

  const {
    data: ahuList,
    isLoading: ahuIsLoading,
    error: ahuError,
  } = useHtzAirHandlingUnitListQuery(
    {
      customer: {
        active: workOrder !== undefined,
        values: [workOrder?.customerId ?? ""],
      },
    },
    {
      skip: workOrder === undefined,
    },
  );
  const airHandlingUnits = ahuList?.airHandlingUnits ?? [];

  const {
    data: orderList,
    isLoading: orderIsLoading,
    error: orderError,
  } = useHtzOrderListQuery(
    {
      process: {
        active: true,
        values: [workOrder?.processId ?? ""],
      },
      immutable: {
        active: false,
        values: [true],
      },
    },
    {
      skip: workOrder === undefined,
    },
  );
  const processOrders = orderList?.orders ?? [];

  const {
    data: offerList,
    isLoading: offerIsLoading,
    error: offerError,
  } = useHtzOfferListQuery(
    {
      process: {
        active: true,
        values: [workOrder?.processId ?? ""],
      },
      immutable: {
        active: true,
        values: [true],
      },
    },
    {
      skip: workOrder === undefined,
    },
  );
  const processOffers = offerList?.offers ?? [];

  const {
    data: laboratoryList,
    isLoading: laboratoryIsLoading,
    error: laboratoryError,
  } = useSamplesLaboratoryListQuery({});
  const laboratories = laboratoryList?.labs ?? [];

  const {
    data: deviceList,
    isLoading: deviceIsLoading,
    error: deviceError,
  } = useSamplesDeviceListQuery({});
  const devices = deviceList?.devices ?? [];

  const isLoading =
    workOrderIsLoading ||
    positionsIsLoading ||
    activePositionCatalogIsLoading ||
    positionCatalogIsLoading ||
    activeAhuComponentCatalogIsLoading ||
    ahuComponentCatalogIsLoading ||
    activeSACatalogIsLoading ||
    structuralAssessmentCatalogIsLoading ||
    activeVACatalogIsLoading ||
    visualAssessmentCatalogIsLoading ||
    ahuIsLoading ||
    orderIsLoading ||
    offerIsLoading ||
    laboratoryIsLoading ||
    deviceIsLoading;

  const error = (() => {
    switch (true) {
      case workOrderError !== undefined:
        return workOrderError;
      case positionsError !== undefined:
        return positionsError;
      case activePositionCatalogError !== undefined:
        return activePositionCatalogError;
      case positionCatalogError !== undefined:
        return positionCatalogError;
      case activeAhuComponentCatalogError !== undefined:
        return activeAhuComponentCatalogError;
      case ahuComponentCatalogError !== undefined:
        return ahuComponentCatalogError;
      case activeSACatalogError !== undefined:
        return activeSACatalogError;
      case structuralAssessmentCatalogError !== undefined:
        return structuralAssessmentCatalogError;
      case activeVACatalogError !== undefined:
        return activeVACatalogError;
      case visualAssessmentCatalogError !== undefined:
        return visualAssessmentCatalogError;
      case ahuError !== undefined:
        return ahuError;
      case orderError !== undefined:
        return orderError;
      case offerError !== undefined:
        return offerError;
      case laboratoryError !== undefined:
        return laboratoryError;
      case deviceError !== undefined:
        return deviceError;
      case activePositionCatalog?.catalogId === null:
        // fake 404 error
        return {
          status: 404,
        };
      default:
        return undefined;
    }
  })();

  return {
    workOrder,
    positions,
    positionCatalog,
    ahuComponentCatalog,
    structuralAssessmentCatalog,
    visualAssessmentCatalog,
    airHandlingUnits,
    processOffers,
    processOrders,
    laboratories,
    devices,
    isLoading,
    error,
  };
}

function useWorkOrderContextProviderDependenciesOffline(workOrderId: string) {
  const { offlineState, setOfflineState } = useOfflineContext();
  const { stale, removeStale } = useStaleContext();

  // Mutable entities
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [workOrderLoading, setWorkOrderLoading] = useState(false);

  // Pure dependencies
  const [positionCatalog, setPositionCatalog] = useState<PositionCatalog>();
  const [ahuComponentCatalog, setAhuComponentCatalog] =
    useState<AhuComponentCatalog>();
  const [structuralAssessmentCatalog, setStructuralAssessmentCatalog] =
    useState<StructuralAssessmentCatalog>();
  const [visualAssessmentCatalog, setVisualAssessmentCatalog] =
    useState<VisualAssessmentCatalog>();
  const [processOffers, setProcessOffers] = useState<Offer[]>();
  const [processOrders, setProcessOrders] = useState<Order[]>();
  const [laboratories, setLaboratories] = useState<Laboratory[]>();
  const [devices, setDevices] = useState<Device[]>();
  const [dependenciesLoading, setDependenciesLoading] = useState(false);

  const isLoading = workOrderLoading || dependenciesLoading;
  const [error, setError] = useState<{ status: number }>();

  // If offline, there is no need to call indexedDB.
  // Doing so would lead to errors, since it is not
  // initialized yet.
  // The return value will be ignored by the caller.
  if (offlineState !== OfflineState.OFFLINE) {
    return {
      workOrder,
      positionCatalog,
      ahuComponentCatalog,
      structuralAssessmentCatalog,
      visualAssessmentCatalog,
      processOffers,
      processOrders,
      laboratories,
      devices,
      isLoading: true,
      error: { status: 404 },
    };
  }

  // If offline, the indexedDB is called to get the data.
  // This assumes, that it was previously stored in the
  // indexedDB. This happens during "Preparing Offline" state.
  // Therefore, the indexedDB should only be called if the
  // offline state is truly "OFFLINE".
  if (offlineState === OfflineState.OFFLINE) {
    // work order
    if (stale.includes("workOrder") && !workOrderLoading) {
      setWorkOrderLoading(true);
      artefactDbGet<WorkOrder>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "workOrder",
        workOrderId,
      )
        .then((idbWorkOrder) => {
          if (idbWorkOrder === null) {
            setError({ status: 404 });
          } else {
            setWorkOrder(idbWorkOrder);
          }
          removeStale("workOrder");
        })
        .catch(() => {
          setError({ status: 500 });
        })
        .finally(() => {
          setWorkOrderLoading(false);
        });
    }

    // Dependencies
    // positionCatalog serves as a flag, if the dependencies are loaded.
    // Dependencies only need to be loaded once, since they are not mutated.
    if (!positionCatalog && !dependenciesLoading) {
      setDependenciesLoading(true);
      // get all dependencies
      artefactDbGet<Dependency>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "dependencies",
        "dependencies",
      )
        .then((dependencies) => {
          if (dependencies === null) {
            setError({ status: 404 });
          } else {
            setAhuComponentCatalog(dependencies.value.ahuComponentCatalog);
            setDevices(dependencies.value.devices);
            setLaboratories(dependencies.value.laboratories);
            setPositionCatalog(dependencies.value.positionCatalog);
            setProcessOffers(dependencies.value.processOffers);
            setProcessOrders(dependencies.value.processOrders);
            setStructuralAssessmentCatalog(
              dependencies.value.structuralAssessmentCatalog,
            );
            setVisualAssessmentCatalog(
              dependencies.value.visualAssessmentCatalog,
            );
          }
        })
        .catch(() => {
          setError({ status: 500 });
        })
        .finally(() => {
          setDependenciesLoading(false);
        });
    }

    return {
      workOrder,
      positionCatalog,
      ahuComponentCatalog,
      structuralAssessmentCatalog,
      visualAssessmentCatalog,
      processOffers,
      processOrders,
      laboratories,
      devices,
      isLoading,
      error,
    };
  }

  // When offline there is basically no error allowed here.
  // The expectation is that once loading is done, all data is available.
  // This check is purely defensive and can only happen if there is a
  // programming error in the goOfflineHandler() preparing the offline state.
  if (!isLoading) {
    if (
      workOrder === undefined ||
      ahuComponentCatalog === undefined ||
      devices === undefined ||
      laboratories === undefined ||
      positionCatalog === undefined ||
      processOffers === undefined ||
      processOrders === undefined ||
      structuralAssessmentCatalog === undefined ||
      visualAssessmentCatalog === undefined
    ) {
      setError({ status: 500 });
    }

    // TODO, sentry report

    // The user cannot recover from this error. The only path is to go back
    // online and re-do the offline preparation.
    setOfflineState(OfflineState.ONLINE).then();

    return {
      workOrder,
      positionCatalog,
      ahuComponentCatalog,
      structuralAssessmentCatalog,
      visualAssessmentCatalog,
      processOffers,
      processOrders,
      laboratories,
      devices,
      isLoading: true,
      error: { status: 500 },
    };
  }

  return {
    workOrder,
    positionCatalog,
    ahuComponentCatalog,
    structuralAssessmentCatalog,
    visualAssessmentCatalog,
    processOffers,
    processOrders,
    laboratories,
    devices,
    isLoading,
    error,
  };
}
