import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { Query, useArtefactDBGetAll } from "@/shared/indexdb/artefact-db-hooks";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  useHtzVisualAssessmentCatalogShowActiveCatalogQuery,
  useHtzVisualAssessmentCatalogShowQuery,
} from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog-service";
import { VisualAssessmentCatalog } from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";

export function useGetVisualAssessmentCatalog(
  workOrderId: string,
): Query<VisualAssessmentCatalog> {
  const { offlineState } = useOfflineContext();

  const {
    data: activeCatalog,
    isLoading: activeCatalogIsLoading,
    error: activeCatalogError,
  } = useHtzVisualAssessmentCatalogShowActiveCatalogQuery({});

  const {
    data: visualAssessmentCatalog,
    isLoading: visualAssessmentCatalogIsLoading,
    error: visualAssessmentCatalogError,
  } = useHtzVisualAssessmentCatalogShowQuery(
    {
      id: activeCatalog?.catalogId ?? "",
    },
    {
      skip: activeCatalog === undefined || activeCatalog?.catalogId === null,
    },
  );

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGetAll<VisualAssessmentCatalog>(
    ArtefactKind.WorkOrder,
    workOrderId,
    "visualAssessmentCatalog",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: visualAssessmentCatalog,
        isLoading: activeCatalogIsLoading || visualAssessmentCatalogIsLoading,
        error: activeCatalogError || visualAssessmentCatalogError,
      };
    case OfflineState.OFFLINE:
      return {
        data: indexedDBData ? indexedDBData[0] : undefined,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: { status: 500 },
      };
  }
}
