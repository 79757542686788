import {
  addMicrobialAssessment,
  deleteMicrobialAssessment,
  HTZ_WORK_ORDER_NAMESPACE,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { useEffect, useState } from "react";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import { Card } from "@/shared/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { cn } from "@/shared/lib/utils";
import { Button } from "@/shared/components/ui/button";
import { Plus, PlusCircle, RefreshCw, Trash2 } from "lucide-react";
import { H4 } from "@/shared/components/ui/typography";
import { v4 } from "uuid";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Kind } from "@/services/backend/samples/sample/kind";
import { Separator } from "@/shared/components/ui/separator";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import {
  activateAirMicrobialSetSample,
  AirMicrobialSample,
  airMicrobialSampleIsActivated,
  AirMicrobialSampleSet,
  deactivateAirMicrobialSetSample,
  newAirMicrobialSampleSet,
  updateAirMicrobialSetCaso,
  updateAirMicrobialSetComment,
  updateAirMicrobialSetDg18,
  updateAirMicrobialSetLab,
  updateAirMicrobialSetMalt,
  updateAirMicrobialSetNumber,
  updateDeviceId,
  updateSamplingVolume,
} from "@/services/backend/samples/sample/air-microbial-sample";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { GrowthMedium } from "@/services/backend/samples/sample/growth-medium";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";
import {
  Sample,
  updateSampleComment,
  updateSampleCreatedAt,
  updateSampleLab,
  updateSampleNumber,
} from "@/services/backend/samples/sample/sample";
import { Textarea } from "@/shared/components/ui/textarea";
import { DateStringPicker } from "@/shared/components/ui/date-picker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import {
  activateSurfaceMicrobialSetSample,
  deactivateSurfaceMicrobialSetSample,
  newSurfaceMicrobialSampleSet,
  SurfaceMicrobialSample,
  surfaceMicrobialSampleIsActivated,
  SurfaceMicrobialSampleSet,
  updateSurfaceMicrobialSetCaso,
  updateSurfaceMicrobialSetComment,
  updateSurfaceMicrobialSetDg18,
  updateSurfaceMicrobialSetLab,
  updateSurfaceMicrobialSetMalt,
  updateSurfaceMicrobialSetNumber,
} from "@/services/backend/samples/sample/surface-microbial-sample";
import {
  DustDensitySample,
  newDustDensitySample,
} from "@/services/backend/samples/sample/dust-density-sample";
import {
  newWaterSample,
  updateTemperature,
  WaterSample,
} from "@/services/backend/samples/sample/water-sample";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  useGetAirMicrobialSampleSet,
  usePutAirMicrobialSampleSet,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_hooks/air-microbial-sample-set-hooks";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PutRequest } from "@/services/backend/samples/sample/air-microbial-service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  useGetSurfaceMicrobialSampleSet,
  usePutSurfaceMicrobialSampleSet,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_hooks/surface-microbial-sample-set-hooks";
import {
  useGetDustDensitySample,
  usePutDustDensitySample,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_hooks/dust-density-sample-hooks";
import {
  useGetWaterSample,
  usePutWaterSample,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_hooks/water-sample-hooks";
import { useGetLaboratories } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-get-laboratories";
import { useGetDevices } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-get-devices";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function MicrobialInspection({ workOrder }: { workOrder: WorkOrder }) {
  const [assessmentId, setAssessmentId] = useState<string | null>(null);
  const { activeComponent, position } = useInspectionContext();

  return (
    <ScrollArea className="h-[65vh] space-y-2">
      <MicrobialAssessmentTable
        workOrder={workOrder}
        selectedAssessmentId={assessmentId}
        onAssessmentSelectedId={(a) => setAssessmentId(a)}
      />
      <div className="p-2 py-4">
        {position.microbialAssessments
          .filter((a) => a.componentId === activeComponent?.id)
          .map((assessment) => {
            if (assessmentId !== assessment.id) return null;
            return (
              <MicrobialAssessmentForm
                key={assessment.id}
                workOrder={workOrder}
                assessment={assessment}
              />
            );
          })}
      </div>
    </ScrollArea>
  );
}

export function AddMicrobialAssessmentButton({
  workOrder,
}: {
  workOrder: WorkOrder;
}) {
  const {
    position,
    onPositionChange: [putPosition, { isLoading: ppIsLoading, error: ppError }],
    disabled: ctxDisabled,
  } = useInspectionContext();

  const [
    putAirMicrobialSampleSet,
    { isLoading: amsIsLoading, error: amsError, reset: amsReset },
  ] = usePutAirMicrobialSampleSet();

  const [
    putSurfaceMicrobialSampleSet,
    { isLoading: smsIsLoading, error: smsError, reset: smsReset },
  ] = usePutSurfaceMicrobialSampleSet();

  const [
    putDustDensitySample,
    { isLoading: ddsIsLoading, error: ddsError, reset: ddsReset },
  ] = usePutDustDensitySample();

  const [
    putWaterSample,
    { isLoading: wsIsLoading, error: wsError, reset: wsReset },
  ] = usePutWaterSample();

  const isLoading =
    amsIsLoading || smsIsLoading || ddsIsLoading || wsIsLoading || ppIsLoading;

  const error = amsError || smsError || ddsError || wsError || ppError;

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      amsReset();
      smsReset();
      ddsReset();
      wsReset();
    }
  }, [amsReset, ddsReset, error, smsReset, toast, wsReset]);

  const { activeComponent } = useInspectionContext();

  const { resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorKey = `position.${position.id}.microbialAssessments`;

  const doAdd = (kind: Kind) => () => {
    if (isLoading) {
      return;
    }

    // A new assessment needs a new sample or sample set depending on the kind.
    const sampleId = v4();
    const assessmentId = v4();

    switch (kind) {
      case Kind.AirMicrobial:
        putAirMicrobialSampleSet(
          newAirMicrobialSampleSet(
            sampleId,
            workOrder.id,
            HTZ_WORK_ORDER_NAMESPACE,
          ),
        );
        break;
      case Kind.SurfaceMicrobial:
        putSurfaceMicrobialSampleSet(
          newSurfaceMicrobialSampleSet(
            sampleId,
            workOrder.id,
            HTZ_WORK_ORDER_NAMESPACE,
          ),
        );
        break;
      case Kind.DustDensity:
        putDustDensitySample(
          newDustDensitySample(
            sampleId,
            workOrder.id,
            HTZ_WORK_ORDER_NAMESPACE,
          ),
        );
        break;
      case Kind.Water:
        putWaterSample(
          newWaterSample(sampleId, workOrder.id, HTZ_WORK_ORDER_NAMESPACE),
        );
        break;
      default:
        throw new Error(`Unknown kind: ${kind}`);
    }

    putPosition(
      addMicrobialAssessment(
        position,
        activeComponent!.id,
        assessmentId,
        kind,
        sampleId,
      ),
    );
    resetNotReadyErrorField(errorKey);
  };

  const disabled =
    workOrder.immutable || activeComponent === null || isLoading || ctxDisabled;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button>
          <PlusCircle />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Probe hinzufügen")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={doAdd(Kind.AirMicrobial)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Luftkeimprobe")}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={doAdd(Kind.SurfaceMicrobial)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Oberflächenkeimprobe")}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={doAdd(Kind.DustDensity)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Staubdichtemessung")}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={doAdd(Kind.Water)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Wasserprobe")}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function MicrobialAssessmentTable({
  workOrder,
  selectedAssessmentId,
  onAssessmentSelectedId,
}: {
  workOrder: WorkOrder;
  selectedAssessmentId: string | null;
  onAssessmentSelectedId: (a: string | null) => void;
}) {
  const { position, activeComponent } = useInspectionContext();

  const toggle = (assessment: MicrobialAssessment) => {
    if (assessment.id === selectedAssessmentId) {
      onAssessmentSelectedId(null);
    } else {
      onAssessmentSelectedId(assessment.id);
    }
  };

  return (
    <Card className="shadow-none">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="h-8">{t("Probenart")}</TableHead>
            <TableHead className="h-8">{t("")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {position.microbialAssessments
            ?.filter((a) => a.componentId === activeComponent?.id)
            .map((assessment) => (
              <TableRow
                key={assessment.id}
                className={cn(
                  "cursor-pointer",
                  assessment.id === selectedAssessmentId ? "bg-accent" : "",
                )}
              >
                <TableCell className="py-1" onClick={() => toggle(assessment)}>
                  {t(assessment.kind)}
                </TableCell>
                <TableCell className="py-1 text-right">
                  <DeleteAssessmentDialog
                    workOrder={workOrder}
                    assessmentId={assessment.id}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function DeleteAssessmentDialog({
  workOrder,
  assessmentId,
}: {
  workOrder: WorkOrder;
  assessmentId: string;
}) {
  const [open, setOpen] = useState(false);
  const { offlineState } = useOfflineContext();
  const {
    position,
    onPositionChange: [putPosition, { isLoading, error, reset, isSuccess }],
  } = useInspectionContext();

  const doDelete = () => {
    if (isLoading) return;

    // samples will be deleted by the backend

    putPosition(deleteMicrobialAssessment(position, assessmentId));
  };

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      reset();
    }
  }, [isSuccess, reset]);

  const disabled = workOrder.immutable || isLoading;

  // If we are offline, we cannot delete the assessment.
  // If a sample is associated with the assessment, it should not be deleted.
  // This is especially important when the sample is already in a delivery.
  // This cannot be reliably checked when offline. Online, the backend will
  // prevent deletion.
  if (offlineState !== OfflineState.ONLINE) {
    return (
      <Button size="sm" variant="destructive" className="h-8 w-8 p-0" disabled>
        <Trash2 className="h-4 w-4" />
      </Button>
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={disabled}>
        <Button size="sm" variant="destructive" className="h-8 w-8 p-0">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Beurteilung löschen?")}</DialogTitle>
          <DialogDescription>
            {t(
              "Durch das Löschen der Beurteilung wird auch die zugeordneten Proben entfernen.",
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete} disabled={isLoading}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function MicrobialAssessmentForm({
  workOrder,
  assessment,
}: {
  workOrder: WorkOrder;
  assessment: MicrobialAssessment;
}) {
  const { immutable } = workOrder;

  switch (assessment.kind) {
    case Kind.AirMicrobial:
      return (
        <AirMicrobialSampleFormLoader
          id={assessment.sampleId}
          immutable={immutable}
        />
      );
    case Kind.SurfaceMicrobial:
      return (
        <SurfaceMicrobialSampleFormLoader
          id={assessment.sampleId}
          immutable={immutable}
        />
      );
    case Kind.DustDensity:
      return (
        <DustDensitySampleFormLoader
          id={assessment.sampleId}
          immutable={immutable}
        />
      );
    case Kind.Water:
      return (
        <WaterSampleFormLoader id={assessment.sampleId} immutable={immutable} />
      );
    default:
      return null;
  }
}

function AirMicrobialSampleFormLoader({
  id,
  immutable,
}: {
  id: EntityId;
  immutable: boolean;
}) {
  const { data, isLoading, error } = useGetAirMicrobialSampleSet(id!);

  if (isLoading) {
    return (
      <div className="h-full">
        <H4>{t(Kind.AirMicrobial)}</H4>
        <Separator className="mb-4 mt-1" />
        <div>
          <RefreshCw className="h-5 w-5 animate-spin" />
        </div>
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <AirMicrobialSampleForm sampleSet={data!} immutable={immutable} />;
}

function AirMicrobialSampleForm({
  sampleSet,
  immutable,
}: {
  sampleSet: AirMicrobialSampleSet;
  immutable: boolean;
}) {
  const [request, setRequest] = useState<PutRequest>(sampleSet);
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutAirMicrobialSampleSet();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  // needed to enable switching between different sample sets / assessments
  useEffect(() => {
    if (sampleSet.id !== request.id) {
      setRequest(sampleSet);
    }
  }, [request.id, sampleSet]);

  return (
    <div className="h-full">
      <H4>{t(Kind.AirMicrobial)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={request.number}
            onNumberChange={(number) =>
              setRequest(updateAirMicrobialSetNumber(request, number))
            }
            error={error}
            disabled={immutable}
          />
          <LaboratorySelect
            labId={request.labId}
            onLabIdChange={(labId) =>
              setRequest(updateAirMicrobialSetLab(request, labId))
            }
            error={error}
            disabled={immutable}
          />
        </div>
        <CommentInput
          comment={request.comment}
          onCommentChange={(comment) =>
            setRequest(updateAirMicrobialSetComment(request, comment))
          }
          error={error}
          disabled={immutable}
        />
        <Tabs defaultValue={GrowthMedium.CASO}>
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value={GrowthMedium.CASO}>
              {t(GrowthMedium.CASO)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.MALT}>
              {t(GrowthMedium.MALT)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.DG18} className="uppercase">
              {t(GrowthMedium.DG18)}
            </TabsTrigger>
          </TabsList>
          <div className="p-2">
            <TabsContent value={GrowthMedium.CASO}>
              <AirMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.CASO}
                set={request}
                onSetChange={setRequest}
                error={error}
                disabled={immutable}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.MALT}>
              <AirMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.MALT}
                set={request}
                onSetChange={setRequest}
                error={error}
                disabled={immutable}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.DG18}>
              <AirMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.DG18}
                set={request}
                onSetChange={setRequest}
                error={error}
                disabled={immutable}
              />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

function SurfaceMicrobialSampleFormLoader({
  id,
  immutable,
}: {
  id: EntityId;
  immutable: boolean;
}) {
  const { data, isLoading, error } = useGetSurfaceMicrobialSampleSet(id!);

  if (isLoading) {
    return (
      <div className="h-full">
        <H4>{t(Kind.SurfaceMicrobial)}</H4>
        <Separator className="mb-4 mt-1" />
        <div>
          <RefreshCw className="h-5 w-5 animate-spin" />
        </div>
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <SurfaceMicrobialSampleForm sampleSet={data!} immutable={immutable} />;
}

function SurfaceMicrobialSampleForm({
  sampleSet,
  immutable,
}: {
  sampleSet: SurfaceMicrobialSampleSet;
  immutable: boolean;
}) {
  const [request, setRequest] = useState(sampleSet);
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutSurfaceMicrobialSampleSet();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  // needed to enable switching between different sample sets / assessments
  useEffect(() => {
    if (sampleSet.id !== request.id) {
      setRequest(sampleSet);
    }
  }, [request.id, sampleSet]);

  return (
    <div className="h-full">
      <H4>{t(Kind.SurfaceMicrobial)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={request.number}
            onNumberChange={(number) =>
              setRequest(updateSurfaceMicrobialSetNumber(sampleSet, number))
            }
            error={error}
            disabled={immutable}
          />
          <LaboratorySelect
            labId={request.labId}
            onLabIdChange={(labId) =>
              setRequest(updateSurfaceMicrobialSetLab(request, labId))
            }
            error={error}
            disabled={immutable}
          />
        </div>
        <CommentInput
          comment={request.comment}
          onCommentChange={(comment) =>
            setRequest(updateSurfaceMicrobialSetComment(request, comment))
          }
          error={error}
          disabled={immutable}
        />
        <Tabs defaultValue={GrowthMedium.CASO}>
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value={GrowthMedium.CASO}>
              {t(GrowthMedium.CASO)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.MALT}>
              {t(GrowthMedium.MALT)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.DG18} className="uppercase">
              {t(GrowthMedium.DG18)}
            </TabsTrigger>
          </TabsList>
          <div className="p-2">
            <TabsContent value={GrowthMedium.CASO}>
              <SurfaceMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.CASO}
                set={request}
                onSetChange={setRequest}
                error={error}
                disabled={immutable}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.MALT}>
              <SurfaceMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.MALT}
                set={request}
                onSetChange={setRequest}
                error={error}
                disabled={immutable}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.DG18}>
              <SurfaceMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.DG18}
                set={request}
                onSetChange={setRequest}
                error={error}
                disabled={immutable}
              />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

function DustDensitySampleFormLoader({
  id,
  immutable,
}: {
  id: EntityId;
  immutable: boolean;
}) {
  const { data, isLoading, error } = useGetDustDensitySample(id!);

  if (isLoading) {
    return (
      <div className="h-full">
        <H4>{t(Kind.DustDensity)}</H4>
        <Separator className="mb-4 mt-1" />
        <div>
          <RefreshCw className="h-5 w-5 animate-spin" />
        </div>
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <DustDensitySampleForm sample={data!} immutable={immutable} />;
}

function DustDensitySampleForm({
  sample,
  immutable,
}: {
  sample: DustDensitySample;
  immutable: boolean;
}) {
  const [request, setRequest] = useState(sample);
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutDustDensitySample();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  // needed to enable switching between different sample sets / assessments
  useEffect(() => {
    if (sample.id !== request.id) {
      setRequest(sample);
    }
  }, [request.id, sample]);

  return (
    <div className="h-full">
      <H4>{t(Kind.DustDensity)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={request.number}
            onNumberChange={(number) =>
              setRequest(updateSampleNumber(request, number))
            }
            error={error}
            disabled={immutable}
          />
          <CreatedAtInput
            createdAt={request.createdAt}
            onCreatedAtChange={(createdAt) =>
              setRequest(updateSampleCreatedAt(request, createdAt))
            }
            error={error}
            disabled={immutable}
          />
        </div>
        <LaboratorySelect
          labId={request.labId}
          onLabIdChange={(labId) => setRequest(updateSampleLab(request, labId))}
          error={error}
          disabled={immutable}
        />
        <CommentInput
          comment={request.comment}
          onCommentChange={(comment) =>
            setRequest(updateSampleComment(request, comment))
          }
          error={error}
          disabled={immutable}
        />
      </div>
    </div>
  );
}

function WaterSampleFormLoader({
  id,
  immutable,
}: {
  id: EntityId;
  immutable: boolean;
}) {
  const { data, isLoading, error } = useGetWaterSample(id!);

  if (isLoading) {
    return (
      <div className="h-full">
        <H4>{t(Kind.Water)}</H4>
        <Separator className="mb-4 mt-1" />
        <div>
          <RefreshCw className="h-5 w-5 animate-spin" />
        </div>
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <WaterSampleForm sample={data!} immutable={immutable} />;
}

function WaterSampleForm({
  sample,
  immutable,
}: {
  sample: WaterSample;
  immutable: boolean;
}) {
  const [request, setRequest] = useState(sample);
  const [put, { isLoading, error, isSuccess, reset }] = usePutWaterSample();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  // needed to enable switching between different sample sets / assessments
  useEffect(() => {
    if (sample.id !== request.id) {
      setRequest(sample);
    }
  }, [request.id, sample]);

  return (
    <div className="h-full">
      <H4>{t(Kind.Water)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={request.number}
            onNumberChange={(number) =>
              setRequest(updateSampleNumber(request, number))
            }
            error={error}
            disabled={immutable}
          />
          <CreatedAtInput
            createdAt={request.createdAt}
            onCreatedAtChange={(createdAt) =>
              setRequest(updateSampleCreatedAt(request, createdAt))
            }
            error={error}
            disabled={immutable}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <TemperatureInput
            temperature={request.temperature}
            onTemperatureChange={(temperature) =>
              setRequest(updateTemperature(request, temperature))
            }
            error={error}
            disabled={immutable}
          />
          <LaboratorySelect
            labId={request.labId}
            onLabIdChange={(labId) =>
              setRequest(updateSampleLab(request, labId))
            }
            error={error}
            disabled={immutable}
          />
        </div>
        <CommentInput
          comment={request.comment}
          onCommentChange={(comment) =>
            setRequest(updateSampleComment(request, comment))
          }
          error={error}
          disabled={immutable}
        />
      </div>
    </div>
  );
}

function NumberInput({
  number,
  onNumberChange,
  error,
  disabled,
}: {
  number: string;
  onNumberChange: (number: string) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Probennummer")}</Label>
      <Input
        type="text"
        value={number}
        onChange={(e) => onNumberChange(e.target.value)}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="number" />
    </div>
  );
}

function TemperatureInput({
  temperature,
  onTemperatureChange,
  error,
  disabled,
}: {
  temperature: number;
  onTemperatureChange: (temperature: number) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Temperatur")}</Label>
      <Input
        type="number"
        value={temperature}
        onChange={(e) => onTemperatureChange(Number(e.target.value))}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="temperature" />
    </div>
  );
}

function LaboratorySelect({
  labId,
  onLabIdChange,
  error,
  disabled,
}: {
  labId: EntityId;
  onLabIdChange: (labId: EntityId) => void;
  error: unknown;
  disabled?: boolean;
}) {
  const {
    data: laboratories,
    isLoading,
    error: loadingError,
  } = useGetLaboratories();

  if (isLoading) {
    return (
      <div className="grid content-start gap-1.5">
        <Label>{t("Labor")}</Label>
        <div>
          <RefreshCw className="h-5 w-5 animate-spin" />
        </div>
      </div>
    );
  }

  if (loadingError) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Labor")}</Label>
      <Select
        value={String(labId)}
        onValueChange={onLabIdChange}
        disabled={disabled ?? false}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Labor auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {laboratories!.map((lab) => (
            <SelectItem key={lab.id} value={lab.id}>
              {lab.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <InputValidationErrors error={error} field="labId" />
    </div>
  );
}

function AirMicrobialSampleSetSampleFields({
  growthMedium,
  set,
  onSetChange,
  error,
  disabled,
}: {
  growthMedium: GrowthMedium;
  set: AirMicrobialSampleSet;
  onSetChange: (set: AirMicrobialSampleSet) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid gap-4">
      <div className="flex items-center space-x-2">
        <Checkbox
          id="terms"
          checked={airMicrobialSampleIsActivated(set, growthMedium)}
          onCheckedChange={(state: CheckedState) => {
            switch (state) {
              case true: {
                onSetChange(activateAirMicrobialSetSample(set, growthMedium));
                break;
              }
              case false: {
                onSetChange(deactivateAirMicrobialSetSample(set, growthMedium));
                break;
              }
              default:
                break;
            }
          }}
          disabled={disabled}
        />
        <Label htmlFor="terms">{`Hat ${t(growthMedium)} Probe`}</Label>
      </div>
      {airMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.CASO && (
          <AirMicrobialSampleFields
            sample={set.caso!}
            onSampleChange={(sample) =>
              onSetChange(updateAirMicrobialSetCaso(set, sample))
            }
            error={error}
          />
        )}
      {airMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.MALT && (
          <AirMicrobialSampleFields
            sample={set.malt!}
            onSampleChange={(sample) =>
              onSetChange(updateAirMicrobialSetMalt(set, sample))
            }
            error={error}
          />
        )}
      {airMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.DG18 && (
          <AirMicrobialSampleFields
            sample={set.dg18!}
            onSampleChange={(sample) =>
              onSetChange(updateAirMicrobialSetDg18(set, sample))
            }
            error={error}
          />
        )}
    </div>
  );
}

function AirMicrobialSampleFields({
  sample,
  onSampleChange,
  error,
  disabled,
}: {
  sample: AirMicrobialSample;
  onSampleChange: (sample: AirMicrobialSample) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid gap-4">
      <BaseSampleFields
        sample={sample}
        onSampleChange={onSampleChange}
        error={error}
        disableSetBoundFields
        disabled={disabled}
      />
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          <SamplingVolumeInput
            volume={sample.samplingVolume}
            onVolumeChange={(volume) =>
              onSampleChange(updateSamplingVolume(sample, volume))
            }
            error={error}
            disabled={disabled}
          />
        </div>
        <div className="col-span-2">
          <DeviceSelect
            deviceId={sample.deviceId}
            onDeviceIdChange={(deviceId) =>
              onSampleChange(updateDeviceId(sample, deviceId))
            }
            error={error}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

function SamplingVolumeInput({
  volume,
  onVolumeChange,
  error,
  disabled,
}: {
  volume: number;
  onVolumeChange: (volume: number) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Ansaugvolumen")}</Label>
      <Input
        type="number"
        value={volume}
        onChange={(e) => onVolumeChange(parseFloat(e.target.value))}
        disabled={disabled}
      />
      <InputValidationErrors error={error} field="samplingVolume" />
    </div>
  );
}

function DeviceSelect({
  deviceId,
  onDeviceIdChange,
  error,
  disabled,
}: {
  deviceId: EntityId;
  onDeviceIdChange: (deviceId: EntityId) => void;
  error: unknown;
  disabled?: boolean;
}) {
  const { data: devices, isLoading, error: loadingError } = useGetDevices();

  if (isLoading) {
    return (
      <div className="grid content-start gap-1.5">
        <Label>{t("Verwendetes Gerät")}</Label>
        <div>
          <RefreshCw className="h-5 w-5 animate-spin" />
        </div>
      </div>
    );
  }

  if (loadingError) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Verwendetes Gerät")}</Label>
      <Select
        value={String(deviceId)}
        onValueChange={onDeviceIdChange}
        disabled={disabled ?? false}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Gerät auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {devices!.map((device) => (
            <SelectItem key={device.id} value={device.id}>
              {device.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <InputValidationErrors error={error} field="deviceId" />
    </div>
  );
}

function SurfaceMicrobialSampleSetSampleFields({
  growthMedium,
  set,
  onSetChange,
  error,
  disabled,
}: {
  growthMedium: GrowthMedium;
  set: SurfaceMicrobialSampleSet;
  onSetChange: (set: SurfaceMicrobialSampleSet) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid gap-4">
      <div className="flex items-center space-x-2">
        <Checkbox
          id="terms"
          checked={surfaceMicrobialSampleIsActivated(set, growthMedium)}
          onCheckedChange={(state: CheckedState) => {
            switch (state) {
              case true: {
                onSetChange(
                  activateSurfaceMicrobialSetSample(set, growthMedium),
                );
                break;
              }
              case false: {
                onSetChange(
                  deactivateSurfaceMicrobialSetSample(set, growthMedium),
                );
                break;
              }
              default:
                break;
            }
          }}
          disabled={disabled}
        />
        <Label htmlFor="terms">{`Hat ${t(growthMedium)} Probe`}</Label>
      </div>
      {surfaceMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.CASO && (
          <SurfaceMicrobialSampleFields
            sample={set.caso!}
            onSampleChange={(sample) =>
              onSetChange(updateSurfaceMicrobialSetCaso(set, sample))
            }
            error={error}
            disabled={disabled}
          />
        )}
      {surfaceMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.MALT && (
          <SurfaceMicrobialSampleFields
            sample={set.malt!}
            onSampleChange={(sample) =>
              onSetChange(updateSurfaceMicrobialSetMalt(set, sample))
            }
            error={error}
            disabled={disabled}
          />
        )}
      {surfaceMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.DG18 && (
          <SurfaceMicrobialSampleFields
            sample={set.dg18!}
            onSampleChange={(sample) =>
              onSetChange(updateSurfaceMicrobialSetDg18(set, sample))
            }
            error={error}
            disabled={disabled}
          />
        )}
    </div>
  );
}

function SurfaceMicrobialSampleFields({
  sample,
  onSampleChange,
  error,
  disabled,
}: {
  sample: SurfaceMicrobialSample;
  onSampleChange: (sample: SurfaceMicrobialSample) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid gap-4">
      <BaseSampleFields
        sample={sample}
        onSampleChange={onSampleChange}
        error={error}
        disableSetBoundFields
        disabled={disabled}
      />
    </div>
  );
}

function BaseSampleFields<S extends Sample>({
  sample,
  onSampleChange,
  error,
  disableSetBoundFields,
  disabled,
}: {
  sample: S;
  onSampleChange: (sample: S) => void;
  error: unknown;
  disableSetBoundFields?: boolean;
  disabled?: boolean;
}) {
  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-2 gap-4">
        <NumberInput
          number={sample.number}
          onNumberChange={(number) =>
            onSampleChange(updateSampleNumber(sample, number))
          }
          error={error}
          disabled={disableSetBoundFields || disabled}
        />
        <CreatedAtInput
          createdAt={sample.createdAt}
          onCreatedAtChange={(createdAt) =>
            onSampleChange(updateSampleCreatedAt(sample, createdAt))
          }
          error={error}
          disabled={disabled}
        />
      </div>
      <LaboratorySelect
        labId={sample.labId}
        onLabIdChange={(labId) =>
          onSampleChange(updateSampleLab(sample, labId))
        }
        error={error}
        disabled={disableSetBoundFields || disabled}
      />
      <CommentInput
        comment={sample.comment}
        onCommentChange={(comment) =>
          onSampleChange(updateSampleComment(sample, comment))
        }
        error={error}
        disabled={disabled}
      />
    </div>
  );
}

function CommentInput({
  comment,
  onCommentChange,
  error,
  disabled,
}: {
  comment: string;
  onCommentChange: (comment: string) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Kommentar (für Labor)")}</Label>
      <Textarea
        value={comment}
        onChange={(e) => onCommentChange(e.target.value)}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="comment" />
    </div>
  );
}

function CreatedAtInput({
  createdAt,
  onCreatedAtChange,
  error,
  disabled,
}: {
  createdAt: string | null;
  onCreatedAtChange: (createdAt: string | null) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Erstellt am")}</Label>
      <DateStringPicker
        date={createdAt}
        onDateChange={onCreatedAtChange}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="createdAt" />
    </div>
  );
}
