import { Area, AreaList } from "@/services/backend/qpm/buildings/types";
import { MonitoringAreaSegmentCollapsible } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/monitoring-area-segment-component";
import { useEffect, useState } from "react";
import {
  AreaMonitor,
  Requirement,
  SelectedAreaMonitors,
  SelectedMonitor,
} from "@/services/backend/qpm/requirements/types";
import { useQpmRequirementSelectedMonitorListQuery } from "@/services/backend/qpm/requirements/service";
import { useQpmMonitorRecordListQuery } from "@/services/backend/qpm/monitors/service";
import { MonitorRecord } from "@/services/backend/qpm/monitors/types";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export type AreaMonitorWrapperProps = {
  requirement: Requirement;
  selectedAreas: AreaList;
  buildingName?: string;
  buildingId: string;
  levelId: string;
  levelName?: string;
};

export function MonitoringAreas({
  requirement,
  selectedAreas,
  buildingName = "",
  buildingId,
  levelId,
  levelName = "",
}: AreaMonitorWrapperProps) {
  const [selectedMonitors, setSelectedMonitors] =
    useState<SelectedAreaMonitors>({
      monitors: {},
    });

  const {
    data: initialMonitors,
    error: errorMonitors,
    isLoading: isLoadingMonitors,
  } = useQpmMonitorRecordListQuery({
    artefactMetaId: {
      active: true,
      values: [requirement.artefactData.id],
    },
  });

  const {
    data: selectedMonitorsList,
    error: errorRequirementSelectedMonitors,
    isLoading: isLoadingRequirementSelectedMonitors,
  } = useQpmRequirementSelectedMonitorListQuery({
    artefactMetaId: requirement.artefactData.id,
  });

  useEffect(() => {
    if (initialMonitors && selectedMonitorsList && selectedAreas) {
      const monitorsMapping: { [areaId: string]: AreaMonitor[] } = {};

      selectedAreas.forEach((area) => {
        // Filter the selectedMonitorsList for monitors that belong to the current area
        // Assign the filtered monitors to the current area in the mapping object
        monitorsMapping[area.base.id] = selectedMonitorsList.monitors
          .filter(
            (selectedMonitor: SelectedMonitor) =>
              selectedMonitor.areaId === area.base.id,
          )
          .map((selectedMonitor: SelectedMonitor) =>
            // Find the full monitor details from initialMonitors using the monitorId
            ({
              ...(initialMonitors.monitors.find(
                (monitor) => monitor.id === selectedMonitor.monitorId,
              ) as MonitorRecord),
              amount: selectedMonitor.amount,
              existingStockAmount: selectedMonitor.existingStockAmount,
              comment: selectedMonitor.comment,
              position: selectedMonitor.position,
            }),
          );
      });

      setSelectedMonitors({ monitors: monitorsMapping });
    }
  }, [selectedAreas, initialMonitors, selectedMonitorsList]);

  if (errorRequirementSelectedMonitors) {
    return <RTKQueryErrorAlert error={errorRequirementSelectedMonitors} />;
  }

  if (errorMonitors) {
    return <RTKQueryErrorAlert error={errorMonitors} />;
  }

  if (isLoadingMonitors || isLoadingRequirementSelectedMonitors) {
    return (
      <div className="flex flex-col space-y-3 p-2">
        <Skeleton className="h-12 w-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
        </div>
      </div>
    );
  }

  return (
    selectedAreas.length > 0 && (
      <div className="border">
        {selectedAreas.map((area: Area, index: number) => (
          <MonitoringAreaSegmentCollapsible
            requirementId={requirement.artefactData.id}
            index={index + 1}
            key={area.base.id}
            area={area}
            buildingId={buildingId}
            levelId={levelId}
            buildingName={buildingName!}
            levelName={levelName!}
            initialMonitors={initialMonitors!}
            selectedMonitors={selectedMonitors.monitors[area.base.id] || []}
          />
        ))}
      </div>
    )
  );
}
