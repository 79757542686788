import {
  useQpmCalculationListQuery,
  useQpmCalculationSetCurrentMutation,
  useQpmCalculationShowCurrentQuery,
} from "@/services/backend/qpm/calculations/service";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { FilterMode } from "@/shared/lib/filter/filter";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { ArtefactMeta } from "@/services/backend/qpm/artefact-meta/types";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function CalculationListTable(props: { processId: string }) {
  const { processId } = props;

  const {
    data: list,
    isLoading,
    error,
  } = useQpmCalculationListQuery({
    ownerType: {
      active: false,
      values: [],
    },
    process: {
      active: true,
      values: [processId],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead className="w-24">{t("Aktuelle")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-32">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-24">{t("Aktuelle")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Erstellt am")}</TableHead>
          <TableHead className="w-48">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.artefacts ?? [])].sort(byCreatedAt).map((artefact) => (
          <TableRow key={artefact.id}>
            <TableCell className="pl-8">
              <CurrentCheckbox
                calculationId={artefact.id}
                processId={processId}
              />
            </TableCell>
            <TableCell>
              <NavLink to={`${artefact.id}`}>
                <ArtefactNumberById artefactId={artefact.id} />
              </NavLink>
            </TableCell>
            <TableCell>
              {new Date(artefact.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell className="text-right">
              <NavLink to={`${artefact.id}`}>
                <Button variant="outline" size="sm">
                  <Search className="h-4 w-4" />
                </Button>
              </NavLink>
            </TableCell>
          </TableRow>
        ))}
        {list?.artefacts?.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Kalkulationen gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function byCreatedAt(a: ArtefactMeta, b: ArtefactMeta) {
  return a.createdAt.localeCompare(b.createdAt);
}

function CurrentCheckbox(props: { calculationId: string; processId: string }) {
  const { calculationId, processId } = props;
  const { data: current } = useQpmCalculationShowCurrentQuery({ processId });
  const [set, { isLoading, error, reset }] =
    useQpmCalculationSetCurrentMutation();
  const { toast } = useToast();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    set({ processId, calculationId });
  };

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  if (current?.calculationId === calculationId) {
    return <Checkbox checked className="h-6 w-6 cursor-default" />;
  }

  return <Checkbox className="h-6 w-6" checked={false} onClick={onClick} />;
}
