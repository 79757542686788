import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";
import {
  Address,
  newAddress,
} from "@/services/backend/addresses/address/address";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { Representative } from "@/services/backend/representatives/representative/representative";
import {
  newStructural,
  StructuralAssessment,
} from "@/services/backend/htz/inspection/structural-assessment";
import {
  newVisual,
  VisualAssessment,
} from "@/services/backend/htz/inspection/visual-assessment";
import {
  MicrobialAssessment,
  newMicrobial,
} from "@/services/backend/htz/inspection/microbial-assessment";
import { newPhoto, Photo } from "@/services/backend/htz/inspection/photo";
import { Process } from "@/services/backend/processes/process/process";
import { v4 } from "uuid";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { Item } from "@/services/backend/htz/position-catalog/position-catalog";
import { Kind } from "@/services/backend/samples/sample/kind";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import {
  containsSampleId,
  Delivery,
} from "@/services/backend/samples/delivery/delivery";

export const HTZ_WORK_ORDER_NAMESPACE = "core.htz.domain.workorder.WorkOrder";

export interface WorkOrder extends Artefact, Immutability {
  id: string;
  processId: string;
  companyId: string;
  customerId: string;
  beginDate: string | null;
  endDate: string | null;
  teamLeader: string;
  comment: string;
  address: Address;
  contact: Contact;
  representative: Representative;
  createdAt: string;
  updatedAt: string;
}

export function newWorkOrder(process: Process): WorkOrder {
  return {
    id: v4(),
    artefactKind: ArtefactKind.WorkOrder,
    immutable: false,
    immutabilityReason: "",
    immutabilitySetAt: null,
    immutabilitySetBy: "",
    processId: process.id,
    companyId: process.companyId,
    customerId: process.customerId,
    beginDate: null,
    endDate: null,
    teamLeader: "",
    comment: "",
    address: newAddress(),
    contact: {
      salutation: "",
      title: "",
      name: "",
      function: "",
      email: "",
      phone: "",
      mobilephone: "",
    } as Contact,
    representative: {
      name: "",
      email: "",
      phone: "",
      mobilephone: "",
    } as Representative,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

export function updateBeginDate(
  workOrder: WorkOrder,
  beginDate: string | null,
): WorkOrder {
  if (workOrder.immutable) {
    return workOrder;
  }

  return {
    ...workOrder,
    beginDate,
  };
}

export function updateEndDate(
  workOrder: WorkOrder,
  endDate: string | null,
): WorkOrder {
  if (workOrder.immutable) {
    return workOrder;
  }

  return {
    ...workOrder,
    endDate,
  };
}

export function updateTeamLeader(
  workOrder: WorkOrder,
  teamLeader: string,
): WorkOrder {
  if (workOrder.immutable) {
    return workOrder;
  }

  return {
    ...workOrder,
    teamLeader,
  };
}

export function updateComment(
  workOrder: WorkOrder,
  comment: string,
): WorkOrder {
  if (workOrder.immutable) {
    return workOrder;
  }

  return {
    ...workOrder,
    comment,
  };
}

export function updateAddress(
  workOrder: WorkOrder,
  address: Address,
): WorkOrder {
  if (workOrder.immutable) {
    return workOrder;
  }

  return {
    ...workOrder,
    address,
  };
}

export function updateContact(
  workOrder: WorkOrder,
  contact: Contact,
): WorkOrder {
  if (workOrder.immutable) {
    return workOrder;
  }

  return {
    ...workOrder,
    contact,
  };
}

export function updateRepresentative(
  workOrder: WorkOrder,
  representative: Representative,
): WorkOrder {
  if (workOrder.immutable) {
    return workOrder;
  }

  return {
    ...workOrder,
    representative,
  };
}

export function assessmentsWithoutDelivery(
  sampleDeliveries: Delivery[],
  positions: Position[],
): MicrobialAssessment[] {
  return positions
    .map((pos) => pos.microbialAssessments ?? [])
    .flat()
    .filter((a) =>
      sampleDeliveries.every((delivery) => {
        if (delivery.cancelled) {
          return true;
        }
        return !containsSampleId(delivery, String(a.sampleId));
      }),
    );
}

export interface Position {
  id: string;
  workOrderId: string;
  number: number;
  completed: boolean;
  airHandlingUnitId: EntityId;
  title: string;
  description: string;
  optionalOffer: boolean;
  structuralInspectionRequired: boolean;
  structuralAssessments: StructuralAssessment[];
  visualInspectionRequired: boolean;
  visualAssessments: VisualAssessment[];
  microbialInspectionRequired: boolean;
  microbialAssessments: MicrobialAssessment[];
  photoDocumentationRequired: boolean;
  photos: Photo[];
}

export function newFromCatalog(
  catPosition: Item,
  workOrderId: string,
): Position {
  const {
    structuralInspectionRequired,
    visualInspectionRequired,
    microbiologicalInspectionRequired,
    photoDocumentationRequired,
  } = catPosition;

  return {
    id: v4(),
    workOrderId,
    number: 0, // set by backend
    completed: false,
    airHandlingUnitId: null,
    title: catPosition.copyTitle ? catPosition.title : "",
    description: catPosition.copyDescription ? catPosition.description : "",
    optionalOffer: false,
    // The inspections are initialized with null, since it may
    // not be known to which air handling unit the work order position
    // will belong. This seems odd, but is the case in the domain, since
    // a work order may be created based on an order in which the
    // actual air handling units where still unknown.
    structuralInspectionRequired,
    structuralAssessments: [],
    visualInspectionRequired,
    visualAssessments: [],
    microbialInspectionRequired: microbiologicalInspectionRequired,
    microbialAssessments: [],
    photoDocumentationRequired,
    photos: [],
  };
}

function assertAirHandlingUnitAssigned(pos: Position) {
  if (pos.airHandlingUnitId === null) {
    throw new Error(`no air handling unit assigned to position. id: ${pos.id}`);
  }
}

export function updateAhu(
  position: Position,
  airHandlingUnitId: string | null,
): Position {
  if (airHandlingUnitId === null) {
    // Inspections are completely dependent on the air handling unit
    // assigned. Changing the ahu, requires removing the inspections.
    return {
      ...position,
      airHandlingUnitId: null,
      structuralAssessments: [],
      visualAssessments: [],
      microbialAssessments: [],
      photos: [],
    };
  }

  // Changing the ahu requires a new inspection, since the assessments
  // are coupled the ahu components.
  return {
    ...position,
    airHandlingUnitId,
    structuralAssessments: [],
    visualAssessments: [],
    microbialAssessments: [],
    photos: [],
  };
}

export function updateDescription(
  position: Position,
  description: string,
): Position {
  return {
    ...position,
    description,
  };
}

export function updateStructuralInspectionRequired(
  position: Position,
  value: boolean,
): Position {
  return {
    ...position,
    structuralInspectionRequired: value,
  };
}

export function addStructuralAssessment(
  position: Position,
  componentId: string,
  assessmentId: string,
): Position {
  assertAirHandlingUnitAssigned(position);

  const assessment = newStructural(
    assessmentId,
    position.airHandlingUnitId!,
    componentId,
  );

  return {
    ...position,
    structuralAssessments: [
      ...(position.structuralAssessments ?? []),
      assessment,
    ],
  };
}

export function updateStructuralAssessment(
  position: Position,
  assessment: StructuralAssessment,
): Position {
  assertAirHandlingUnitAssigned(position);

  return {
    ...position,
    structuralAssessments: position.structuralAssessments.map((a) =>
      a.id === assessment.id ? assessment : a,
    ),
  };
}

export function deleteStructuralAssessment(
  position: Position,
  assessmentId: string,
): Position {
  assertAirHandlingUnitAssigned(position);

  return {
    ...position,
    structuralAssessments: position.structuralAssessments.filter(
      (a) => a.id !== assessmentId,
    ),
  };
}

export function updateVisualInspectionRequired(
  position: Position,
  value: boolean,
): Position {
  return {
    ...position,
    visualInspectionRequired: value,
  };
}

export function addVisualAssessment(
  position: Position,
  componentId: string,
  assessmentId: string,
): Position {
  assertAirHandlingUnitAssigned(position);

  const assessment = newVisual(
    assessmentId,
    position.airHandlingUnitId!,
    componentId,
  );

  return {
    ...position,
    visualAssessments: [...(position.visualAssessments ?? []), assessment],
  };
}

export function updateVisualAssessment(
  position: Position,
  assessment: VisualAssessment,
): Position {
  assertAirHandlingUnitAssigned(position);

  return {
    ...position,
    visualAssessments: position.visualAssessments.map((a) =>
      a.id === assessment.id ? assessment : a,
    ),
  };
}

export function deleteVisualAssessment(
  position: Position,
  assessmentId: string,
): Position {
  assertAirHandlingUnitAssigned(position);

  return {
    ...position,
    visualAssessments: position.visualAssessments.filter(
      (a) => a.id !== assessmentId,
    ),
  };
}

export function updateMicrobialInspectionRequired(
  position: Position,
  value: boolean,
): Position {
  return {
    ...position,
    microbialInspectionRequired: value,
  };
}

export function addMicrobialAssessment(
  position: Position,
  componentId: string,
  assessmentId: string,
  kind: Kind,
  sampleId: EntityId,
): Position {
  assertAirHandlingUnitAssigned(position);

  const assessment = newMicrobial(
    assessmentId,
    position.airHandlingUnitId!,
    componentId,
    kind,
    sampleId,
  );

  return {
    ...position,
    microbialAssessments: [
      ...(position.microbialAssessments ?? []),
      assessment,
    ],
  };
}

export function deleteMicrobialAssessment(
  position: Position,
  assessmentId: string,
): Position {
  assertAirHandlingUnitAssigned(position);

  return {
    ...position,
    microbialAssessments: position.microbialAssessments.filter(
      (a) => a.id !== assessmentId,
    ),
  };
}

export function updatePhotoDocumentationRequired(
  position: Position,
  value: boolean,
): Position {
  return {
    ...position,
    photoDocumentationRequired: value,
  };
}

export function addPhoto(
  position: Position,
  componentId: string,
  photoId: string,
): Position {
  assertAirHandlingUnitAssigned(position);

  const photo = newPhoto(photoId, position.airHandlingUnitId!, componentId);

  return {
    ...position,
    photos: [...(position.photos ?? []), photo],
  };
}

export function updatePhoto(position: Position, photo: Photo): Position {
  assertAirHandlingUnitAssigned(position);

  return {
    ...position,
    photos: position.photos.map((p) => (p.id === photo.id ? photo : p)),
  };
}

export function deletePhoto(position: Position, photoId: string): Position {
  assertAirHandlingUnitAssigned(position);

  return {
    ...position,
    photos: position.photos.filter((p) => p.id !== photoId),
  };
}
