import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { useParams } from "react-router-dom";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { LaboratoryName } from "@/shared/components/domain/samples/laboratory-name";
import { DeliveryStatusDialog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/laboratory/_components/delivery-status-dialog";
import { CreateSampleDeliveryDialog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/laboratory/_components/create-sample-delivery-dialog";
import { CancelDeliveryDialog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/laboratory/_components/cancel-delivery-dialog";
import { Delivery } from "@/services/backend/samples/delivery/delivery";
import { useSamplesDeliveryListQuery } from "@/services/backend/samples/delivery/service";

export function LaboratoryRoute() {
  const { workOrderId } = useParams();
  const {
    data: sampleDeliveries,
    isLoading,
    error,
  } = useSamplesDeliveryListQuery({
    artefact: { active: true, values: [workOrderId!] },
  });

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{t("Probenlieferungen")}</CardTitle>
        </CardHeader>
        <CardContent>
          <Skeleton className="h-4 w-52" />
          <Skeleton className="h-4 w-52" />
          <Skeleton className="h-4 w-52" />
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <SampleDeliveriesCard
      workOrderId={workOrderId!}
      sampleDeliveries={sampleDeliveries!.deliveries}
    />
  );
}

function SampleDeliveriesCard({
  workOrderId,
  sampleDeliveries,
}: {
  workOrderId: string;
  sampleDeliveries: Delivery[];
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Probenlieferungen")}</span>
          <CreateSampleDeliveryDialog workOrderId={workOrderId} />
        </CardTitle>
      </CardHeader>
      <SampleDeliveriesTable sampleDeliveries={sampleDeliveries} />
    </Card>
  );
}

function SampleDeliveriesTable({
  sampleDeliveries,
}: {
  sampleDeliveries: Delivery[];
}) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Tracking ID")}</TableHead>
          <TableHead>{t("Labor")}</TableHead>
          <TableHead>{t("Gesendet Am")}</TableHead>
          <TableHead>{t("Status")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sampleDeliveries.map((sampleDelivery) => (
          <TableRow key={sampleDelivery.id}>
            <TableCell>{sampleDelivery.trackingId}</TableCell>
            <TableCell>
              <LaboratoryName id={sampleDelivery.labId!} />
            </TableCell>
            <TableCell>
              {formatDate(sampleDelivery.sentAt)},{" "}
              {formatTime(sampleDelivery.sentAt)}
            </TableCell>
            <TableCell>
              <DeliveryStatusDialog delivery={sampleDelivery} />
            </TableCell>
            <TableCell>
              {!sampleDelivery.cancelled && (
                <CancelDeliveryDialog delivery={sampleDelivery} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
