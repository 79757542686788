import { NavLink, Outlet, useParams } from "react-router-dom";

import t from "@/lang/lang";
import { CreateCalculationDialog } from "@/routes/gemex/processes/qpm/calculations/_components/create-calculation-dialog";
import { useQpmCalculationListQuery } from "@/services/backend/qpm/calculations/service";

import { ChevronDown, PanelsTopLeft } from "lucide-react";
import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import { FilterMode } from "@/shared/lib/filter/filter";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ArtefactMeta } from "@/services/backend/qpm/artefact-meta/types";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export default function CalculationsRoute() {
  const { processId } = useParams();
  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Kalkulationen")}</MilestonePageTitle>
        <div className="flex items-center space-x-2">
          <SubNavigation />
          <CreateCalculationDialog processId={processId!} />
        </div>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet />
      </MilestonePageContent>
    </MilestonePage>
  );
}

function SubNavigation() {
  const { processId, calculationId } = useParams();

  const { data: list } = useQpmCalculationListQuery({
    ownerType: {
      active: false,
      values: [],
    },
    process: {
      active: true,
      values: [processId!],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
  });

  const currentPageLabel = () => {
    if (calculationId) {
      <ArtefactNumberById artefactId={calculationId} />;
    }

    return t("Übersicht");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{currentPageLabel()}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <NavLink to="">
            <DropdownMenuItem>
              <PanelsTopLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Kalkulationen")}</DropdownMenuLabel>
        {[...(list?.artefacts ?? [])].sort(byCreatedAt).map((artefact) => (
          <NavLink to={artefact.id} key={artefact.id}>
            <DropdownMenuItem>
              <ArtefactNumberById artefactId={artefact.id} />
            </DropdownMenuItem>
          </NavLink>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function byCreatedAt(a: ArtefactMeta, b: ArtefactMeta) {
  return a.createdAt.localeCompare(b.createdAt);
}
