import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { Kind } from "@/services/backend/samples/sample/kind";

export interface Delivery {
  id: string;
  artefactId: string;
  artefactNamespace: string;
  trackingId: string;
  description: string;
  labId: EntityId;
  items: Item[];
  sent: boolean;
  sentAt: string;
  cancelled: boolean;
  cancelledAt: string;
}

export interface Item {
  id: string;
  deliveryId: string;
  kind: Kind;
  sampleId: string;
  cancelled: boolean;
}

export function containsSampleId(
  delivery: Delivery,
  sampleId: string,
): boolean {
  return delivery.items.some((item) => item.sampleId === sampleId);
}
