export enum CalculationType {
  ALoRa = "alora",
  QPM = "qpm",
}

export enum CalculationServiceType {
  Installation = "installation",
  Maintenance = "maintenance",
}

export enum CalculationServiceEntryType {
  CheckPoint = "checkpoint",
  CheckPointSCB = "checkpoint-scb",
  ALoRa = "alora",
  FIV = "fiv",
}

export enum CalculationNavLinkType {
  MaterialCost = "material",
  Installation = "installation",
  Maintenance = "maintenance",
  Rental = "rental",
  TotalOverview = "total",
}
