import {
  Position,
  updateDescription,
  updateMicrobialInspectionRequired,
  updatePhotoDocumentationRequired,
  updateStructuralInspectionRequired,
  updateVisualInspectionRequired,
} from "@/services/backend/htz/work-order/work-order";
import { useState } from "react";
import { TableCell, TableRow } from "@/shared/components/ui/table";
import { Textarea } from "@/shared/components/ui/textarea";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, ChevronRight } from "lucide-react";
import { Checkbox } from "@/shared/components/ui/checkbox";

export function PositionDetailsRow({
  position,
  onPositionChange,
  disabled,
}: {
  position: Position;
  onPositionChange: (position: Position) => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <PositionDetailsRowToggle open={open} toggle={() => setOpen(!open)} />
      {open && (
        <>
          <TableRow className="border-b-0">
            <TableCell className="bg-background py-2"> </TableCell>
            <TableCell className="bg-background py-2" colSpan={8}>
              <Textarea
                value={position.description}
                onChange={(e) =>
                  onPositionChange(updateDescription(position, e.target.value))
                }
                disabled={disabled}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bg-background py-2"> </TableCell>
            <TableCell className="bg-background pt-2" colSpan={8}>
              <div className="grid grid-cols-2 gap-2">
                <InspectionRequiredCheckbox
                  label={t("Konstruktive Beurteilung nötig")}
                  checked={position.structuralInspectionRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updateStructuralInspectionRequired(position, value),
                    )
                  }
                  disabled={disabled}
                />
                <InspectionRequiredCheckbox
                  label={t("Visuelle Beurteilung nötig")}
                  checked={position.visualInspectionRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updateVisualInspectionRequired(position, value),
                    )
                  }
                  disabled={disabled}
                />
                <InspectionRequiredCheckbox
                  label={t("Mikrobiologische Inspektion nötig")}
                  checked={position.microbialInspectionRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updateMicrobialInspectionRequired(position, value),
                    )
                  }
                  disabled={disabled}
                />
                <InspectionRequiredCheckbox
                  label={t("Fotodokumentation nötig")}
                  checked={position.photoDocumentationRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updatePhotoDocumentationRequired(position, value),
                    )
                  }
                  disabled={disabled}
                />
              </div>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

function PositionDetailsRowToggle({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}) {
  return (
    <TableRow className={open ? "border-b-0" : ""}>
      <TableCell className="h-6 bg-background py-0 pb-1"> </TableCell>
      <TableCell colSpan={8} className="h-6 bg-background py-0 pb-1">
        <Button className="h-6 px-1.5" variant="ghost" onClick={toggle}>
          {open ? (
            <ChevronDown className="mr-2 h-4 w-4" />
          ) : (
            <ChevronRight className="mr-2 h-4 w-4" />
          )}
          <span>{t("Details")}</span>
        </Button>
      </TableCell>
    </TableRow>
  );
}

interface InspectionRequiredCheckboxProps {
  label: string;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  disabled?: boolean;
}

export function InspectionRequiredCheckbox({
  label,
  checked,
  onCheckedChange,
  disabled,
}: InspectionRequiredCheckboxProps) {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={label}
        checked={checked}
        onCheckedChange={(state) => {
          switch (state) {
            case true:
            case false:
              onCheckedChange(state);
              break;
            default:
          }
        }}
        disabled={disabled}
      />
      <label
        htmlFor={label}
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  );
}
