import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  Mutation,
  useArtefactDBDelete,
  useArtefactDBGet,
  useArtefactDBGetAll,
  useArtefactDBPut,
} from "@/shared/indexdb/artefact-db-hooks";
import { FilterMode } from "@/shared/lib/filter/filter";
import { WaterSample } from "@/services/backend/samples/sample/water-sample";
import {
  PutRequest,
  useSamplesWaterSampleDeleteMutation,
  useSamplesWaterSampleListQuery,
  useSamplesWaterSamplePutMutation,
  useSamplesWaterSampleShowQuery,
} from "@/services/backend/samples/sample/water-sample-service";
import { DeleteRequest } from "@/services/backend/samples/sample/air-microbial-service";
import { useParams } from "react-router-dom";

export function usePutWaterSample(): Mutation<WaterSample, WaterSample> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [putWaterSampleMutation, { isLoading, error, isSuccess, reset }] =
    useSamplesWaterSamplePutMutation();

  const [
    putWaterSampleIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBPut<PutRequest, WaterSample>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "waterSample",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [putWaterSampleMutation, { isLoading, error, isSuccess, reset }];
    case OfflineState.OFFLINE:
      return [
        putWaterSampleIndexedDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Put of sample set not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}

export function useGetWaterSample(id: string) {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesWaterSampleShowQuery({
    id,
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGet<WaterSample>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "waterSample",
    id,
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useListWaterSamples() {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesWaterSampleListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrderId!],
    },
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGetAll<WaterSample>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "waterSample",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData?.samples,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useDeleteWaterSample(): Mutation<DeleteRequest, null> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [deleteWaterSampleMutation, { isLoading, error, isSuccess, reset }] =
    useSamplesWaterSampleDeleteMutation();

  const [
    deleteWaterSampleIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBDelete(ArtefactKind.WorkOrder, workOrderId!, "waterSample");
  const deleteIdb = async (request: DeleteRequest) => {
    const result = await deleteWaterSampleIndexedDB(request.id);
    if (result) {
      return result;
    }
    // This should never happen.
    throw new Error("Delete of sample set failed.");
  };

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        deleteWaterSampleMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        deleteIdb,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Delete of sample not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}
