import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input } from "@/shared/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export type TimeNumberInputProps = {
  id?: string;
  value: number; // Value in nanoseconds
  onChange?: (value: number) => void;
  timeUnit?: TimeUnit; // Fixed time unit if provided
  className?: string;
  disabled?: boolean;
  locale?: string; // Optional locale, defaults to 'de-DE' for ',' as decimal separator
};

// export function TimeNumberInput({
//   id,
//   value,
//   onChange,
//   timeUnit,
//   className = "",
//   disabled = false,
//   locale = "de-DE",
// }: TimeNumberInputProps) {
//   const [unit, setUnit] = useState<TimeUnit>(TimeUnit.Minutes);
//   const inputRef = useRef<HTMLInputElement>(null);
//
//   const activeUnit = timeUnit || unit; // Active unit based on prop or state
//
//   // Memoized formatter for locale-specific decimal handling
//   const formatter = useMemo(
//     () =>
//       new Intl.NumberFormat(locale, {
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2,
//       }),
//     [locale],
//   );
//
//   const formatLocaleNumber = useCallback(
//     (number: number) => formatter.format(Number.isNaN(number) ? 0 : number),
//     [formatter],
//   );
//
//   const parseLocaleNumber = useCallback(
//     (input: string) => {
//       const separator = formatter.format(1.1).charAt(1); // Get decimal separator
//       const normalized = input.replace(separator, ".");
//       return parseFloat(normalized);
//     },
//     [formatter],
//   );
//
//   // Conversion functions
//   const convertFromNanoseconds = useCallback((ns: number, u: TimeUnit) => {
//     const minutes = ns / 1e9 / 60;
//     return u === TimeUnit.Hours ? minutes / 60 : minutes;
//   }, []);
//
//   const convertToNanoseconds = useCallback((time: number, u: TimeUnit) => {
//     const minutes = u === TimeUnit.Hours ? time * 60 : time;
//     return minutes * 60 * 1e9;
//   }, []);
//
//   const [inputValue, setInputValue] = useState(() =>
//     value === 0
//       ? ""
//       : formatLocaleNumber(convertFromNanoseconds(value, activeUnit)),
//   );
//
//   useEffect(() => {
//     setInputValue(
//       value === 0
//         ? ""
//         : formatLocaleNumber(convertFromNanoseconds(value, activeUnit)),
//     );
//   }, [value, activeUnit, formatLocaleNumber, convertFromNanoseconds]);
//
//   const updateValue = useCallback(
//     (increment: number) => {
//       const parsedValue = parseLocaleNumber(inputValue);
//       if (!Number.isNaN(parsedValue)) {
//         const newValue = Math.max(0, parsedValue + increment);
//         const nanoseconds = convertToNanoseconds(newValue, activeUnit);
//         const roundedValue = convertFromNanoseconds(nanoseconds, activeUnit);
//         setInputValue(formatLocaleNumber(roundedValue));
//         if (onChange) {
//           onChange(nanoseconds);
//         }
//       }
//     },
//     [
//       parseLocaleNumber,
//       inputValue,
//       formatLocaleNumber,
//       convertToNanoseconds,
//       convertFromNanoseconds,
//       activeUnit,
//       onChange,
//     ],
//   );
//
//   useEffect(() => {
//     const handleKeyDown = (e: KeyboardEvent) => {
//       if (!disabled) {
//         if (e.shiftKey) {
//           if (e.key === "ArrowUp") {
//             e.preventDefault();
//             updateValue(1);
//           } else if (e.key === "ArrowDown") {
//             e.preventDefault();
//             updateValue(-1);
//           } else if (e.key === "ArrowLeft") {
//             e.preventDefault();
//             updateValue(-10);
//           } else if (e.key === "ArrowRight") {
//             e.preventDefault();
//             updateValue(10);
//           }
//         }
//       }
//     };
//
//     const inputElement = inputRef.current;
//     inputElement?.addEventListener("keydown", handleKeyDown);
//
//     return () => {
//       inputElement?.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [updateValue, disabled]);
//
//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const rawValue = e.target.value;
//
//     if (rawValue === "") {
//       setInputValue("0");
//       if (onChange) {
//         onChange(0);
//       }
//       return;
//     }
//
//     setInputValue(rawValue);
//
//     const parsedValue = parseLocaleNumber(rawValue);
//     if (!Number.isNaN(parsedValue)) {
//       const nanoseconds = convertToNanoseconds(parsedValue, activeUnit);
//       if (onChange) {
//         onChange(nanoseconds);
//       }
//     }
//   };
//
//   const handleBlur = () => {
//     const parsedValue = parseLocaleNumber(inputValue);
//     if (!Number.isNaN(parsedValue)) {
//       const nanoseconds = convertToNanoseconds(parsedValue, activeUnit);
//       const roundedValue = convertFromNanoseconds(nanoseconds, activeUnit);
//       setInputValue(formatLocaleNumber(roundedValue));
//       if (onChange) {
//         onChange(nanoseconds);
//       }
//     } else {
//       setInputValue(
//         formatLocaleNumber(convertFromNanoseconds(value, activeUnit)),
//       );
//     }
//   };
//
//   return (
//     <div className={`flex items-center gap-2 ${className}`}>
//       <Input
//         id={id}
//         ref={inputRef}
//         type="text"
//         inputMode="decimal"
//         pattern="[0-9]*[.,]?[0-9]*"
//         className="h-8 w-24 text-end"
//         value={inputValue}
//         disabled={disabled}
//         onChange={handleInputChange}
//         onBlur={handleBlur}
//       />
//       {timeUnit ? (
//         <span className="flex h-8 items-center px-1">
//           {TimeUnitUtil.toString(timeUnit)}
//         </span>
//       ) : (
//         <Select
//           value={unit}
//           defaultValue={TimeUnit.Minutes}
//           onValueChange={(val) => setUnit(val as TimeUnit)}
//         >
//           <SelectTrigger className="h-8 w-fit">
//             <SelectValue />
//           </SelectTrigger>
//           <SelectContent>
//             <SelectItem value={TimeUnit.Minutes}>
//               {t(TimeUnitUtil.toLongString(TimeUnit.Minutes))}
//             </SelectItem>
//             <SelectItem value={TimeUnit.Hours}>
//               {t(TimeUnitUtil.toLongString(TimeUnit.Hours))}
//             </SelectItem>
//           </SelectContent>
//         </Select>
//       )}
//     </div>
//   );
// }
export function TimeNumberInput({
  id,
  value,
  onChange,
  timeUnit,
  className = "",
  disabled = false,
  locale = "de-DE",
}: TimeNumberInputProps) {
  const [unit, setUnit] = useState<TimeUnit>(TimeUnit.Minutes);
  const [inputValue, setInputValue] = useState<string>("");

  const activeUnit = timeUnit || unit;

  // Formatter for locale-specific handling
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    [locale],
  );

  const formatLocaleNumber = useCallback(
    (number: number) => formatter.format(Number.isNaN(number) ? 0 : number),
    [formatter],
  );

  const parseLocaleNumber = useCallback(
    (input: string) => {
      const separator = formatter.format(1.1).charAt(1); // Get decimal separator
      const normalized = input.replace(separator, ".");
      return parseFloat(normalized);
    },
    [formatter],
  );

  const convertFromNanoseconds = useCallback((ns: number, u: TimeUnit) => {
    const minutes = ns / 1e9 / 60;
    return u === TimeUnit.Hours ? minutes / 60 : minutes;
  }, []);

  const convertToNanoseconds = useCallback((time: number, u: TimeUnit) => {
    const minutes = u === TimeUnit.Hours ? time * 60 : time;
    return minutes * 60 * 1e9;
  }, []);

  // Initialize the input value from the `value` prop
  useEffect(() => {
    setInputValue(
      value === 0
        ? ""
        : formatLocaleNumber(convertFromNanoseconds(value, activeUnit)),
    );
  }, [value, activeUnit, formatLocaleNumber, convertFromNanoseconds]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value); // Allow free input
  };

  const handleBlurOrEnter = () => {
    const parsedValue = parseLocaleNumber(inputValue);
    if (!Number.isNaN(parsedValue)) {
      const nanoseconds = convertToNanoseconds(parsedValue, activeUnit);
      const roundedValue = convertFromNanoseconds(nanoseconds, activeUnit);
      setInputValue(formatLocaleNumber(roundedValue)); // Format the input value
      if (onChange) {
        onChange(nanoseconds); // Propagate to the parent
      }
    } else {
      // Reset to last valid value on invalid input
      setInputValue(
        formatLocaleNumber(convertFromNanoseconds(value, activeUnit)),
      );
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleBlurOrEnter(); // Trigger formatting on Enter key
    }
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <Input
        id={id}
        type="text"
        inputMode="decimal"
        pattern="[0-9]*[.,]?[0-9]*"
        className="h-8 w-24 text-end"
        value={inputValue}
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={handleBlurOrEnter} // Format on blur
        onKeyDown={handleKeyDown} // Format on Enter key
      />
      {timeUnit ? (
        <span className="flex h-8 items-center px-1">
          {TimeUnitUtil.toString(timeUnit)}
        </span>
      ) : (
        <Select
          value={unit}
          defaultValue={TimeUnit.Minutes}
          onValueChange={(val) => setUnit(val as TimeUnit)}
        >
          <SelectTrigger className="h-8 w-fit">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={TimeUnit.Minutes}>
              {TimeUnitUtil.toLongString(TimeUnit.Minutes)}
            </SelectItem>
            <SelectItem value={TimeUnit.Hours}>
              {TimeUnitUtil.toLongString(TimeUnit.Hours)}
            </SelectItem>
          </SelectContent>
        </Select>
      )}
    </div>
  );
}

export enum TimeUnit {
  Seconds = "seconds",
  Minutes = "minutes",
  Hours = "hours",
}

export class TimeUnitUtil {
  private static readonly descriptions: {
    [key in TimeUnit]: string;
  } = {
    [TimeUnit.Seconds]: "Sekunden",
    [TimeUnit.Minutes]: "Minuten",
    [TimeUnit.Hours]: "Stunden",
  };

  private static readonly abbreviations: {
    [key in TimeUnit]: string;
  } = {
    [TimeUnit.Seconds]: "s",
    [TimeUnit.Minutes]: "min",
    [TimeUnit.Hours]: "h",
  };

  static toString(type: TimeUnit): string {
    return this.abbreviations[type];
  }

  static toLongString(type: TimeUnit): string {
    return this.descriptions[type] || "Unknown";
  }
}
