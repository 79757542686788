import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Skeleton } from "@/shared/components/ui/skeleton";
import t from "@/lang/lang";
import { Contact } from "@/services/backend/contacts/contact/contact";

export function ContactInfoCard({ contact }: { contact: Contact }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{contact.name}</CardTitle>
        <CardDescription>{contact.function}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-between gap-2">
          <div className="font-medium">{t("Telefon")}</div>
          <div>{contact.phone}</div>
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="font-medium">{t("Mobil")}</div>
          <div>{contact.mobilephone}</div>
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="font-medium">{t("E-Mail")}</div>
          <div>{contact.email}</div>
        </div>
      </CardContent>
    </Card>
  );
}

export function ContactInfoCardSkeleton() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Skeleton className="h-6 w-48" />
        </CardTitle>
        <Skeleton className="h-4 w-32" />
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-between gap-2">
          <div className="font-medium">{t("Telefon")}</div>
          <Skeleton className="h-4 w-32" />
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="font-medium">{t("Mobil")}</div>
          <Skeleton className="h-4 w-32" />
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="font-medium">{t("E-Mail")}</div>
          <Skeleton className="h-4 w-32" />
        </div>
      </CardContent>
    </Card>
  );
}
