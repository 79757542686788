import {
  Mutation,
  Query,
  useArtefactDBGetAll,
  useArtefactDBPut,
} from "@/shared/indexdb/artefact-db-hooks";
import { Position } from "@/services/backend/htz/work-order/work-order";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import {
  useHtzWorkOrderListPositionQuery,
  useHtzWorkOrderPutPositionMutation,
} from "@/services/backend/htz/work-order/service";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { useParams } from "react-router-dom";

export function usePutPosition(): Mutation<Position, Position> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [putPositionMutation, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderPutPositionMutation();

  const [
    putPositionIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBPut<Position, Position>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "position",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [putPositionMutation, { isLoading, error, isSuccess, reset }];
    case OfflineState.OFFLINE:
      return [
        putPositionIndexedDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Put of position not possible is state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}

export function useListPosition(): Query<Position[]> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading: serverIsLoading,
    error: serverError,
  } = useHtzWorkOrderListPositionQuery({
    workOrder: {
      active: true,
      values: [workOrderId!],
    },
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGetAll<Position>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "position",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData?.positions,
        isLoading: serverIsLoading,
        error: serverError,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}
