import { Navigate, RouteObject } from "react-router-dom";
import { RequirementsOverview } from "@/routes/gemex/processes/qpm/requirements/requirement/requirements-overview";
import CreateRequirement from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import { AdditionalInfoRoute } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/additional-information-component";
import { CompleteOverviewComponentRoute } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/complete-overview/complete-overview-component-route";
import { MaterialRequirementRoute } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/material-requirement-component";
import { RequirementDetailsRoute } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/requirement-details-component";

export const requirementRoutes: RouteObject[] = [
  {
    index: true,
    element: <RequirementsOverview />,
  },
  {
    path: ":requirementId",
    element: <CreateRequirement />,
    children: [
      {
        index: true,
        element: <Navigate to="inventory" replace />,
      },
      {
        path: "info",
        element: <RequirementDetailsRoute />,
      },
      {
        path: "inventory",
        element: <MaterialRequirementRoute />,
      },
      {
        path: "additional-info",
        element: <AdditionalInfoRoute />,
      },
      {
        path: "total",
        element: <CompleteOverviewComponentRoute />,
      },
    ],
  },
];
