import { PositionsTable } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/positions-table";
import { useParams } from "react-router-dom";
import { useGetWorkOrder } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/work-order-hooks";
import { PositionsTableSkeleton } from "@/routes/gesec/processes/[processId]/htz/_shared/positions/positions-table";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export function PositionsRoute() {
  const { workOrderId } = useParams();
  const { data: workOrder, isLoading, error } = useGetWorkOrder(workOrderId!);

  if (isLoading) {
    return <PositionsTableSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!workOrder) {
    return null;
  }

  return <PositionsTable workOrder={workOrder} />;
}
