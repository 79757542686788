import { CalculationServiceType } from "@/services/backend/qpm/calculations/enum";

export class CalculationServiceTypeUtil {
  private static readonly descriptions: {
    [key in CalculationServiceType]: string;
  } = {
    [CalculationServiceType.Installation]: "Einrichtung",
    [CalculationServiceType.Maintenance]: "Service",
  };

  private static readonly abbreviations: {
    [key in CalculationServiceType]: string;
  } = {
    [CalculationServiceType.Installation]: "Einrichtung",
    [CalculationServiceType.Maintenance]: "Service",
  };

  static toString(type: CalculationServiceType): string {
    return this.abbreviations[type];
  }

  static toLongString(type: CalculationServiceType): string {
    return this.descriptions[type] || "Unknown";
  }
}
