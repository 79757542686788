import {
  addStructuralAssessment,
  deleteStructuralAssessment,
  updateStructuralAssessment,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { useEffect, useState } from "react";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import { Button } from "@/shared/components/ui/button";
import { AlertCircle, Plus, PlusCircle, Trash2 } from "lucide-react";
import t from "@/lang/lang";
import {
  fromCatalogItem,
  StructuralAssessment,
} from "@/services/backend/htz/inspection/structural-assessment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { cn } from "@/shared/lib/utils";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Textarea } from "@/shared/components/ui/textarea";
import { Card } from "@/shared/components/ui/card";
import { H4 } from "@/shared/components/ui/typography";
import { Separator } from "@/shared/components/ui/separator";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { ScrollArea, ScrollBar } from "@/shared/components/ui/scroll-area";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import {
  Item,
  itemsForComponentKind,
} from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { v4 } from "uuid";
import {
  Dot,
  SeveritySelect,
} from "@/services/backend/htz/inspection/severity";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  fieldErrors,
  hasFieldError,
} from "@/shared/app-lib/errors/validation-error";
import { useGetStructuralAssessmentCatalog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-get-structural-assessment-catalog";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function StructuralInspection({ workOrder }: { workOrder: WorkOrder }) {
  const [assessmentId, setAssessmentId] = useState<string | null>(null);
  const { position, activeComponent } = useInspectionContext();

  return (
    <ScrollArea className="h-[65vh] space-y-2">
      <StructuralAssessmentTable
        selectedAssessmentId={assessmentId}
        onAssessmentSelectedId={setAssessmentId}
      />
      <div className="p-2 py-4">
        {position.structuralAssessments
          .filter((a) => a.componentId === activeComponent?.id)
          .map((assessment) => {
            if (assessment.id !== assessmentId) return null;
            return (
              <StructuralAssessmentForm
                key={assessment.id}
                workOrder={workOrder}
                assessment={assessment}
              />
            );
          })}
      </div>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
}

export function AddStructuralAssessmentButton({
  workOrder,
}: {
  workOrder: WorkOrder;
}) {
  const {
    position,
    onPositionChange: [putPosition, { error, isLoading, reset }],
  } = useInspectionContext();

  const { data: structuralAssessmentCatalog } =
    useGetStructuralAssessmentCatalog(position.workOrderId);

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);
  const { activeComponent } = useInspectionContext();

  const { resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorKey = `position.${position.id}.structuralAssessments`;

  const doAdd = () => {
    if (isLoading) return;
    putPosition(addStructuralAssessment(position, activeComponent!.id, v4()));
    resetNotReadyErrorField(errorKey);
  };

  if (!structuralAssessmentCatalog) {
    return null;
  }

  const doAddFromCatalog = (item: Item) => {
    const assessment = fromCatalogItem(
      v4(),
      position.airHandlingUnitId,
      activeComponent!.id,
      item,
    );

    const posWithAssessment = addStructuralAssessment(
      position,
      activeComponent!.id,
      assessment.id,
    );
    const posWithAssData = updateStructuralAssessment(
      posWithAssessment,
      assessment,
    );
    putPosition(posWithAssData);
    resetNotReadyErrorField(errorKey);
  };

  const items = itemsForComponentKind(
    structuralAssessmentCatalog,
    activeComponent?.componentKindId ?? "",
  );

  const disabled =
    !workOrder || workOrder.immutable || activeComponent === null || isLoading;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button>
          <PlusCircle />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Beurteilung hinzufügen")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={doAdd}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Blanko")}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Aus Vorlage:")}</DropdownMenuLabel>
        {items.map((item) => (
          <DropdownMenuItem
            onClick={() => doAddFromCatalog(item)}
            key={item.id}
          >
            <Plus className="mr-2 h-4 w-4" />
            <span>{`${item.shorthand} (${item.element}) ${item.deficiency}`}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function StructuralAssessmentTable({
  selectedAssessmentId,
  onAssessmentSelectedId,
}: {
  selectedAssessmentId: string | null;
  onAssessmentSelectedId: (a: string | null) => void;
}) {
  const { position, activeComponent } = useInspectionContext();

  const toggle = (assessment: StructuralAssessment) => {
    if (assessment.id === selectedAssessmentId) {
      onAssessmentSelectedId(null);
    } else {
      onAssessmentSelectedId(assessment.id);
    }
  };

  const { notReadyError } = useErrArtefactNotReady();
  const errorPrefix = `position.${position.id}.structuralAssessments.`;

  return (
    <Card className="shadow-none">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="h-8">{t("Kürzel")}</TableHead>
            <TableHead className="h-8">{t("Element")}</TableHead>
            <TableHead className="h-8">{t("Mängel")}</TableHead>
            <TableHead className="h-8">{t("")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {position.structuralAssessments
            ?.filter((a) => a.componentId === activeComponent?.id)
            .map((assessment) => (
              <TableRow
                key={assessment.id}
                className={cn(
                  "cursor-pointer",
                  assessment.id === selectedAssessmentId ? "bg-accent" : "",
                )}
              >
                <TableCell className="py-1" onClick={() => toggle(assessment)}>
                  <span className="flex items-center space-x-2">
                    {hasFieldError(
                      notReadyError,
                      `${errorPrefix}${assessment.id}`,
                    ) && <AlertCircle className="h-6 w-6 text-red-500" />}
                    <span>{assessment.shorthand}</span>
                  </span>
                </TableCell>
                <TableCell
                  className="w-full py-1"
                  onClick={() => toggle(assessment)}
                >
                  {assessment.element}
                </TableCell>
                <TableCell className="py-1" onClick={() => toggle(assessment)}>
                  <Dot severity={assessment.severity} />
                </TableCell>
                <TableCell className="py-1 text-right">
                  <DeleteAssessmentButton
                    assessmentId={assessment.id}
                    onDeleted={() => onAssessmentSelectedId(null)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function DeleteAssessmentButton({
  assessmentId,
  onDeleted,
}: {
  assessmentId: string;
  onDeleted: () => void;
}) {
  const {
    position,
    onPositionChange: [putPosition, { error, isLoading, reset }],
  } = useInspectionContext();

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const doDelete = () => {
    if (isLoading) return;

    onDeleted();
    putPosition(deleteStructuralAssessment(position, assessmentId));
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="destructive" className="h-8 w-8 p-0">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Beurteilung löschen?")}</DialogTitle>
          <DialogDescription>
            {t(
              "Die Beurteilung wird unwiderruflich gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function StructuralAssessmentForm({
  workOrder,
  assessment: propAssessment,
}: {
  workOrder: WorkOrder;
  assessment: StructuralAssessment;
}) {
  const [assessment, setAssessment] = useState(propAssessment);
  const {
    position,
    onPositionChange: [putPosition],
  } = useInspectionContext();

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorPrefix = `position.${position.id}.structuralAssessments.${assessment.id}.`;

  const onChange = (a: StructuralAssessment) => {
    setAssessment(a);
    putPosition(updateStructuralAssessment(position, a));
  };

  return (
    <div className="h-full">
      <H4>{t("Beurteilung")}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-1 grid content-start gap-1.5">
            <Label>{t("Kürzel")}</Label>
            <Input
              type="text"
              value={assessment.shorthand}
              onChange={(e) => {
                onChange({ ...assessment, shorthand: e.target.value });
                resetNotReadyErrorField(`${errorPrefix}shorthand`);
              }}
              className={cn(
                hasFieldError(notReadyError, `${errorPrefix}shorthand`) &&
                  "border-red-500 shadow-md",
              )}
              disabled={workOrder.immutable}
            />
            <InputValidationErrors
              error={notReadyError}
              field={`${errorPrefix}shorthand`}
            />
          </div>
          <div className="col-span-3 grid content-start gap-1.5">
            <Label>{t("Element")}</Label>
            <Input
              type="text"
              value={assessment.element}
              onChange={(e) => {
                onChange({ ...assessment, element: e.target.value });
                resetNotReadyErrorField(`${errorPrefix}element`);
              }}
              className={cn(
                hasFieldError(notReadyError, `${errorPrefix}element`) &&
                  "border-red-500 shadow-md",
              )}
              disabled={workOrder.immutable}
            />
            <InputValidationErrors
              error={notReadyError}
              field={`${errorPrefix}element`}
            />
          </div>
          <div className="col-span-2 content-start">
            <SeveritySelect
              severity={assessment.severity}
              onSeverityChange={(severity) => {
                onChange({ ...assessment, severity });
                resetNotReadyErrorField(`${errorPrefix}severity`);
              }}
              errors={
                fieldErrors(notReadyError, `${errorPrefix}severity`) ?? []
              }
            />
          </div>
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Anforderung")}</Label>
          <Textarea
            value={assessment.requirement}
            onChange={(e) => {
              onChange({ ...assessment, requirement: e.target.value });
              resetNotReadyErrorField(`${errorPrefix}requirement`);
            }}
            className={cn(
              hasFieldError(notReadyError, `${errorPrefix}requirement`) &&
                "border-red-500 shadow-md",
            )}
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={notReadyError}
            field={`${errorPrefix}requirement`}
          />
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Mangel")}</Label>
          <Textarea
            value={assessment.deficiency}
            onChange={(e) => {
              onChange({ ...assessment, deficiency: e.target.value });
              resetNotReadyErrorField(`${errorPrefix}deficiency`);
            }}
            className={cn(
              hasFieldError(notReadyError, `${errorPrefix}deficiency`) &&
                "border-red-500 shadow-md",
            )}
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={notReadyError}
            field={`${errorPrefix}deficiency`}
          />
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Handlungsempfehlung")}</Label>
          <Textarea
            value={assessment.recommendation}
            onChange={(e) => {
              onChange({ ...assessment, recommendation: e.target.value });
              resetNotReadyErrorField(`${errorPrefix}recommendation`);
            }}
            className={cn(
              hasFieldError(notReadyError, `${errorPrefix}recommendation`) &&
                "border-red-500 shadow-md",
            )}
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={notReadyError}
            field={`${errorPrefix}recommendation`}
          />
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Bemerkung")}</Label>
          <Textarea
            value={assessment.comment}
            onChange={(e) => {
              onChange({ ...assessment, comment: e.target.value });
              resetNotReadyErrorField(`${errorPrefix}comment`);
            }}
            className={cn(
              hasFieldError(notReadyError, `${errorPrefix}comment`) &&
                "border-red-500 shadow-md",
            )}
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={notReadyError}
            field={`${errorPrefix}comment`}
          />
        </div>
      </div>
    </div>
  );
}
