import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import {
  Dependency,
  useWorkOrderContextProviderDependenciesOnline,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-work-order-context-provider-dependencies";
import { useToast } from "@/shared/hooks/use-toast";
import {
  artefactDbGet,
  artefactDbGetAll,
  artefactDbGetAllDeleted,
  artefactDbPut,
  deleteArtefactDB,
  initArtefactDB,
} from "@/shared/indexdb/artefact-db";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import t from "@/lang/lang";
import {
  Position,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import {
  useHtzWorkOrderDeletePositionMutation,
  useHtzWorkOrderPutMutation,
  useHtzWorkOrderPutPositionMutation,
} from "@/services/backend/htz/work-order/service";
import { Button } from "@/shared/components/ui/button";
import { RefreshCw } from "lucide-react";
import { useHtzAirHandlingUnitPutMutation } from "@/services/backend/htz/ahu/service";
import { useStaleContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/stale-context";
import {
  useSamplesAirMicrobialSampleSetDeleteMutation,
  useSamplesAirMicrobialSampleSetListQuery,
  useSamplesAirMicrobialSampleSetPutMutation,
} from "@/services/backend/samples/sample/air-microbial-service";
import { AirMicrobialSampleSet } from "@/services/backend/samples/sample/air-microbial-sample";
import {
  useSamplesDustDensitySampleDeleteMutation,
  useSamplesDustDensitySampleListQuery,
  useSamplesDustDensitySamplePutMutation,
} from "@/services/backend/samples/sample/dust-density-sample-service";
import {
  useSamplesWaterSampleDeleteMutation,
  useSamplesWaterSampleListQuery,
  useSamplesWaterSamplePutMutation,
} from "@/services/backend/samples/sample/water-sample-service";
import {
  useSamplesSurfaceMicrobialSampleSetDeleteMutation,
  useSamplesSurfaceMicrobialSampleSetListQuery,
  useSamplesSurfaceMicrobialSampleSetPutMutation,
} from "@/services/backend/samples/sample/surface-microbial-service";
import { SurfaceMicrobialSampleSet } from "@/services/backend/samples/sample/surface-microbial-sample";
import { DustDensitySample } from "@/services/backend/samples/sample/dust-density-sample";
import { WaterSample } from "@/services/backend/samples/sample/water-sample";

// TODO
//  * add position catalog
//  * add ahu component catalog
//  * add structural assessment catalog
//  * add visual assessment catalog
//  * add laboratories
//  * add devices

const objectStoreNames = [
  // entities that may be mutated
  { name: "workOrder", keyPath: "id" },
  { name: "airHandlingUnit", keyPath: "id" },
  { name: "position", keyPath: "id" },
  { name: "airMicrobialSampleSet", keyPath: "id" },
  { name: "dustDensitySample", keyPath: "id" },
  { name: "surfaceMicrobialSampleSet", keyPath: "id" },
  { name: "waterSample", keyPath: "id" },
  // entities that are read only
  { name: "dependencies", keyPath: "name" },
];

export function OfflineButton({ workOrderId }: { workOrderId: string }) {
  const { offlineState, setOfflineState, setSynced } = useOfflineContext();
  const { addStale } = useStaleContext();

  // Load all data needed for offline mode
  const {
    data: airMicrobialSampleSets,
    isLoading: airMicrobialIsLoading,
    error: airMicrobialError,
  } = useSamplesAirMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const {
    data: dustDensitySamples,
    isLoading: dustDensityIsLoading,
    error: dustDensityError,
  } = useSamplesDustDensitySampleListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const {
    data: surfaceMicrobialSampleSets,
    isLoading: surfaceMicrobialIsLoading,
    error: surfaceMicrobialError,
  } = useSamplesSurfaceMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const {
    data: waterSamples,
    isLoading: waterIsLoading,
    error: waterError,
  } = useSamplesWaterSampleListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const {
    workOrder,
    positions,
    positionCatalog,
    ahuComponentCatalog,
    structuralAssessmentCatalog,
    visualAssessmentCatalog,
    airHandlingUnits,
    processOffers,
    processOrders,
    laboratories,
    devices,
    isLoading,
    error,
  } = useWorkOrderContextProviderDependenciesOnline(workOrderId);

  const { toast } = useToast();

  // goOfflineHandler prepares the offline mode.
  // The design to await every step is intentional to ensure
  // that the offline mode is correctly prepared. An async
  // setup is possible, but more complex to understand, debug
  // and error handling is more difficult.
  const goOfflineHandler = async () => {
    try {
      // After going offline, all data is set to stale, such that
      // all data is reloaded from the offline database.
      addStale(
        "workOrder",
        "position",
        "airHandlingUnit",
        "airMicrobialSampleSet",
        "dustDensitySample",
        "surfaceMicrobialSampleSet",
        "waterSample",
      );

      // INITIALIZE OFFLINE DATABASE
      setSynced([t("Initialisiere Offline Datenbank ...")]);
      await setOfflineState(OfflineState.PREPARING_OFFLINE);
      // We delete any previous index db for the work order
      // to avoid any unexpected behaviour, maybe after a crash.
      await deleteArtefactDB(ArtefactKind.WorkOrder, workOrderId);

      // initialize the index db for the work order
      const initialized = await initArtefactDB(
        ArtefactKind.WorkOrder,
        workOrderId,
        objectStoreNames,
      );
      if (!initialized) {
        toast({
          title: t("Fehler"),
          description: t(
            "Offline Datenbank konnte nicht initialisiert werden.",
          ),
          variant: "destructive",
        });
        return;
      }

      // Make sure all dependencies are loaded.
      // This is merely a defensive programming measure.
      const allDependenciesAreLoaded =
        airMicrobialSampleSets !== undefined &&
        dustDensitySamples !== undefined &&
        surfaceMicrobialSampleSets !== undefined &&
        waterSamples !== undefined &&
        workOrder !== undefined &&
        positions !== undefined &&
        positionCatalog !== undefined &&
        ahuComponentCatalog !== undefined &&
        structuralAssessmentCatalog !== undefined &&
        visualAssessmentCatalog !== undefined &&
        airHandlingUnits !== undefined &&
        processOffers !== undefined &&
        processOrders !== undefined &&
        laboratories !== undefined &&
        devices !== undefined &&
        !airMicrobialIsLoading &&
        !dustDensityIsLoading &&
        !surfaceMicrobialIsLoading &&
        !waterIsLoading &&
        !isLoading &&
        airMicrobialError === undefined &&
        dustDensityError === undefined &&
        surfaceMicrobialError === undefined &&
        waterError === undefined &&
        error === undefined;
      if (!allDependenciesAreLoaded) {
        toast({
          title: t("Fehler"),
          description: t(
            "Offline Modus kann nicht aktiviert werden, da nicht alle Daten geladen sind.",
          ),
          variant: "destructive",
        });
        await setOfflineState(OfflineState.ONLINE);
        setSynced([]);
        return;
      }
      setSynced([
        t("Datenbank initialisiert"),
        t("Speichere Arbeitsschein ..."),
      ]);

      // FILL THE OFFLINE DATABASE
      // Work order
      await artefactDbPut<WorkOrder>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "workOrder",
        workOrder,
      );
      setSynced([
        t("Datenbank initialisiert"),
        t("Arbeitsschein"),
        t("Speichere Positionen ..."),
      ]);

      // Positions
      // eslint-disable-next-line no-restricted-syntax
      for (const position of positions!) {
        // eslint-disable-next-line no-await-in-loop
        await artefactDbPut<Position>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "position",
          position,
        );
      }
      setSynced([
        t("Datenbank initialisiert"),
        t("Arbeitsschein"),
        t("Positionen"),
        t("Speichere RLT-Anlagen ..."),
      ]);

      // Air handling units
      // eslint-disable-next-line no-restricted-syntax
      for (const ahu of airHandlingUnits) {
        // eslint-disable-next-line no-await-in-loop
        await artefactDbPut<AirHandlingUnit>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "airHandlingUnit",
          ahu,
        );
      }
      setSynced([
        t("Datenbank initialisiert"),
        t("Arbeitsschein"),
        t("Positionen"),
        t("RLT-Anlagen"),
        t("Speichere Proben ..."),
      ]);

      // Samples
      // eslint-disable-next-line no-restricted-syntax
      for (const airMicrobialSampleSet of airMicrobialSampleSets.sampleSets) {
        // eslint-disable-next-line no-await-in-loop
        await artefactDbPut<AirMicrobialSampleSet>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "airMicrobialSampleSet",
          airMicrobialSampleSet,
        );
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const dustDensitySample of dustDensitySamples.samples) {
        // eslint-disable-next-line no-await-in-loop
        await artefactDbPut<DustDensitySample>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "dustDensitySample",
          dustDensitySample,
        );
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const surfaceMicrobialSampleSet of surfaceMicrobialSampleSets.sampleSets) {
        // eslint-disable-next-line no-await-in-loop
        await artefactDbPut<SurfaceMicrobialSampleSet>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "surfaceMicrobialSampleSet",
          surfaceMicrobialSampleSet,
        );
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const waterSample of waterSamples.samples) {
        // eslint-disable-next-line no-await-in-loop
        await artefactDbPut<WaterSample>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "waterSample",
          waterSample,
        );
      }
      setSynced([
        t("Datenbank initialisiert"),
        t("Arbeitsschein"),
        t("Positionen"),
        t("RLT-Anlagen"),
        t("Proben"),
        t("Speichere Sonstige Daten ..."),
      ]);

      // dependencies
      await artefactDbPut<Dependency>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "dependencies",
        {
          name: "dependencies",
          value: {
            ahuComponentCatalog,
            devices,
            laboratories,
            positionCatalog,
            processOffers,
            processOrders,
            structuralAssessmentCatalog,
            visualAssessmentCatalog,
          },
        },
      );
      setSynced([
        t("Datenbank initialisiert"),
        t("Arbeitsschein"),
        t("Positionen"),
        t("RLT-Anlagen"),
        t("Sonstige Daten"),
        t("Starte Offline ..."),
      ]);

      await setOfflineState(OfflineState.OFFLINE);
    } catch (err) {
      toast({
        title: t("Fehler"),
        description:
          err !== null && typeof err === "object" && "message" in err
            ? (err.message as string)
            : t("Fehler beim Vorbereiten des Offline Modus"),
        variant: "destructive",
      });
      await setOfflineState(OfflineState.ONLINE);
    } finally {
      setSynced([]);
    }
  };

  // Mutations needed for synchronization
  const [putAirHandlingUnit, { isLoading: ahuIsLoading }] =
    useHtzAirHandlingUnitPutMutation();

  const [putWorkOrder, { isLoading: workOrderIsLoading }] =
    useHtzWorkOrderPutMutation();

  const [putPosition, { isLoading: positionIsLoading }] =
    useHtzWorkOrderPutPositionMutation();

  const [deletePosition, { isLoading: deletePositionIsLoading }] =
    useHtzWorkOrderDeletePositionMutation();

  const [
    putAirMicrobialSampleSet,
    { isLoading: airMicrobialSampleSetIsLoading },
  ] = useSamplesAirMicrobialSampleSetPutMutation();

  const [putDustDensitySample, { isLoading: dustDensitySampleIsLoading }] =
    useSamplesDustDensitySamplePutMutation();

  const [
    putSurfaceMicrobialSampleSet,
    { isLoading: surfaceMicrobialSampleSetIsLoading },
  ] = useSamplesSurfaceMicrobialSampleSetPutMutation();

  const [putWaterSample, { isLoading: waterSampleIsLoading }] =
    useSamplesWaterSamplePutMutation();

  const [
    deleteAirMicrobialSampleSet,
    { isLoading: deleteAirMicrobialSampleSetIsLoading },
  ] = useSamplesAirMicrobialSampleSetDeleteMutation();

  const [
    deleteDustDensitySample,
    { isLoading: deleteDustDensitySampleIsLoading },
  ] = useSamplesDustDensitySampleDeleteMutation();

  const [
    deleteSurfaceMicrobialSampleSet,
    { isLoading: deleteSurfaceMicrobialSampleSetIsLoading },
  ] = useSamplesSurfaceMicrobialSampleSetDeleteMutation();

  const [deleteWaterSample, { isLoading: deleteWaterSampleIsLoading }] =
    useSamplesWaterSampleDeleteMutation();

  const syncDisabled =
    ahuIsLoading ||
    workOrderIsLoading ||
    positionIsLoading ||
    deletePositionIsLoading ||
    airMicrobialSampleSetIsLoading ||
    dustDensitySampleIsLoading ||
    surfaceMicrobialSampleSetIsLoading ||
    waterSampleIsLoading ||
    deleteAirMicrobialSampleSetIsLoading ||
    deleteDustDensitySampleIsLoading ||
    deleteSurfaceMicrobialSampleSetIsLoading ||
    deleteWaterSampleIsLoading;

  const synchronizeHandler = async () => {
    if (syncDisabled) {
      return;
    }

    // The order of synchronization is important.
    // Positions may hold foreign keys to work order and ahus.
    // Therefore, loops and await are used to ensure that
    // the foreign keys are available when the positions are
    // synchronized.
    try {
      setSynced([t("Synchronisiere RLT-Anlagen ...")]);
      await setOfflineState(OfflineState.SYNCING);
      // Air handling units
      const idbAhus = await artefactDbGetAll<AirHandlingUnit>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "airHandlingUnit",
      );
      setSynced([t(`Synchronisiere RLT-Anlagen (0/${idbAhus.length}) ...`)]);
      let i = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const ahu of idbAhus) {
        // eslint-disable-next-line no-await-in-loop
        await putAirHandlingUnit(ahu).unwrap();
        i += 1;
        setSynced([
          t(`Synchronisiere RLT-Anlagen (${i}/${idbAhus.length}) ...`),
        ]);
      }
      setSynced([
        t(`RLT-Anlagen (${i}/${idbAhus.length})`),
        t("Synchronisiere Arbeitsschein ..."),
      ]);

      // Work orders
      const idbWorkOrder = await artefactDbGet<WorkOrder>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "workOrder",
        workOrderId,
      );
      await putWorkOrder(idbWorkOrder!).unwrap();
      setSynced([
        t(`RLT-Anlagen (${i}/${idbAhus.length})`),
        t("Arbeitsschein"),
        t("Synchronisiere Leistungen ..."),
      ]);

      // Positions
      const idbPositions = await artefactDbGetAll<Position>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "position",
      );
      let p = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const position of idbPositions) {
        // eslint-disable-next-line no-await-in-loop
        await putPosition(position).unwrap();
        p += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Synchronisiere Leistungen (${p}/${idbPositions.length}) ...`),
        ]);
      }
      setSynced([
        t(`RLT-Anlagen (${i}/${idbAhus.length})`),
        t("Arbeitsschein"),
        t(`Leistungen (${p}/${idbPositions.length})`),
        t("Lösche Leistungen ..."),
      ]);

      // Deleted positions
      const deletedIdbPositions = await artefactDbGetAllDeleted<Position>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "position",
      );
      let d = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const position of deletedIdbPositions) {
        // eslint-disable-next-line no-await-in-loop
        await deletePosition({
          id: position.id,
          workOrderId: position.workOrderId,
        }).unwrap();
        d += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Lösche Leistungen (${d}/${deletedIdbPositions.length}) ...`),
        ]);
      }
      setSynced([
        t(`RLT-Anlagen (${i}/${idbAhus.length})`),
        t("Arbeitsschein"),
        t(`Leistungen (${p}/${idbPositions.length})`),
        t(`Gelöschte Leistungen (${d}/${deletedIdbPositions.length})`),
        t("Synchronisiere Proben ..."),
      ]);

      // Samples
      const idbAirMicrobialSampleSets =
        await artefactDbGetAll<AirMicrobialSampleSet>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "airMicrobialSampleSet",
        );
      const idbDustDensitySamples = await artefactDbGetAll<DustDensitySample>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "dustDensitySample",
      );
      const idbSurfaceMicrobialSampleSets =
        await artefactDbGetAll<SurfaceMicrobialSampleSet>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "surfaceMicrobialSampleSet",
        );
      const idbWaterSamples = await artefactDbGetAll<WaterSample>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "waterSample",
      );
      let sampleSynced = 0;
      const sampleCount =
        idbAirMicrobialSampleSets.length +
        idbDustDensitySamples.length +
        idbSurfaceMicrobialSampleSets.length +
        idbWaterSamples.length;
      // eslint-disable-next-line no-restricted-syntax
      for (const airMicrobialSampleSet of idbAirMicrobialSampleSets) {
        // eslint-disable-next-line no-await-in-loop
        await putAirMicrobialSampleSet(airMicrobialSampleSet).unwrap();
        sampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount}) ...`),
        ]);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const dustDensitySample of idbDustDensitySamples) {
        // eslint-disable-next-line no-await-in-loop
        await putDustDensitySample(dustDensitySample).unwrap();
        sampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount}) ...`),
        ]);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const surfaceMicrobialSampleSet of idbSurfaceMicrobialSampleSets) {
        // eslint-disable-next-line no-await-in-loop
        await putSurfaceMicrobialSampleSet(surfaceMicrobialSampleSet).unwrap();
        sampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount}) ...`),
        ]);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const waterSample of idbWaterSamples) {
        // eslint-disable-next-line no-await-in-loop
        await putWaterSample(waterSample).unwrap();
        sampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount}) ...`),
        ]);
      }
      setSynced([
        t(`RLT-Anlagen (${i}/${idbAhus.length})`),
        t("Arbeitsschein"),
        t(`Leistungen (${p}/${idbPositions.length})`),
        t(`Gelöschte Leistungen (${d}/${deletedIdbPositions.length})`),
        t(`Proben (${sampleSynced}/${sampleCount})`),
        t("Lösche Proben ..."),
      ]);

      // Deleted samples
      const deletedIdbAirMicrobialSampleSets =
        await artefactDbGetAllDeleted<AirMicrobialSampleSet>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "airMicrobialSampleSet",
        );
      const deletedIdbDustDensitySamples =
        await artefactDbGetAllDeleted<DustDensitySample>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "dustDensitySample",
        );
      const deletedIdbSurfaceMicrobialSampleSets =
        await artefactDbGetAllDeleted<SurfaceMicrobialSampleSet>(
          ArtefactKind.WorkOrder,
          workOrderId,
          "surfaceMicrobialSampleSet",
        );
      const deletedIdbWaterSamples = await artefactDbGetAllDeleted<WaterSample>(
        ArtefactKind.WorkOrder,
        workOrderId,
        "waterSample",
      );
      let deletedSampleSynced = 0;
      const deletedSampleCount =
        deletedIdbAirMicrobialSampleSets.length +
        deletedIdbDustDensitySamples.length +
        deletedIdbSurfaceMicrobialSampleSets.length +
        deletedIdbWaterSamples.length;
      // eslint-disable-next-line no-restricted-syntax
      for (const airMicrobialSampleSet of deletedIdbAirMicrobialSampleSets) {
        // eslint-disable-next-line no-await-in-loop
        await deleteAirMicrobialSampleSet(airMicrobialSampleSet).unwrap();
        deletedSampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount})`),
          t(
            `Gelöschte Proben (${deletedSampleSynced}/${deletedSampleCount}) ...`,
          ),
        ]);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const dustDensitySample of deletedIdbDustDensitySamples) {
        // eslint-disable-next-line no-await-in-loop
        await deleteDustDensitySample(dustDensitySample).unwrap();
        deletedSampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount})`),
          t(
            `Gelöschte Proben (${deletedSampleSynced}/${deletedSampleCount}) ...`,
          ),
        ]);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const surfaceMicrobialSampleSet of deletedIdbSurfaceMicrobialSampleSets) {
        // eslint-disable-next-line no-await-in-loop
        await deleteSurfaceMicrobialSampleSet(
          surfaceMicrobialSampleSet,
        ).unwrap();
        deletedSampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount})`),
          t(
            `Gelöschte Proben (${deletedSampleSynced}/${deletedSampleCount}) ...`,
          ),
        ]);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const waterSample of deletedIdbWaterSamples) {
        // eslint-disable-next-line no-await-in-loop
        await deleteWaterSample(waterSample).unwrap();
        deletedSampleSynced += 1;
        setSynced([
          t(`RLT-Anlagen (${i}/${idbAhus.length})`),
          t("Arbeitsschein"),
          t(`Leistungen (${p}/${idbPositions.length})`),
          t(`Proben (${sampleSynced}/${sampleCount})`),
          t(
            `Gelöschte Proben (${deletedSampleSynced}/${deletedSampleCount}) ...`,
          ),
        ]);
      }
      setSynced([
        t(`RLT-Anlagen (${i}/${idbAhus.length})`),
        t("Arbeitsschein"),
        t(`Leistungen (${p}/${idbPositions.length})`),
        t(`Gelöschte Leistungen (${d}/${deletedIdbPositions.length})`),
        t(`Proben (${sampleSynced}/${sampleCount})`),
        t(`Gelöschte Proben (${deletedSampleSynced}/${deletedSampleCount})`),
        t("Offline Datenbank löschen ..."),
      ]);

      // cleanup
      await deleteArtefactDB(ArtefactKind.WorkOrder, workOrderId);
      await setOfflineState(OfflineState.ONLINE);
    } catch (err) {
      toast({
        title: t("Fehler"),
        description:
          err !== null && typeof err === "object" && "message" in err
            ? (err.message as string)
            : t("Fehler beim Synchronisieren"),
        variant: "destructive",
      });
      await setOfflineState(OfflineState.OFFLINE);
    } finally {
      setSynced([]);
    }
  };

  switch (offlineState) {
    case OfflineState.ONLINE:
      return (
        <Button variant="secondary" onClick={goOfflineHandler}>
          {t("Offline aktivieren")}
        </Button>
      );
    case OfflineState.PREPARING_OFFLINE:
      return (
        <Button variant="secondary" disabled>
          <RefreshCw className="mr-2 h-4 w-4" />
          <span>{t("Offline Vorbereiten ...")}</span>
        </Button>
      );
    case OfflineState.OFFLINE:
      return (
        <Button
          variant="secondary"
          onClick={synchronizeHandler}
          disabled={syncDisabled}
        >
          <span>{t("Synchronisieren")}</span>
        </Button>
      );
    case OfflineState.SYNCING:
      return (
        <Button variant="secondary" disabled>
          <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
          <span>{t("Synchronisiere ...")}</span>
        </Button>
      );
    default:
      return null;
  }
}
