import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { ServiceAddressCard } from "@/shared/components/domain/addresses/service-address-card";
import {
  fieldErrorsForPrefix,
  hasFieldError,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { Label } from "@/shared/components/ui/label";
import { DatePicker } from "@/shared/components/ui/date-picker";
import {
  updateAddress,
  updateBeginDate,
  updateComment,
  updateContact,
  updateEndDate,
  updateRepresentative,
  updateTeamLeader,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Textarea } from "@/shared/components/ui/textarea";
import { Address } from "@/services/backend/addresses/address/address";
import { ContactCard } from "@/shared/components/domain/contacts/contact-card";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { RepresentativeCard } from "@/shared/components/domain/representatives/representative-card";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { usePutWorkOrder } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/work-order-hooks";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function WorkOrderData({ workOrder }: { workOrder: WorkOrder }) {
  return (
    <div className="space-y-2">
      <BaseDataCard workOrder={workOrder} />
      <AddressCard workOrder={workOrder} />
      <div className="grid gap-2 md:grid-cols-2">
        <ServiceContactCard workOrder={workOrder} />
        <ServiceRepresentativeCard workOrder={workOrder} />
      </div>
    </div>
  );
}

function BaseDataCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Arbeitsschein")}</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-2 gap-4">
          <BeginDateInput
            workOrder={request}
            onWorkOrderChange={(wo) => {
              resetNotReadyErrorField("beginDate");
              setRequest(wo);
            }}
            validationError={notReadyError}
          />
          <EndDateInput
            workOrder={request}
            onWorkOrderChange={(wo) => {
              resetNotReadyErrorField("endDate");
              setRequest(wo);
            }}
            validationError={notReadyError}
          />
        </div>
        <TeamLeaderInput
          workOrder={request}
          onWorkOrderChange={(wo) => {
            resetNotReadyErrorField("teamLeader");
            setRequest(wo);
          }}
          validationError={notReadyError}
        />
        <CommentInput
          workOrder={request}
          onWorkOrderChange={(wo) => {
            resetNotReadyErrorField("comment");
            setRequest(wo);
          }}
          validationError={notReadyError}
        />
      </CardContent>
    </Card>
  );
}

function AddressCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const onServiceAddressChange = (serviceAddress: Address) => {
    setRequest(updateAddress(workOrder, serviceAddress));
  };

  return (
    <ServiceAddressCard
      serviceAddress={request.address}
      onServiceAddressChange={onServiceAddressChange}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "address.")}
      resetFieldError={(field) => resetNotReadyErrorField(`address.${field}`)}
      disabled={workOrder.immutable}
    />
  );
}

interface DateInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  validationError: ValidationError | null;
}

function BeginDateInput({
  workOrder,
  onWorkOrderChange,
  validationError,
}: DateInputProps) {
  const onDateChange = (date: string | null) => {
    onWorkOrderChange(updateBeginDate(workOrder, date));
  };

  const formattedDate = workOrder.beginDate
    ? new Date(workOrder.beginDate)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
  };

  const markError = hasFieldError(validationError, "beginDate");

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Arbeitsbeginn")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={workOrder.immutable}
      />
    </div>
  );
}

function EndDateInput({
  workOrder,
  onWorkOrderChange,
  validationError,
}: DateInputProps) {
  const onDateChange = (date: string | null) => {
    onWorkOrderChange(updateEndDate(workOrder, date));
  };

  const formattedDate = workOrder.endDate
    ? new Date(workOrder.endDate)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
  };

  const markError = hasFieldError(validationError, "endDate");

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Arbeitsende")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={workOrder.immutable}
      />
    </div>
  );
}

interface TeamLeaderInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  validationError: ValidationError | null;
}

function TeamLeaderInput({
  workOrder,
  onWorkOrderChange,
  validationError,
}: TeamLeaderInputProps) {
  const onTeamLeaderChange = (teamLeader: string) => {
    onWorkOrderChange(updateTeamLeader(workOrder, teamLeader));
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Teamleiter")}</Label>
      <Input
        type="text"
        value={workOrder.teamLeader}
        onChange={(e) => onTeamLeaderChange(e.target.value)}
        disabled={workOrder.immutable}
        className={
          hasFieldError(validationError, "teamLeader")
            ? "border-red-500 shadow-md"
            : ""
        }
      />
      <InputValidationErrors error={validationError} field="teamLeader" />
    </div>
  );
}

interface CommentInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  validationError: ValidationError | null;
}

function CommentInput({
  workOrder,
  onWorkOrderChange,
  validationError,
}: CommentInputProps) {
  const onCommentChange = (comment: string) => {
    onWorkOrderChange(updateComment(workOrder, comment));
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Kommentar (intern)")}</Label>
      <Textarea
        value={workOrder.comment}
        onChange={(e) => onCommentChange(e.target.value)}
        disabled={workOrder.immutable}
      />
      <InputValidationErrors error={validationError} field="comment" />
    </div>
  );
}

function ServiceContactCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const onContactChange = (contact: Contact) => {
    setRequest(updateContact(workOrder, contact));
  };

  return (
    <ContactCard
      contact={request.contact}
      onContactChange={onContactChange}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "contact.")}
      resetFieldError={(field) => resetNotReadyErrorField(`contact.${field}`)}
      disabled={workOrder.immutable}
    />
  );
}

function ServiceRepresentativeCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const onRepresentativeChange = (rep: Representative) => {
    setRequest(updateRepresentative(workOrder, rep));
  };

  return (
    <RepresentativeCard
      representative={request.representative}
      onRepresentativeChange={onRepresentativeChange}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
      resetFieldError={(field) =>
        resetNotReadyErrorField(`representative.${field}`)
      }
      disabled={workOrder.immutable}
    />
  );
}
