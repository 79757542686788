import { useState } from "react";
import {
  Area,
  CreateAreaRequest,
  DeleteAreaRequest,
  Level,
} from "@/services/backend/qpm/buildings/types";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import {
  ChevronDown,
  ChevronRight,
  Loader2,
  Pencil,
  Save,
  Trash2,
  X,
} from "lucide-react";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import { Label } from "@/shared/components/ui/label";
import {
  useQpmAreaAddMutation,
  useQpmAreaDeleteMutation,
} from "@/services/backend/qpm/buildings/service";
import t from "@/lang/lang";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/shared/components/ui/alert-dialog";

type AreaSegmentCollapsibleProps = {
  id: string;
  level: Level;
  area: Area;
  isOpen?: boolean;
};

export function AreaSegmentCollapsible({
  id,
  level,
  area,
  isOpen = true,
}: AreaSegmentCollapsibleProps) {
  const [open, setOpen] = useState(isOpen === undefined ? true : isOpen);
  const [editModeActive, setEditMode] = useState(!area.base.name);
  const [areaName, setAreaName] = useState<string>(area.base.name || "");
  const [createRequest, setCreateRequest] = useState<CreateAreaRequest>({
    id,
    name: "",
    levelId: level.base.id,
  });
  const [deleteRequest, setDeleteRequest] = useState<DeleteAreaRequest>({
    id: "",
  });
  const [
    createNewArea,
    {
      error: errorCreateArea,
      reset: resetCreateArea,
      isSuccess: isSuccessCreateArea,
      isLoading: isLoadingCreateArea,
    },
  ] = useQpmAreaAddMutation();

  const [
    deleteArea,
    {
      error: errorDeleteArea,
      reset: resetDeleteArea,
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDeleteArea,
    },
  ] = useQpmAreaDeleteMutation();

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleAreaNameChange = (event: { target: { value: string } }) => {
    setAreaName(event.target.value);
  };

  const handleSave = () => {
    if (!areaName) {
      return;
    }
    const createAreaRequest: CreateAreaRequest = {
      ...createRequest,
      name: areaName.trim(),
    };
    setCreateRequest(createAreaRequest);
  };

  const handleDelete = () => {
    setDeleteRequest({ id: area.base.id });
  };

  const reset = () => {
    setEditMode(false);
    resetCreateArea();
  };

  useDebouncedMutationWithPersistenceStateContextUpdate(
    createRequest,
    createNewArea,
    isLoadingCreateArea,
    errorCreateArea,
    isSuccessCreateArea,
    reset,
    250,
    { toastError: true },
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    deleteRequest,
    deleteArea,
    isLoadingDelete,
    errorDeleteArea,
    isSuccessDeleteArea,
    resetDeleteArea,
    250,
    { toastError: true },
  );

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="overflow-auto-x">
        <div className="flex bg-muted/25">
          <div className="flex w-full">
            <CollapsibleTrigger>
              <div className="flex items-center text-sm font-bold uppercase">
                <div className="py-3 pl-6">
                  {open ? (
                    <ChevronDown className="h-5 w-5" />
                  ) : (
                    <ChevronRight className="h-5 w-5" />
                  )}
                </div>
                {!editModeActive && (
                  <div className="text-nowrap py-3 pl-3 pr-6">
                    <span>{area?.base.name}</span>
                  </div>
                )}
              </div>
            </CollapsibleTrigger>
            <div className="flex w-full items-center justify-between">
              <div className="py-3 pl-3 pr-6">
                {editModeActive && (
                  <Input
                    placeholder={t("Bereichsname")}
                    defaultValue={area?.base.name}
                    readOnly={!editModeActive}
                    onChange={handleAreaNameChange}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        areaName &&
                        !isLoadingCreateArea
                      ) {
                        handleSave();
                      }
                    }}
                  />
                )}
              </div>
              <div className="flex items-center space-x-2 p-2">
                {editModeActive && (
                  <>
                    {isLoadingCreateArea && (
                      <Loader2 className="animate-spin" />
                    )}
                    {!isLoadingCreateArea && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              size="icon"
                              onClick={handleSave}
                              disabled={!areaName || isLoadingCreateArea}
                            >
                              <Save className="h-5 w-5" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{t("Speichern")}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    {isLoadingDelete ||
                      (isLoadingCreateArea && (
                        <Loader2 className="animate-spin" />
                      ))}
                    {!isLoadingDelete && !isLoadingCreateArea && (
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button size="icon" variant="destructive">
                            <Trash2 className="h-5 w-5" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              {t("Bereich löschen?")}
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              {t(
                                `Möchten Sie den Bereich (${area.base.name}) der Ebene ${level.base.name} unwiderruflich löschen?`,
                              )}
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>
                              {t("Abbruch")}
                            </AlertDialogCancel>
                            <AlertDialogAction
                              onClick={handleDelete}
                              className="bg-destructive hover:bg-destructive/90"
                            >
                              {t("Bestätigen")}
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    )}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => setEditMode(false)}
                          >
                            <X className="h-5 w-5" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t("Abbrechen")}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                )}
                {!editModeActive && (
                  <Button size="icon" variant="outline" onClick={handleEdit}>
                    <Pencil className="h-5 w-5" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <CollapsibleContent>
          <div className="my-2 flex flex-col justify-between gap-4 px-2 md:flex-row md:items-center">
            <div>{t("Es ist noch kein Bereichsplan vorhanden.")}</div>
            <div className="flex flex-col gap-2 md:flex-row md:items-center">
              <InputFile />
              <Button variant="secondary">{t("Hochladen")}</Button>
            </div>
          </div>
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}

function InputFile() {
  const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onChange(file: File | null) {}

  return (
    <div className="flex w-full flex-col gap-1.5 md:flex-row md:items-center">
      <Label htmlFor="picture" className="font-bold">
        Bereichsplan
      </Label>
      <Input
        id="picture"
        type="file"
        name="AreaUpload"
        accept={ACCEPTED_IMAGE_TYPES.toString()}
        onChange={(event) => {
          const { files } = event.target;
          const file = files?.length ? files[0] : null;
          onChange(file);
        }}
      />
    </div>
  );
}
