import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import {
  useHtzStructuralAssessmentCatalogShowActiveCatalogQuery,
  useHtzStructuralAssessmentCatalogShowQuery,
} from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog-service";
import { Query, useArtefactDBGetAll } from "@/shared/indexdb/artefact-db-hooks";
import { StructuralAssessmentCatalog } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { ArtefactKind } from "@/services/backend/artefacts/kind";

export function useGetStructuralAssessmentCatalog(
  workOrderId: string,
): Query<StructuralAssessmentCatalog> {
  const { offlineState } = useOfflineContext();

  const {
    data: activeSACatalog,
    isLoading: activeSACatalogIsLoading,
    error: activeSACatalogError,
  } = useHtzStructuralAssessmentCatalogShowActiveCatalogQuery({});

  const {
    data: structuralAssessmentCatalog,
    isLoading: structuralAssessmentCatalogIsLoading,
    error: structuralAssessmentCatalogError,
  } = useHtzStructuralAssessmentCatalogShowQuery(
    {
      id: activeSACatalog?.catalogId ?? "",
    },
    {
      skip:
        activeSACatalog === undefined || activeSACatalog?.catalogId === null,
    },
  );

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGetAll<StructuralAssessmentCatalog>(
    ArtefactKind.WorkOrder,
    workOrderId,
    "structuralAssessmentCatalog",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: structuralAssessmentCatalog,
        isLoading:
          activeSACatalogIsLoading || structuralAssessmentCatalogIsLoading,
        error: activeSACatalogError || structuralAssessmentCatalogError,
      };
    case OfflineState.OFFLINE:
      return {
        data: indexedDBData ? indexedDBData[0] : undefined,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: { status: 500 },
      };
  }
}
