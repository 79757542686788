import { H4 } from "@/shared/components/ui/typography";
import { RequirementAdditionalInformation } from "@/services/backend/qpm/requirements/types";
import t from "@/lang/lang";
import { IntNumberInput } from "@/routes/gemex/processes/_components/ui/number-input";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { ServicePropertyUtil } from "@/services/backend/qpm/shared/servicePropertyUtil";
import { PropertyUnit } from "@/services/backend/qpm/shared/enums";
import { TimeNumberInput } from "@/routes/gemex/processes/_components/ui/time-number-input";

export function ComplementaryServiceComponent({
  additionalInformation,
  onUpdate,
}: {
  additionalInformation: RequirementAdditionalInformation;
  onUpdate: (updatedInfo: Partial<RequirementAdditionalInformation>) => void;
}) {
  return (
    <div className="flex flex-col border-b py-2">
      <H4>Leistungen und Zeit</H4>
      <div className="flex flex-col gap-2 xl:grid xl:grid-cols-2 xl:gap-2">
        <div className="grid grid-cols-2">
          <div className="flex w-full items-center gap-2">
            <Checkbox
              checked={additionalInformation.basicRefurbishmentMonthlyHours > 0}
              onClick={() =>
                additionalInformation.basicRefurbishmentMonthlyHours > 0
                  ? onUpdate({ basicRefurbishmentMonthlyHours: 0 })
                  : onUpdate({ basicRefurbishmentMonthlyHours: 1 })
              }
            />
            <span className="font-bold">{t("Grundsicherung (GS)")}</span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <IntNumberInput
              value={additionalInformation.basicRefurbishmentMonthlyHours}
              onChange={(value) =>
                onUpdate({ basicRefurbishmentMonthlyHours: value })
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-3">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={additionalInformation.redemptionControlMonthlyHours > 0}
              onClick={() =>
                additionalInformation.redemptionControlMonthlyHours > 0
                  ? onUpdate({
                      redemptionControlTimeAfterWeeks: 0,
                      redemptionControlMonthlyHours: 0,
                    })
                  : onUpdate({
                      redemptionControlTimeAfterWeeks: 4,
                      redemptionControlMonthlyHours: 1,
                    })
              }
            />
            <span className="font-bold">{t("Tilgungskontrolle (TK)")}</span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <span>{t("nach")}</span>
            <IntNumberInput
              value={additionalInformation.redemptionControlTimeAfterWeeks}
              onChange={(value) =>
                onUpdate({
                  redemptionControlTimeAfterWeeks: value,
                })
              }
            />
            <span>{t(ServicePropertyUtil.toString(PropertyUnit.Weeks))}</span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <IntNumberInput
              value={additionalInformation.redemptionControlMonthlyHours}
              onChange={(value) =>
                onUpdate({
                  redemptionControlMonthlyHours: value,
                })
              }
            />
            <span>
              {t(ServicePropertyUtil.toString(PropertyUnit.MonthlyHours))}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex w-full items-center gap-2">
            <Checkbox
              disabled
              checked={additionalInformation.installationTime > 0}
              onClick={() =>
                additionalInformation.installationTime > 0
                  ? onUpdate({
                      installationTime: 0,
                    })
                  : onUpdate({
                      installationTime: 60 * 1_000_000_000,
                    })
              }
            />
            <span className="font-bold">{t("Installation QPM")}</span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <TimeNumberInput
              disabled
              value={additionalInformation.installationTime}
              onChange={(value) =>
                onUpdate({
                  installationTime: value,
                })
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex w-full items-center gap-2">
            <Checkbox
              disabled
              checked={additionalInformation.maintenanceTime > 0}
              onClick={() =>
                additionalInformation.maintenanceTime > 0
                  ? onUpdate({
                      maintenanceTime: 0,
                    })
                  : onUpdate({
                      maintenanceTime: 60 * 1_000_000_000,
                    })
              }
            />
            <span className="font-bold">{t("Pflege QPM")}</span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <TimeNumberInput
              disabled
              value={additionalInformation.maintenanceTime}
              onChange={(value) =>
                onUpdate({
                  maintenanceTime: value,
                })
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex w-full items-center gap-2">
            <Checkbox
              checked={additionalInformation.rfidServiceTime > 0}
              onClick={() =>
                additionalInformation.rfidServiceTime > 0
                  ? onUpdate({
                      rfidServiceTime: 0,
                    })
                  : onUpdate({
                      rfidServiceTime: 60 * 1_000_000_000,
                    })
              }
            />
            <span className="font-bold">{t("Pflege RFID")}</span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <TimeNumberInput
              value={additionalInformation.rfidServiceTime}
              onChange={(value) =>
                onUpdate({
                  rfidServiceTime: value,
                })
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-3">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={additionalInformation.hygieneAuditTimesPerYear > 0}
              onClick={() =>
                additionalInformation.hygieneAuditTimesPerYear > 0
                  ? onUpdate({
                      hygieneAuditTimesPerYear: 0,
                      hygieneAuditMonthlyHours: 0,
                    })
                  : onUpdate({
                      hygieneAuditTimesPerYear: 1,
                      hygieneAuditMonthlyHours: 1,
                    })
              }
            />
            <span className="font-bold">{t("Tilgungskontrolle (TK)")}</span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <IntNumberInput
              value={additionalInformation.hygieneAuditTimesPerYear}
              onChange={(value) =>
                onUpdate({
                  hygieneAuditTimesPerYear: value,
                })
              }
            />
            <span>
              {t(ServicePropertyUtil.toString(PropertyUnit.TimesPerYear))}
            </span>
          </div>
          <div className="flex w-full items-center justify-end gap-2">
            <IntNumberInput
              value={additionalInformation.hygieneAuditMonthlyHours}
              onChange={(value) =>
                onUpdate({
                  hygieneAuditMonthlyHours: value,
                })
              }
            />
            <span>
              {t(ServicePropertyUtil.toString(PropertyUnit.MonthlyHours))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
