import { Query, useArtefactDBGetAll } from "@/shared/indexdb/artefact-db-hooks";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { useParams } from "react-router-dom";
import { useSamplesDeviceListQuery } from "@/services/backend/samples/device/service";
import { Device } from "@/services/backend/samples/device/device";

export function useGetDevices(): Query<Device[]> {
  const { offlineState } = useOfflineContext();
  const { workOrderId } = useParams();

  const {
    data: deviceList,
    isLoading: deviceIsLoading,
    error: deviceError,
  } = useSamplesDeviceListQuery({});
  const devices = deviceList?.devices ?? [];

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGetAll<Device>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "devices",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: devices,
        isLoading: deviceIsLoading,
        error: deviceError,
      };
    case OfflineState.OFFLINE:
      return {
        data: indexedDBData,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}
