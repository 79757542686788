import React, { useState } from "react";
import { H4 } from "@/shared/components/ui/typography";
import { Input } from "@/shared/components/ui/input";
import { TimeNumberInput } from "@/routes/gemex/processes/_components/ui/time-number-input";
import { RequirementAdditionalInformation } from "@/services/backend/qpm/requirements/types";

interface TravelInfoComponentProps {
  additionalInformation: RequirementAdditionalInformation;
  onUpdate: (updatedInfo: Partial<RequirementAdditionalInformation>) => void;
}

export function TravelInfoComponent({
  additionalInformation,
  onUpdate,
}: TravelInfoComponentProps) {
  const [inConjunctionText, setInConjunctionText] = useState<string>(
    additionalInformation.travelCompany,
  );

  const onChangeInConjunctionText = (value: string) => {
    setInConjunctionText(value);
    onUpdate({ travelCompany: value });
  };

  return (
    <div className="flex flex-col gap-2 border-b py-2">
      <H4>Fahrtzeit einfach</H4>
      <div className="gap flex flex-col gap-2 md:flex-row md:items-center">
        <TimeNumberInput
          value={additionalInformation.travelTime}
          onChange={(val) => {
            onUpdate({ travelTime: val });
          }}
        />
        <div className="flex items-center gap-1">
          <span className="w-full">in Verbindung mit</span>
          <Input
            type="text"
            className="rounded-sm border p-1"
            placeholder="Verbindung..."
            value={inConjunctionText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeInConjunctionText(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
