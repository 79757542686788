import { WorkOrder } from "@/services/backend/htz/work-order/work-order";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  useHtzWorkOrderPutMutation,
  useHtzWorkOrderShowQuery,
} from "@/services/backend/htz/work-order/service";
import {
  Mutation,
  Query,
  useArtefactDBGet,
  useArtefactDBPut,
} from "@/shared/indexdb/artefact-db-hooks";
import { useParams } from "react-router-dom";

export function usePutWorkOrder(): Mutation<WorkOrder, WorkOrder> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [putWorkOrderMutation, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderPutMutation();

  const [
    putToIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBPut<WorkOrder, WorkOrder>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "workOrder",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [putWorkOrderMutation, { isLoading, error, isSuccess, reset }];
    case OfflineState.OFFLINE:
      return [
        putToIndexedDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Put of work order not possible is state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}

export function useGetWorkOrder(workOrderId: string): Query<WorkOrder> {
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading: serverIsLoading,
    error: serverError,
  } = useHtzWorkOrderShowQuery({ id: workOrderId });

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGet<WorkOrder>(
    ArtefactKind.WorkOrder,
    workOrderId,
    "workOrder",
    workOrderId,
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData,
        isLoading: serverIsLoading,
        error: serverError,
      };
    case OfflineState.OFFLINE:
      return {
        data: indexedDBData,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: { status: 500 },
      };
  }
}
