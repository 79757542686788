import { useCallback, useEffect, useState } from "react";
import { MonitorDetailSummaryComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/monitor-summary-component";
import { useQpmBuildingListQuery } from "@/services/backend/qpm/buildings/service";
import { Building, Level } from "@/services/backend/qpm/buildings/types";
import { BuildingSelector } from "@/routes/gemex/processes/qpm/shared/components/building-selector-component";
import { LevelSelector } from "@/routes/gemex/processes/qpm/shared/components/level-selector-component";
import { Skeleton } from "@/shared/components/ui/skeleton";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Requirement } from "@/services/backend/qpm/requirements/types";
import { RequirementLevelComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/requirement-level-component";
import { AlertWarning } from "@/shared/components/ui/alert-warning";
import { MoreVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { CreateRequirementContext } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";

export type MaterialInventoryProps = {
  requirement: Requirement;
  addressId: string;
  customerId: string;
};

export function MaterialRequirementRoute() {
  const requirementContext = useOutletContext<CreateRequirementContext>();

  return (
    <MaterialRequirement
      requirement={requirementContext.requirement}
      addressId={requirementContext.addressId}
      customerId={requirementContext.customerId}
    />
  );
}

export function MaterialRequirement({
  requirement,
  addressId,
  customerId,
}: MaterialInventoryProps) {
  const [, setSearchParams] = useSearchParams();
  const [selectedBuilding, setSelectedBuilding] = useState<Building>();
  const [selectedLevel, setSelectedLevel] = useState<Level>();
  const [currentBuildingId, setCurrentBuildingId] = useState<string>();
  const [currentLevelId, setCurrentLevelId] = useState<string>();

  const {
    data: buildingList,
    error: errorBuildings,
    isLoading: isLoadingBuildings,
  } = useQpmBuildingListQuery({ customerId });

  useEffect(() => {
    if (
      !selectedBuilding &&
      buildingList &&
      buildingList.buildings.length > 0
    ) {
      const initialBuilding = buildingList.buildings[0];
      setSelectedBuilding(initialBuilding);
      setCurrentBuildingId(initialBuilding.id);
      if (initialBuilding.components.length > 0) {
        setSelectedLevel(initialBuilding.components[0]);
      }
    }
  }, [buildingList, selectedBuilding]);

  useEffect(() => {
    if (buildingList) {
      const building = buildingList.buildings.find(
        (b) => b.id === currentBuildingId,
      );
      if (building) {
        setSelectedBuilding(building);
        setCurrentBuildingId(building.id);
        const currentLevel = building.components.find(
          (level) => level.base.id === currentLevelId,
        );
        if (currentLevel) {
          setSelectedLevel(currentLevel);
        } else if (building.components.length > 0) {
          setSelectedLevel(building.components[0]);
        }
      }
    }
  }, [buildingList, currentBuildingId, currentLevelId]);

  const handleNewBuildingCreateCallback = (building: Building) => {
    setSelectedBuilding(building);
    setCurrentBuildingId(building.id);
    setSelectedLevel(undefined);
    setCurrentLevelId(undefined);
  };

  const handleBuildingSelect = (buildingId: string) => {
    const building = buildingList?.buildings.find((b) => b.id === buildingId);
    if (building && building !== selectedBuilding) {
      setSelectedBuilding(building);
      setCurrentBuildingId(building.id);
      setSelectedLevel(building.components[0]);
      setCurrentLevelId(building.components[0]?.base?.id);
    }
  };

  const handleLevelSelect = (levelId: string) => {
    const level = selectedBuilding?.components.find(
      (l) => l.base.id === levelId,
    );
    if (level && level !== selectedLevel) {
      setSelectedLevel(level);
      setCurrentLevelId(level.base.id);
    }
  };

  const onLevelCreated = (level: Level) => {
    setSelectedLevel(level);
    setCurrentLevelId(level.base.id);
  };

  const setAreaSegmentStateOpen = useCallback(() => {
    setSearchParams({ open: "true" });
  }, [setSearchParams]);

  const setAreaSegmentStateClosed = useCallback(() => {
    setSearchParams({ open: "false" });
  }, [setSearchParams]);

  if (errorBuildings) {
    return <RTKQueryErrorAlert error={errorBuildings} />;
  }

  if (isLoadingBuildings) {
    return (
      <div className="flex items-center justify-between gap-2 p-2">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-3">
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">{t("Bestandsaufnahme")}</CardTitle>
          {!buildingList ||
            (buildingList.buildings.length === 0 && (
              <AlertWarning title={t("Hinweis")}>
                <div className="flex flex-col">
                  <span>
                    {t("Es wurden noch keine Gebäudedaten hinterlegt.")}
                  </span>
                  <span>
                    {t(
                      "Um mit der Bestandsaufnahme beginnen zu können, legen Sie bitte zuerst ein Gebäude, dann eine Ebene und schließlich einen Bereich an.",
                    )}
                  </span>
                </div>
              </AlertWarning>
            ))}
          {selectedBuilding &&
            (!selectedBuilding.components ||
              selectedBuilding.components.length === 0) && (
              <AlertWarning title={t("Hinweis")}>
                <div className="flex flex-col">
                  <span>
                    {t("Für dieses Gebäude wurde noch keine Ebene hinterlegt.")}
                  </span>
                  <span>
                    {t(
                      "Um mit der Bestandsaufnahme beginnen zu können, legen Sie bitte eine Ebene und schließlich einen Bereich an.",
                    )}
                  </span>
                </div>
              </AlertWarning>
            )}
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-between">
            <div className="my-2 flex gap-2">
              <BuildingSelector
                buildingList={buildingList}
                selectedBuilding={selectedBuilding}
                handleBuildingSelect={handleBuildingSelect}
                customerId={customerId}
                addressId={addressId}
                onBuildingCreateCallback={handleNewBuildingCreateCallback}
              />
              <div className="flex items-center">
                <LevelSelector
                  levels={selectedBuilding?.components}
                  selectedLevel={selectedLevel}
                  handleLevelSelect={handleLevelSelect}
                  buildingId={selectedBuilding?.id}
                  onLevelCreatedCallback={onLevelCreated}
                />
              </div>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="icon" variant="outline" className="h-8 w-8">
                  <MoreVertical className="h-3.5 w-3.5" />
                  <span className="sr-only">More</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={setAreaSegmentStateOpen}>
                  {t("Bereiche aufklappen")}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={setAreaSegmentStateClosed}>
                  {t("Bereiche zuklappen")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          {selectedBuilding && (
            <RequirementLevelComponent
              buildingId={selectedBuilding.id}
              buildingName={selectedBuilding?.name}
              selectedLevel={selectedLevel}
              requirement={requirement}
            />
          )}
        </CardContent>
      </Card>
      {selectedBuilding && selectedLevel && (
        <MonitorDetailSummaryComponent
          title={`${t("Gesamtübersicht")} ${selectedBuilding.name} - ${selectedLevel.base.name}`}
          requirementId={requirement.artefactData.id}
          buildingId={selectedBuilding.id}
          levelId={selectedLevel.base.id}
        />
      )}
    </div>
  );
}
