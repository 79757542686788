import { backendApi } from "@/services/backend/api";
import { LabApiKey } from "@/services/backend/samples/lab/laboratory";

const labApiApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    samplesLabApiStatus: build.query<StatusResponse, StatusRequest>({
      query: (request) => ({
        url: `/samples/lab-api/status`,
        method: "GET",
        params: request,
      }),
    }),
  }),
});

export const { useSamplesLabApiStatusQuery } = labApiApi;

export interface StatusRequest {
  labId: string;
  referenceId: string;
}

export interface StatusResponse {
  labKey: LabApiKey;
  payload: AysidStatus; // currently the only
}

export interface AysidStatus {
  referenceId: string;
  state: State;
  totalSampleSets: number;
  completedSampleSets: number;
  results: Result[] | null;
}

export type State = "Pending" | "Processing" | "Completed";

export interface Result {
  sampleSetId: string;
  value: string;
  unit: string;
  measurements: Measurement[];
  moldDifferentiation: MoldDifferentiation[] | null;
}

export interface Measurement {
  sampleId: string;
  growthMedium: "Caso" | "Malt" | "DG18";
  measurement: string;
  correctedMeasurement: string;
  measurementDate: string; // ISO 8601 date string
  sequenceNumber: number;
}

export interface MoldDifferentiation {
  description: string;
  value: string;
  overgrown: boolean;
}
