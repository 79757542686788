import {
  useHtzPositionCatalogShowActiveCatalogQuery,
  useHtzPositionCatalogShowQuery,
} from "@/services/backend/htz/position-catalog/service";
import { useOfflineContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { Query, useArtefactDBGetAll } from "@/shared/indexdb/artefact-db-hooks";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";
import { ArtefactKind } from "@/services/backend/artefacts/kind";

export function useGetPositionCatalog(
  workOrderId: string,
  companyId: string,
): Query<PositionCatalog> {
  const { offlineState } = useOfflineContext();

  const {
    data: activePositionCatalog,
    isLoading: activePositionCatalogIsLoading,
    error: activePositionCatalogError,
  } = useHtzPositionCatalogShowActiveCatalogQuery({
    companyId,
  });

  const {
    data: positionCatalog,
    isLoading: positionCatalogIsLoading,
    error: positionCatalogError,
  } = useHtzPositionCatalogShowQuery(
    {
      id: activePositionCatalog?.catalogId ?? "",
    },
    {
      skip:
        activePositionCatalog === undefined ||
        activePositionCatalog?.catalogId === null,
    },
  );

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGetAll<PositionCatalog>(
    ArtefactKind.WorkOrder,
    workOrderId,
    "positionCatalog",
  );

  switch (offlineState) {
    case "ONLINE":
      return {
        data: positionCatalog,
        isLoading: activePositionCatalogIsLoading || positionCatalogIsLoading,
        error: activePositionCatalogError || positionCatalogError,
      };
    case "OFFLINE":
      return {
        data: indexedDBData ? indexedDBData[0] : undefined,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: { status: 500 },
      };
  }
}
