import { Button } from "@/shared/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui//dialog";
import { Area } from "@/services/backend/qpm/buildings/types";
import { Textarea } from "@/shared/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/shared/components/ui/radio-group";
import { Label } from "@/shared/components/ui/label";
import {
  useQpmRiskLevelRecordListQuery,
  useQpmRiskTypeRecordListQuery,
} from "@/services/backend/qpm/risks/service";
import { useEffect, useState } from "react";
import {
  RiskLevel,
  RiskType,
  RiskTypeRecord,
} from "@/services/backend/qpm/risks/types";
import { RiskLevelKey, RiskTypeKey } from "@/services/backend/qpm/shared/enums";
import {
  useQpmRequirementAreaRiskAddMutation,
  useQpmRequirementAreaRiskListQuery,
} from "@/services/backend/qpm/requirements/service";
import {
  AddRequirementAreaRiskRequest,
  RequirementAreaRisk,
} from "@/services/backend/qpm/requirements/types";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RiskTypeUtil } from "@/services/backend/qpm/shared/riskTypeUtil";
import { RiskLevelUtil } from "@/services/backend/qpm/shared/riskLevelUtil";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useParams } from "react-router-dom";

export type RiskAnalysisProps = {
  area: Area;
  requirementId: string;
};

export function RiskAnalysisComponent({
  area,
  requirementId,
}: RiskAnalysisProps) {
  const riskTypeOrder = [
    RiskTypeKey.FlyingInsect,
    RiskTypeKey.CrawlingInsect,
    RiskTypeKey.Rodent,
  ];

  const {
    data: dataRiskType,
    error: errorRiskData,
    isLoading: isLoadingRiskData,
  } = useQpmRiskTypeRecordListQuery({ artefactMetaId: requirementId });

  const {
    data: dataReqAreaRisk,
    error: errorReqAreaRisk,
    isLoading: isLoadingReqAreaRisk,
  } = useQpmRequirementAreaRiskListQuery({
    artefactMetaId: {
      active: true,
      values: [requirementId],
    },
    areaId: {
      active: true,
      values: [area.base.id],
    },
  });

  if (errorRiskData) {
    return <RTKQueryErrorAlert error={errorRiskData} />;
  }

  if (errorReqAreaRisk) {
    return <RTKQueryErrorAlert error={errorReqAreaRisk} />;
  }

  if (!area) {
    return <div>Fehler! Kein Bereich!</div>;
  }

  if (!dataRiskType) {
    return <div>Fehler beim Laden der Risikodaten!</div>;
  }

  if (isLoadingRiskData || isLoadingReqAreaRisk) {
    return <RiskAnalysisComponentSkeleton area={area} />;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="h-8" variant="outline">
          Risikobewertung
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-svh max-w-screen-lg overflow-scroll">
        <PersistenceContextProvider>
          <DialogHeader>
            <div className="flex items-end justify-between">
              <div>
                <DialogTitle>Risikobewertung {area.base.name}</DialogTitle>
                <DialogDescription>
                  Bitte bewerten Sie das Risiko für den Bereich <b />
                  {area.base.name}.
                </DialogDescription>
              </div>
              <PersistenceStateUsingContext />
            </div>
          </DialogHeader>
          {riskTypeOrder.map((riskKey) => {
            const riskType = dataRiskType.types.find(
              (type) => type.key === riskKey,
            );

            if (riskType) {
              const reqAreaRiskData = dataReqAreaRisk?.risks.find(
                (risk) => risk.riskTypeId === riskType.id,
              );
              const selectedData: RequirementAreaRisk = reqAreaRiskData || {
                artefactMetaId: requirementId,
                areaId: area.base.id,
                riskTypeId: riskType.id,
                riskLevelId: "",
                detection: "",
                recommendation: "",
                measure: "",
              };
              return (
                <RiskTypeComponent
                  key={riskKey}
                  riskType={riskType}
                  initialRiskData={selectedData}
                />
              );
            }
            return null;
          })}
        </PersistenceContextProvider>
      </DialogContent>
    </Dialog>
  );
}

function RiskAnalysisComponentSkeleton({ area }: { area: Area }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="h-8" variant="outline">
          Risikobewertung
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-svh max-w-screen-lg overflow-scroll">
        <DialogHeader>
          <DialogTitle>Risikobewertung {area.base.name}</DialogTitle>
          <DialogDescription>
            Bitte bewerten Sie das Risiko für den Bereich <b />
            {area.base.name}.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4 p-4">
          <Skeleton className="h-36 w-full" />
          <Skeleton className="h-36 w-full" />
          <Skeleton className="h-36 w-full" />
        </div>
      </DialogContent>
    </Dialog>
  );
}

function RiskTypeComponent({
  riskType,
  initialRiskData,
}: {
  riskType: RiskTypeRecord;
  initialRiskData: RequirementAreaRisk;
}) {
  const { requirementId } = useParams();
  const radioItemsOrder: RiskLevelKey[] = [
    RiskLevelKey.None,
    RiskLevelKey.Low,
    RiskLevelKey.Medium,
    RiskLevelKey.High,
  ];

  const [riskLevel, setRiskLevel] = useState<Map<RiskLevelKey, RiskLevel>>(
    new Map(),
  );

  const [riskData, setRiskData] =
    useState<RequirementAreaRisk>(initialRiskData);
  const [request, setRequest] = useState<AddRequirementAreaRiskRequest>({
    ...initialRiskData,
  });

  const {
    data: dataRiskLevel,
    error: errorRiskLevel,
    isLoading: isLoadingRiskLevel,
  } = useQpmRiskLevelRecordListQuery({ artefactMetaId: requirementId! });

  const [
    setReqAreaRisk,
    {
      error: errorSetReqAreaRisk,
      reset: resetSetReqAreaRisk,
      isLoading: isLoadingSetReqAreaRisk,
      isSuccess: isSuccessSetReqAreaRisk,
    },
  ] = useQpmRequirementAreaRiskAddMutation();

  useEffect(() => {
    if (dataRiskLevel) {
      const lvlMap: Map<RiskLevelKey, RiskLevel> = new Map();
      dataRiskLevel.levels.forEach((level) => lvlMap.set(level.key, level));
      setRiskLevel(lvlMap);
    }
  }, [dataRiskLevel]);

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setReqAreaRisk,
    isLoadingSetReqAreaRisk,
    errorSetReqAreaRisk,
    isSuccessSetReqAreaRisk,
    resetSetReqAreaRisk,
    500,
  );

  const riskLevelColors: Record<RiskLevelKey, string> = {
    [RiskLevelKey.None]: "",
    [RiskLevelKey.Low]: "text-yellow-300",
    [RiskLevelKey.Medium]: "text-orange-400",
    [RiskLevelKey.High]: "text-red-600",
  };

  const handleClick = (riskLevelId: string) => {
    const updatedRequest: AddRequirementAreaRiskRequest = {
      ...initialRiskData,
      riskLevelId,
    };
    setRiskData(updatedRequest);
    setRequest(updatedRequest);
  };

  const updateTextField = (updatedRequest: AddRequirementAreaRiskRequest) => {
    if (request.riskLevelId !== "") {
      setRiskData(updatedRequest);
      setRequest(updatedRequest);
    }
  };

  if (errorRiskLevel) {
    return <RTKQueryErrorAlert error={errorRiskLevel} />;
  }

  if (isLoadingRiskLevel) {
    return <RiskTypeComponentSkeleton riskType={riskType} />;
  }

  return (
    <>
      <span className="text-xl font-bold">
        {RiskTypeUtil.toLongString(riskType.key)}
      </span>
      <div className="flex flex-col gap-2">
        <RadioGroup
          className="mb-2 flex gap-x-4 gap-y-2"
          defaultValue={riskData.riskLevelId}
        >
          {radioItemsOrder.map((riskLevelKey) => {
            if (riskLevel.has(riskLevelKey)) {
              const riskLevelItem = riskLevel.get(riskLevelKey);
              if (riskLevelItem) {
                return (
                  <div
                    key={riskLevelKey}
                    className="flex items-center space-x-2"
                  >
                    <RadioGroupItem
                      className={riskLevelColors[riskLevelKey]}
                      value={riskLevelItem.id}
                      id={`${riskType.key}-${riskLevelItem.id}`}
                      onClick={() => handleClick(riskLevelItem.id)}
                    />
                    <Label htmlFor={`${riskType.key}-${riskLevelItem.id}`}>
                      {RiskLevelUtil.toLongString(riskLevelKey)}
                    </Label>
                  </div>
                );
              }
            }
            return null;
          })}
        </RadioGroup>
        <div className="grid grid-cols-3 flex-col items-start gap-2">
          <div className="flex flex-col gap-1">
            <Label htmlFor="textDetection">Feststellung</Label>
            <Textarea
              id="textDetection"
              placeholder="Feststellung..."
              value={riskData.detection}
              onChange={(e) => {
                updateTextField({
                  ...riskData,
                  detection: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label htmlFor="textRecommendation">Empfehlungen</Label>
            <Textarea
              id="texRecommendation"
              placeholder="Empfehlungen..."
              value={riskData.recommendation}
              onChange={(e) => {
                updateTextField({
                  ...riskData,
                  recommendation: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label htmlFor="textMeasure">Maßnahmen</Label>
            <Textarea
              id="textMeasure"
              placeholder="Maßnahmen..."
              value={riskData.measure}
              onChange={(e) => {
                updateTextField({
                  ...riskData,
                  measure: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default RiskTypeComponent;

function RiskTypeComponentSkeleton({ riskType }: { riskType: RiskType }) {
  return (
    <>
      <span className="text-xl font-bold">
        {RiskTypeUtil.toLongString(riskType.key)}
      </span>
      <div className="flex flex-col gap-4">
        <Skeleton className="h-10 w-full" />
        <div className="grid grid-cols-3 flex-col items-start gap-2">
          <Skeleton className="h-28 w-full" />
          <Skeleton className="h-28 w-full" />
          <Skeleton className="h-28 w-full" />
        </div>
      </div>
    </>
  );
}
