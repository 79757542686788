import {
  RentingCost,
  RentingPosition,
  UpdateRentingCostPositionRequest,
  UpdateServicePositionAmountRequest,
} from "@/services/backend/qpm/calculations/types";
import {
  useQpmCalcRentingCostPositionPricePostMutation,
  useQpmCalcServiceCostPositionAmountPostMutation,
} from "@/services/backend/qpm/calculations/service";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  FloatNumberInput,
  IntNumberInput,
  Value,
} from "@/routes/gemex/processes/_components/ui/number-input";

export type RentingCostComponentProps = {
  rentingCost: RentingCost;
};

export function RentingCostComponent({
  rentingCost,
}: RentingCostComponentProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/5">{t("Monitor")}</TableHead>
          <TableHead className="w-1/5 text-right">{t("Anzahl")}</TableHead>
          <TableHead className="w-1/5 text-right">
            {t("Mietpreis pro Stück")}
          </TableHead>
          <TableHead className="w-1/5 text-right">{t("Monatsraten")}</TableHead>
          <TableHead className="w-1/5 text-right">{t("Gesamtpreis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {rentingCost &&
          rentingCost.rentingPositionList.positions.map((position) => (
            <RentingPositionRow
              key={`${position.rentingCostId}-${position.monitorId}`}
              position={position}
              calculationId={rentingCost.calculationId}
            />
          ))}
      </TableBody>
    </Table>
  );
}

export type RentingPositionRowProps = {
  calculationId: string;
  position: RentingPosition;
};

function RentingPositionRow({
  calculationId,
  position,
}: RentingPositionRowProps) {
  const [amount, setAmount] = useState(position.monitorAmount);
  const [price, setPrice] = useState(position.price);
  const [updatePriceRequest, setUpdatePriceRequest] =
    useState<UpdateRentingCostPositionRequest>({
      calculationId,
      monitorId: position.monitorId,
      price: position.price,
    });
  const [updateAmountRequest, setUpdateAmountRequest] =
    useState<UpdateServicePositionAmountRequest>({
      calculationId,
      monitorId: position.monitorId,
      amount: position.monitorAmount,
    });

  const [setRentingPositionPrice, { isLoading, error, reset, isSuccess }] =
    useQpmCalcRentingCostPositionPricePostMutation();

  const [
    updateAmount,
    {
      isLoading: isLoadingUpdateAmount,
      error: errorUpdateAmount,
      reset: resetUpdateAmount,
      isSuccess: isSuccessUpdateAmount,
    },
  ] = useQpmCalcServiceCostPositionAmountPostMutation();

  useEffect(() => {
    // Update amount if the position prop changes
    setAmount(position.monitorAmount);
  }, [position.monitorAmount]);

  useEffect(() => {
    // Update price if the position prop changes
    setPrice(position.price);
  }, [position.price]);

  useDebouncedMutationWithPersistenceStateContextUpdate(
    updatePriceRequest,
    setRentingPositionPrice,
    isLoading,
    error,
    isSuccess,
    reset,
    750,
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    updateAmountRequest,
    updateAmount,
    isLoadingUpdateAmount,
    errorUpdateAmount,
    isSuccessUpdateAmount,
    resetUpdateAmount,
    500,
  );
  return (
    <TableRow>
      <TableCell className="font-medium">{position.monitorName}</TableCell>
      <TableCell className="text-right">
        <IntNumberInput
          className="justify-self-end"
          value={amount}
          onChange={(val) => {
            const a = val < 0 ? 0 : val;
            setAmount(a);
            setUpdateAmountRequest({
              ...updateAmountRequest,
              amount: a,
            });
          }}
          disabled={isLoadingUpdateAmount}
        />
      </TableCell>
      <TableCell className="text-right">
        <FloatNumberInput
          className="justify-self-end"
          value={price}
          fractions={2}
          onChange={(val) => {
            setUpdatePriceRequest({
              ...updatePriceRequest,
              price: val,
            });
          }}
          disabled={isLoading}
        />
      </TableCell>
      <TableCell className="text-right">
        <Value value={position.rentingDuration} fractions={0} />
      </TableCell>
      <TableCell className="text-right">
        <Value value={position.totalCost} />
      </TableCell>
    </TableRow>
  );
}
