import { UUID } from "@/shared/nidavellir/types/entity-id";
import { ArtefactKind } from "@/services/backend/artefacts/kind";

let db: IDBDatabase;

interface Deletable {
  idbDeleted: boolean;
}

export function initArtefactDB(
  artefactKind: ArtefactKind,
  artefactID: UUID,
  stores: { name: string; keyPath: string }[],
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName(artefactKind, artefactID));

    request.onupgradeneeded = (event) => {
      // @ts-expect-error this is done as specified in the MDN docs. For some reason TS does not like it.
      const idb = event.target.result;

      // create object stores
      stores.forEach((store) => {
        if (!idb.objectStoreNames.contains(store.name)) {
          idb.createObjectStore(store.name, { keyPath: store.keyPath });
        }
      });

      // no need to resolve here
    };

    request.onsuccess = (event) => {
      // @ts-expect-error this is done as specified in the MDN docs. For some reason TS does not like it.
      db = event.target.result;
      resolve(true);
    };

    request.onerror = () => {
      reject(new Error("error creating indexedDB"));
    };
  });
}

function dbName(artefactKind: ArtefactKind, artefactId: string): string {
  return `${artefactKind}_${artefactId}`;
}

export function deleteArtefactDB(
  artefactKind: ArtefactKind,
  artefactID: UUID,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName(artefactKind, artefactID));

    openRequest.onsuccess = () => {
      db = openRequest.result;
      db.close();
      const deleteRequest = indexedDB.deleteDatabase(
        dbName(artefactKind, artefactID),
      );

      deleteRequest.onsuccess = () => {
        resolve(true);
      };

      deleteRequest.onerror = () => {
        reject(deleteRequest.error);
      };
    };
  });
}

export function artefactDbPut<T>(
  artefactKind: ArtefactKind,
  artefactId: UUID,
  storeName: string,
  data: T,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const putRequest = store.put({ ...data, idbDeleted: false });
    putRequest.onsuccess = () => {
      resolve(data);
    };
    putRequest.onerror = () => {
      reject(putRequest.error);
    };
  });
}

export function artefactDbGet<T>(
  artefactKind: ArtefactKind,
  artefactID: UUID,
  storeName: string,
  key: string,
): Promise<T | null> {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName(artefactKind, artefactID));

    openRequest.onsuccess = () => {
      db = openRequest.result;
      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const getRequest = store.get(key);

      getRequest.onsuccess = () => {
        if (getRequest.result) {
          if (getRequest.result.idbDeleted) {
            resolve(null);
          } else {
            resolve(getRequest.result);
          }
        } else {
          resolve(null);
        }
      };

      getRequest.onerror = () => {
        reject(getRequest.error);
      };
    };
  });
}

export function artefactDbGetAll<T>(
  artefactKind: ArtefactKind,
  artefactID: UUID,
  storeName: string,
): Promise<T[]> {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName(artefactKind, artefactID));

    openRequest.onsuccess = () => {
      db = openRequest.result;
      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const result = store.getAll();

      result.onsuccess = () => {
        resolve(result.result.filter((item: Deletable) => !item.idbDeleted));
      };

      result.onerror = () => {
        reject(result.error);
      };
    };
  });
}

export function artefactDbGetAllDeleted<T>(
  artefactKind: ArtefactKind,
  artefactID: UUID,
  storeName: string,
): Promise<T[]> {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName(artefactKind, artefactID));

    openRequest.onsuccess = () => {
      db = openRequest.result;
      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const result = store.getAll();

      result.onsuccess = () => {
        resolve(result.result.filter((item: Deletable) => item.idbDeleted));
      };

      result.onerror = () => {
        reject(result.error);
      };
    };
  });
}

/**
 * Mark an entry as deleted in indexedDB.
 * @param artefactKind
 * @param artefactID
 * @param storeName
 * @param key
 */
export function artefactDbDelete(
  artefactKind: ArtefactKind,
  artefactID: UUID,
  storeName: string,
  key: string,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const getRequest = store.get(key);

    getRequest.onsuccess = () => {
      if (getRequest.result) {
        const deleteRequest = store.put({
          ...getRequest.result,
          idbDeleted: true,
        });

        deleteRequest.onsuccess = () => {
          resolve(true);
        };

        deleteRequest.onerror = () => {
          reject(
            new Error(
              `error deleting data from indexedDB for ${artefactKind} ${artefactID}`,
            ),
          );
        };
      } else {
        resolve(false);
      }
    };

    getRequest.onerror = () => {
      reject(getRequest.error);
    };
  });
}
