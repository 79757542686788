import { createContext, ReactNode, useContext, useMemo, useState } from "react";

// TODO, this may not work as intended
//  -> it should be more a observer pattern.
//  -> instantiated hooks, register themselves to the context
//  -> the context informs them, when they should update

interface StaleContextInterface {
  stale: string[];
  addStale: (...tag: string[]) => void;
  removeStale: (tag: string) => void;
}

const StaleContext = createContext<StaleContextInterface>({
  stale: [],
  addStale: () => {},
  removeStale: () => {},
});

export function useStaleContext() {
  return useContext(StaleContext);
}

export function StaleContextProvider({
  initiallyStale,
  children,
}: {
  initiallyStale?: string[];
  children: ReactNode;
}) {
  const [stale, setStale] = useState<string[]>(initiallyStale ?? []);

  const value = useMemo(
    () => ({
      stale,
      addStale: (...tag: string[]) => setStale([...stale, ...tag]),
      removeStale: (tag: string) => setStale(stale.filter((t) => t !== tag)),
    }),
    [stale],
  );

  return (
    <StaleContext.Provider value={value}>{children}</StaleContext.Provider>
  );
}
