import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/shared/components/ui/sheet";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import {
  Position,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { AhuComponentsSchema } from "@/shared/components/domain/htz/ahu/ahu-components-schema";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { AhuComponentsTable } from "@/shared/components/domain/htz/ahu/ahu-components-table";
import {
  InspectionContextProvider,
  useInspectionContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import {
  AddStructuralAssessmentButton,
  StructuralInspection,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/structural-inspection";
import { useEffect, useState } from "react";
import { AddComponentDropdown } from "@/shared/components/domain/htz/ahu/add-component-dropdown";
import { Switch } from "@/shared/components/ui/switch";
import { Label } from "@/shared/components/ui/label";
import { Camera, CheckCircle, Eye, RefreshCw, Tags } from "lucide-react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/shared/components/ui/resizable";
import {
  AddVisualAssessmentButton,
  VisualInspection,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/visual-inspection";
import {
  AddPhotoButton,
  PhotoDocumentation,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/photo-documentation";
import {
  AddMicrobialAssessmentButton,
  MicrobialInspection,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/microbial-inspection";
import {
  useGetAirHandlingUnit,
  usePutAirHandlingUnit,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/air-handling-unit-hooks";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useGetAhuComponentCatalog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-get-ahu-component-catalog";
import { Mutation } from "@/shared/indexdb/artefact-db-hooks";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { cn } from "@/shared/lib/utils";

export function InspectionSheet({
  workOrder,
  position,
  onPositionChange,
  disabled: propDisabled,
}: {
  workOrder: WorkOrder;
  position: Position;
  onPositionChange: Mutation<Position, Position>;
  disabled?: boolean;
}) {
  const disabled =
    !position.airHandlingUnitId || workOrder.immutable || propDisabled;

  const { notReadyError } = useErrArtefactNotReady();
  const errorPrefix = `position.${position.id}.`;
  const hasError = hasFieldError(
    notReadyError,
    `${errorPrefix}structuralAssessment`,
    `${errorPrefix}visualAssessment`,
    `${errorPrefix}microbialAssessment`,
    `${errorPrefix}photo`,
  );

  return (
    <InspectionContextProvider
      position={position}
      onPositionChange={onPositionChange}
      disabled={disabled}
    >
      <Sheet>
        <SheetTrigger asChild disabled={disabled}>
          <Button
            size="sm"
            variant="secondary"
            className={cn("h-8", hasError && "border border-red-500 shadow-md")}
          >
            {t("Inspektion")}
          </Button>
        </SheetTrigger>
        <SheetContent side="bottom" className="h-screen space-y-4">
          <InspectionSheetHeader />
          <InspectionSheetMain workOrder={workOrder} />
        </SheetContent>
      </Sheet>
    </InspectionContextProvider>
  );
}

function InspectionSheetHeader() {
  const { position } = useInspectionContext();
  const { data: airHandlingUnit } = useGetAirHandlingUnit(
    position.airHandlingUnitId ?? "",
  );

  return (
    <SheetHeader className="max-h-[10vh]">
      <SheetTitle className="flex justify-between pr-12">
        <span>
          {t("Inspektion für Position: ")}
          {position.number}
          {t(" - Anlage: ")}
          {airHandlingUnit?.name}
        </span>
        <PersistenceStateUsingContext />
        <InspectionModeSwitch />
      </SheetTitle>
      <SheetDescription>
        {t(
          "Im Modus Aufnahme, können die Komponenten der Anlage zusammengebaut werden. Im Modus Inspektion können Komponenten ausgewählt werden um für diese Beurteilungen zu erstellen.",
        )}
      </SheetDescription>
    </SheetHeader>
  );
}

function InspectionSheetMain({ workOrder }: { workOrder: WorkOrder }) {
  const { position, active } = useInspectionContext();

  const { data: airHandlingUnit } = useGetAirHandlingUnit(
    position.airHandlingUnitId ?? "",
  );

  if (!airHandlingUnit) {
    return null;
  }

  if (!active) {
    return (
      <AhuCard
        airHandlingUnit={airHandlingUnit}
        workOrderId={position.workOrderId}
      />
    );
  }

  return (
    <ResizablePanelGroup direction="horizontal" className="space-x-4">
      <ResizablePanel minSize={24}>
        <AhuCard
          airHandlingUnit={airHandlingUnit}
          workOrderId={position.workOrderId}
        />
      </ResizablePanel>
      <ResizableHandle withHandle className="max-h-[80vh]" />
      <ResizablePanel minSize={24}>
        <AssessmentCard workOrder={workOrder} />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}

function AhuCard({
  airHandlingUnit,
  workOrderId,
}: {
  airHandlingUnit: AirHandlingUnit;
  workOrderId: string;
}) {
  const [request, setRequest] = useState(airHandlingUnit);
  const [mustSave, setMustSave] = useState(false);
  const [put, { isLoading, error, reset }] = usePutAirHandlingUnit();

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const onChange = (ahu: AirHandlingUnit) => {
    setMustSave(true);
    setRequest(ahu);
  };

  const doSave = () => {
    if (isLoading) {
      return;
    }

    put(request);
    setMustSave(false);
  };

  const { active } = useInspectionContext();
  const [tabValue, setTabValue] = useState<string>("schema");
  const { data: ahuComponentCatalog } = useGetAhuComponentCatalog(workOrderId);

  if (!ahuComponentCatalog) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Anlage")}</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs value={tabValue} onValueChange={setTabValue}>
          <div className="flex items-center justify-between">
            <TabsList>
              <TabsTrigger value="schema">{t("Schema")}</TabsTrigger>
              <TabsTrigger value="list">{t("Liste")}</TabsTrigger>
            </TabsList>
            {mustSave && (
              <div className="inline-flex h-10 items-center justify-center rounded-lg border border-destructive/50 px-4 py-2 font-medium tracking-tight text-destructive">
                {t("Änderungen nicht gespeichert")}
              </div>
            )}
            <div className="space-x-2">
              {tabValue === "list" && !active && (
                <AddComponentDropdown
                  ahu={airHandlingUnit}
                  onAhuChange={onChange}
                  catalog={ahuComponentCatalog}
                />
              )}
              {!active && (
                <Button onClick={doSave} disabled={isLoading}>
                  {isLoading && (
                    <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  {t("Speichern")}
                </Button>
              )}
            </div>
          </div>
          <TabsContent value="schema" className="h-[65vh]">
            <AhuComponentsSchema
              ahu={request}
              onAhuChange={onChange}
              ahuComponentCatalog={ahuComponentCatalog}
            />
          </TabsContent>
          <TabsContent value="list" className="h-[65vh]">
            <AhuComponentsTable ahu={request} onAhuChange={onChange} />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}

function InspectionModeSwitch() {
  const { active, setActive } = useInspectionContext();

  return (
    <div className="flex items-center space-x-4">
      <Label>{t("Aufnahme")}</Label>
      <Switch checked={active} onCheckedChange={setActive} />
      <Label>{t("Inspektion")}</Label>
    </div>
  );
}

function AssessmentCard({ workOrder }: { workOrder: WorkOrder }) {
  const { position, activeComponent } = useInspectionContext();
  const [tabValue, setTabValue] = useState("structural");

  const { notReadyError } = useErrArtefactNotReady();
  const errorPrefix = `position.${position.id}.`;

  const hasStructuralErrs = hasFieldError(
    notReadyError,
    `${errorPrefix}structuralAssessment`,
  );

  const hasVisualErrs = hasFieldError(
    notReadyError,
    `${errorPrefix}visualAssessment`,
  );

  const hasMicrobialErrs = hasFieldError(
    notReadyError,
    `${errorPrefix}microbialAssessment`,
  );

  const hasPhotoErrs = hasFieldError(notReadyError, `${errorPrefix}photo`);

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {activeComponent && activeComponent.name}
          {!activeComponent && (
            <span className="font-normal italic text-muted-foreground">
              {t("- bitte Komponente auswählen -")}
            </span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs value={tabValue} onValueChange={setTabValue}>
          <div className="flex justify-between">
            <TabsList>
              <TabsTrigger
                value="structural"
                className={cn(
                  hasStructuralErrs && "border border-red-500 shadow-md",
                )}
              >
                <CheckCircle className="mr-2 h-4 w-4" />
                <span>{t("Konstruktiv")}</span>
              </TabsTrigger>
              <TabsTrigger
                value="visual"
                className={cn(
                  hasVisualErrs && "border border-red-500 shadow-md",
                )}
              >
                <Eye className="mr-2 h-4 w-4" />
                <span>{t("Visuell")}</span>
              </TabsTrigger>
              <TabsTrigger
                value="microbial"
                className={cn(
                  hasMicrobialErrs && "border border-red-500 shadow-md",
                )}
              >
                <Tags className="mr-2 h-4 w-4" />
                <span>{t("Proben")}</span>
              </TabsTrigger>
              <TabsTrigger
                value="photo"
                className={cn(
                  hasPhotoErrs && "border border-red-500 shadow-md",
                )}
              >
                <Camera className="mr-2 h-4 w-4" />
                <span>{t("Fotos")}</span>
              </TabsTrigger>
            </TabsList>
            {tabValue === "structural" && (
              <AddStructuralAssessmentButton workOrder={workOrder} />
            )}
            {tabValue === "visual" && (
              <AddVisualAssessmentButton workOrder={workOrder} />
            )}
            {tabValue === "microbial" && (
              <AddMicrobialAssessmentButton workOrder={workOrder} />
            )}
            {tabValue === "photo" && <AddPhotoButton workOrder={workOrder} />}
          </div>
          <TabsContent value="structural" className="h-[65vh]">
            <StructuralInspection workOrder={workOrder} />
          </TabsContent>
          <TabsContent value="visual" className="h-[65vh]">
            <VisualInspection workOrder={workOrder} />
          </TabsContent>
          <TabsContent value="microbial" className="h-[65vh]">
            <MicrobialInspection workOrder={workOrder} />
          </TabsContent>
          <TabsContent value="photo" className="h-[65vh]">
            <PhotoDocumentation workOrder={workOrder} />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}
