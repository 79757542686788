import { backendApi } from "@/services/backend/api";
import {
  CreateMonitorRequest,
  Monitor,
  MonitorList,
  MonitorRecordList,
  MonitorRecordListRequest,
} from "@/services/backend/qpm/monitors/types";

const qpmMonitorServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["QpmMonitor", "QpmMonitorRecord"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmMonitorAdd: build.mutation<Monitor, CreateMonitorRequest>({
        query: (request) => ({
          url: `/qpm/monitor`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmMonitor"],
      }),
      qpmMonitorList: build.query<MonitorList, null>({
        query: () => ({
          url: `/qpm/monitor/list`,
          method: "GET",
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmMonitor", id: "LIST" }] : [],
      }),
      qpmMonitorRecordList: build.query<
        MonitorRecordList,
        Partial<MonitorRecordListRequest>
      >({
        query: (request) => ({
          url: `/qpm/monitor-record/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmMonitorRecord", id: "LIST" }] : [],
      }),
    }),
  });

export const {
  useQpmMonitorAddMutation,
  useQpmMonitorListQuery,
  useQpmMonitorRecordListQuery,
} = qpmMonitorServiceApi;
