import { backendApi } from "@/services/backend/api";
import {
  Calculation,
  CalculationDocumentation,
  CalculationDocumentationList,
  CreateFromOldRequest,
  CreateRequest,
  Current,
  DeleteCalcDocRequest,
  DeleteServiceDocRequest,
  GetCalcDocRequest,
  GetMaintenancePriceRequest,
  GetMaterialCostRequest,
  List,
  ListCalcDocRequest,
  ListCalcServiceDocRequest,
  ListRequest,
  ListServiceCostRequest,
  LoadRentingCostRequest,
  LoadRequirementRequest,
  LoadServiceCostRequest,
  MaintenancePriceList,
  MaterialCostMap,
  MaterialPrice,
  PutCalcDocRequest,
  PutMaterialPriceRequest,
  PutRentingCostRequest,
  PutRentingPositionRequest,
  PutServiceDocRequest,
  RentingCost,
  RentingPosition,
  RentingPositionList,
  ServiceCost,
  ServiceCostList,
  ServiceDocumentation,
  ServiceDocumentationList,
  ShowCurrentRequest,
  ShowRequest,
  UpdateParametersRequest,
  UpdateRentingCostDurationRequest,
  UpdateRentingCostPositionRequest,
  UpdateServicePositionAmountRequest,
  UpdateServicePositionAmountResponse,
  UpdateServiceTimePerMonitorRequest,
  UpdateServiceTimePerMonitorResponse,
  UpdateTargetRevenueRequest,
  UpdateTargetRevenueResponse,
  UpdateTravelingDurationRequest,
  UpdateTravelingDurationResponse,
} from "@/services/backend/qpm/calculations/types";

const qpmCalculationApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "QpmCalculation",
      "QpmCalculationCurrent",
      "QpmCalculationMaterialPrices",
      "QpmCalculationMaintenancePrices",
      "QpmCalculationDocumentation",
      "QpmCalculationServiceDocumentation",
      "QpmCalculationServiceCost",
      "QpmCalculationRentingCost",
      "QpmCalculationRentingCostPosition",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmCalculationCreate: build.mutation<Calculation, CreateRequest>({
        query: (request) => ({
          url: `/qpm/calculation`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          "QpmCalculation",
          "QpmCalculationCurrent",
          "QpmCalculationServiceCost",
        ],
      }),
      qpmCalculationCreateFromOld: build.mutation<
        Calculation,
        CreateFromOldRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/create-from-old`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          "QpmCalculation",
          "QpmCalculationCurrent",
          "QpmCalculationServiceCost",
        ],
      }),
      qpmCalculationLoadRequirement: build.mutation<
        Calculation,
        LoadRequirementRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/load-requirement`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          "QpmCalculation",
          "QpmCalculationCurrent",
          "QpmCalculationMaterialPrices",
          "QpmCalculationMaintenancePrices",
          "QpmCalculationServiceCost",
        ],
      }),
      qpmCalculationShow: build.query<Calculation, ShowRequest>({
        query: (request) => ({
          url: `/qpm/calculation`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [{ type: "QpmCalculation", id: result.artefactData.id }]
            : [],
      }),
      qpmCalculationGetLinkedRequirement: build.query<Calculation, ShowRequest>(
        {
          query: (request) => ({
            url: `/qpm/calculation`,
            method: "GET",
            params: request,
          }),
          providesTags: (result) =>
            result
              ? [{ type: "QpmCalculation", id: result.artefactData.id }]
              : [],
        },
      ),
      qpmCalculationList: build.query<List, Partial<ListRequest>>({
        query: (request) => ({
          url: `/qpm/calculation/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculation", id: "LIST" }] : [],
      }),
      qpmCalculationShowCurrent: build.query<Current, ShowCurrentRequest>({
        query: (request) => ({
          url: `/qpm/calculation/current`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [{ type: "QpmCalculationCurrent", id: result.processId }]
            : [],
      }),
      qpmCalculationSetCurrent: build.mutation<Current, Current>({
        query: (request) => ({
          url: `/qpm/calculation/current`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["QpmCalculationCurrent"],
      }),
      qpmCalculationSetMaterialPrice: build.mutation<
        MaterialPrice,
        PutMaterialPriceRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/material-price`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: [
          { type: "QpmCalculationMaterialPrices", id: "LIST" },
          { type: "QpmCalculationMaintenancePrices", id: "LIST" },
          "QpmCalculation",
          "QpmCalculationMaterialPrices",
          "QpmCalculationServiceCost",
          "QpmCalculationMaintenancePrices",
        ],
      }),
      qpmCalculationGetMaterialCost: build.query<
        MaterialCostMap,
        Partial<GetMaterialCostRequest>
      >({
        query: (request) => ({
          url: `/qpm/calculation/material-cost`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculationMaterialPrices", id: "LIST" }] : [],
      }),
      qpmCalculationGetMaintenancePrices: build.query<
        MaintenancePriceList,
        Partial<GetMaintenancePriceRequest>
      >({
        query: (request) => ({
          url: `/qpm/calculation/maintenance-price-list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result
            ? [{ type: "QpmCalculationMaintenancePrices", id: "LIST" }]
            : [],
      }),
      qpmUpdateCalculationParameters: build.mutation<
        Calculation,
        UpdateParametersRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/parameters`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, _error, request) => [
          { type: "QpmCalculation", id: request.id },
        ],
      }),
      qpmCalcDocumentationPut: build.mutation<
        CalculationDocumentation,
        PutCalcDocRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/documentation`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmCalculationDocumentation", "QpmCalculation"],
      }),
      qpmCalcDocumentationGet: build.query<
        CalculationDocumentation,
        GetCalcDocRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/documentation`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculationDocumentation" }] : [],
      }),
      qpmCalcDocumentationList: build.query<
        CalculationDocumentationList,
        ListCalcDocRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/documentation/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculationDocumentation", id: "LIST" }] : [],
      }),
      qpmCalcDocumentationDelete: build.mutation<null, DeleteCalcDocRequest>({
        query: (request) => ({
          url: `/qpm/calculation/documentation`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: () => [
          { type: "QpmCalculationDocumentation", id: "LIST" },
          { type: "QpmCalculationDocumentation" },
          { type: "QpmCalculation" },
        ],
      }),
      qpmCalcServiceDocumentationPut: build.mutation<
        ServiceDocumentation,
        PutServiceDocRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-documentation`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          "QpmCalculationServiceDocumentation",
          "QpmCalculation",
          "QpmCalculationServiceCost",
          "QpmCalculationRentingCost",
        ],
      }),
      qpmCalcServiceDocList: build.query<
        ServiceDocumentationList,
        Partial<ListCalcServiceDocRequest>
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-documentation/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result
            ? [{ type: "QpmCalculationServiceDocumentation", id: "LIST" }]
            : [],
      }),
      qpmCalcServiceDocumentationDelete: build.mutation<
        null,
        DeleteServiceDocRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-documentation`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: () => [
          { type: "QpmCalculationServiceDocumentation", id: "LIST" },
          { type: "QpmCalculationServiceDocumentation" },
          { type: "QpmCalculation" },
          { type: "QpmCalculationServiceCost" },
          { type: "QpmCalculationRentingCost" },
        ],
      }),
      qpmCalcServiceCostGet: build.query<ServiceCost, LoadServiceCostRequest>({
        query: (request) => ({
          url: `/qpm/calculation/service-cost`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculationServiceCost" }] : [],
      }),
      qpmCalcServiceCostList: build.query<
        ServiceCostList,
        Partial<ListServiceCostRequest>
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-cost/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculationServiceCost", id: "LIST" }] : [],
      }),
      qpmCalcRentingCostPut: build.mutation<RentingCost, PutRentingCostRequest>(
        {
          query: (request) => ({
            url: `/qpm/calculation/renting-cost`,
            method: "POST",
            body: request,
          }),
          invalidatesTags: [
            "QpmCalculation",
            "QpmCalculationRentingCost",
            "QpmCalculationRentingCostPosition",
          ],
        },
      ),
      qpmCalcRentingCostGet: build.query<RentingCost, LoadRentingCostRequest>({
        query: (request) => ({
          url: `/qpm/calculation/renting-cost`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculationRentingCost" }] : [],
      }),
      qpmCalcRentingCostPositionPut: build.mutation<
        RentingPosition,
        PutRentingPositionRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/renting-cost/position`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          "QpmCalculation",
          "QpmCalculationRentingCostPosition",
          "QpmCalculationRentingCost",
        ],
      }),
      qpmCalcRentingCostPositionGet: build.query<
        RentingPositionList,
        LoadRentingCostRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/renting-cost/position`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCalculationRentingCostPosition" }] : [],
      }),
      qpmCalcRentingCostDurationPut: build.mutation<
        RentingCost,
        UpdateRentingCostDurationRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/renting-cost/update-duration`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          "QpmCalculation",
          "QpmCalculationRentingCost",
          "QpmCalculationRentingCostPosition",
        ],
      }),
      qpmCalcRentingCostPositionPricePost: build.mutation<
        RentingPosition,
        UpdateRentingCostPositionRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/renting-cost/update-position-price`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          { type: "QpmCalculationMaterialPrices", id: "LIST" },
          { type: "QpmCalculationMaintenancePrices", id: "LIST" },
          "QpmCalculation",
          "QpmCalculationMaterialPrices",
          "QpmCalculationServiceCost",
          "QpmCalculationMaintenancePrices",
          "QpmCalculationRentingCost",
          "QpmCalculationRentingCostPosition",
        ],
      }),
      qpmCalcServiceCostPositionAmountPost: build.mutation<
        UpdateServicePositionAmountResponse,
        UpdateServicePositionAmountRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-cost/update-position-amount`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: (result) =>
          result
            ? [
                { type: "QpmCalculationMaterialPrices", id: "LIST" },
                { type: "QpmCalculationMaintenancePrices", id: "LIST" },
                { type: "QpmCalculation", id: result.calculationId },
                "QpmCalculationMaterialPrices",
                "QpmCalculation",
                "QpmCalculationServiceCost",
                "QpmCalculationMaintenancePrices",
                "QpmCalculationRentingCost",
                "QpmCalculationRentingCostPosition",
              ]
            : [],
      }),
      qpmCalcServiceCostServiceTimePerMonitorPost: build.mutation<
        UpdateServiceTimePerMonitorResponse,
        UpdateServiceTimePerMonitorRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-cost/update-service-time-per-monitor`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: (result) =>
          result
            ? [
                { type: "QpmCalculationMaterialPrices", id: "LIST" },
                { type: "QpmCalculationMaintenancePrices", id: "LIST" },
                { type: "QpmCalculation", id: result.calculationId },
                "QpmCalculationMaterialPrices",
                "QpmCalculation",
                "QpmCalculationServiceCost",
                "QpmCalculationMaintenancePrices",
                "QpmCalculationRentingCost",
                "QpmCalculationRentingCostPosition",
              ]
            : [],
      }),
      qpmCalcServiceCostTargetRevenuePost: build.mutation<
        UpdateTargetRevenueResponse,
        UpdateTargetRevenueRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-cost/update-target-revenue`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: (result) =>
          result
            ? [
                { type: "QpmCalculationMaterialPrices", id: "LIST" },
                { type: "QpmCalculationMaintenancePrices", id: "LIST" },
                { type: "QpmCalculation", id: result.calculationId },
                "QpmCalculationMaterialPrices",
                "QpmCalculation",
                "QpmCalculationServiceCost",
                "QpmCalculationMaintenancePrices",
                "QpmCalculationRentingCost",
                "QpmCalculationRentingCostPosition",
              ]
            : [],
      }),
      qpmCalcServiceCostTravelingDurationPost: build.mutation<
        UpdateTravelingDurationResponse,
        UpdateTravelingDurationRequest
      >({
        query: (request) => ({
          url: `/qpm/calculation/service-cost/update-traveling-duration`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: (result) =>
          result
            ? [
                { type: "QpmCalculationMaterialPrices", id: "LIST" },
                { type: "QpmCalculationMaintenancePrices", id: "LIST" },
                { type: "QpmCalculation", id: result.calculationId },
                "QpmCalculationMaterialPrices",
                "QpmCalculation",
                "QpmCalculationServiceCost",
                "QpmCalculationMaintenancePrices",
                "QpmCalculationRentingCost",
                "QpmCalculationRentingCostPosition",
              ]
            : [],
      }),
    }),
  });

export const {
  useQpmCalculationCreateMutation,
  useQpmCalculationCreateFromOldMutation,
  useQpmCalculationLoadRequirementMutation,
  useQpmCalculationShowQuery,
  useQpmCalculationListQuery,
  useQpmCalculationSetCurrentMutation,
  useQpmCalculationShowCurrentQuery,
  useQpmCalculationSetMaterialPriceMutation,
  useQpmCalculationGetMaterialCostQuery,
  useQpmCalculationGetMaintenancePricesQuery,
  useQpmUpdateCalculationParametersMutation,
  useQpmCalcDocumentationPutMutation,
  useQpmCalcDocumentationGetQuery,
  useQpmCalcDocumentationListQuery,
  useQpmCalcDocumentationDeleteMutation,
  useQpmCalcServiceDocumentationPutMutation,
  useQpmCalcServiceDocListQuery,
  useQpmCalcServiceDocumentationDeleteMutation,
  useQpmCalcServiceCostGetQuery,
  useQpmCalcServiceCostListQuery,
  useQpmCalcRentingCostPutMutation,
  useQpmCalcRentingCostGetQuery,
  useQpmCalcRentingCostPositionPutMutation,
  useQpmCalcRentingCostPositionGetQuery,
  useQpmCalcRentingCostDurationPutMutation,
  useQpmCalcRentingCostPositionPricePostMutation,
  useQpmCalcServiceCostPositionAmountPostMutation,
  useQpmCalcServiceCostServiceTimePerMonitorPostMutation,
  useQpmCalcServiceCostTargetRevenuePostMutation,
  useQpmCalcServiceCostTravelingDurationPostMutation,
} = qpmCalculationApi;
