import {
  CalculationServiceEntryType,
  CalculationServiceType,
} from "@/services/backend/qpm/calculations/enum";

export class CalculationServiceEntryUtil {
  private static readonly descriptions: {
    [key in CalculationServiceEntryType]: string;
  } = {
    [CalculationServiceEntryType.ALoRa]: "ALoRa",
    [CalculationServiceEntryType.CheckPoint]: "Prüfpunkte",
    [CalculationServiceEntryType.CheckPointSCB]: "Prüfpunkte mit Wirkstoff",
    [CalculationServiceEntryType.FIV]: "Fluginsektenvernichter",
  };

  private static readonly installationDescriptions: {
    [key in CalculationServiceEntryType]: string;
  } = {
    [CalculationServiceEntryType.ALoRa]: "Einrichtung ALoRa",
    [CalculationServiceEntryType.CheckPoint]: "Einrichtung Prüfpunkte",
    [CalculationServiceEntryType.CheckPointSCB]:
      "Einrichtung Prüfpunkte mit Wirkstoff",
    [CalculationServiceEntryType.FIV]: "Einrichtung Fluginsektenvernichter",
  };

  private static readonly maintenanceDescriptions: {
    [key in CalculationServiceEntryType]: string;
  } = {
    [CalculationServiceEntryType.ALoRa]: "Funktionsprüfung ALoRa",
    [CalculationServiceEntryType.CheckPoint]: "Service Prüfpunkte",
    [CalculationServiceEntryType.CheckPointSCB]:
      "Service Prüfpunkte mit Wirkstoff",
    [CalculationServiceEntryType.FIV]: "Wartung Fluginsektenvernichter",
  };

  private static readonly abbreviations: {
    [key in CalculationServiceEntryType]: string;
  } = {
    [CalculationServiceEntryType.ALoRa]: "ALoRa",
    [CalculationServiceEntryType.CheckPoint]: "PP",
    [CalculationServiceEntryType.CheckPointSCB]: "SbD",
    [CalculationServiceEntryType.FIV]: "FIV",
  };

  private static readonly installationAbbreviations: {
    [key in CalculationServiceEntryType]: string;
  } = {
    [CalculationServiceEntryType.ALoRa]: "Einrichtung ALoRa",
    [CalculationServiceEntryType.CheckPoint]: "Einrichtung PP",
    [CalculationServiceEntryType.CheckPointSCB]: "Einrichtung SbD",
    [CalculationServiceEntryType.FIV]: "Einrichtung FIV",
  };

  private static readonly maintenanceAbbreviations: {
    [key in CalculationServiceEntryType]: string;
  } = {
    [CalculationServiceEntryType.ALoRa]: "Funktionsprüfung ALoRa",
    [CalculationServiceEntryType.CheckPoint]: "Service PP",
    [CalculationServiceEntryType.CheckPointSCB]: "Service SbD",
    [CalculationServiceEntryType.FIV]: "Wartung FIV",
  };

  static toString(
    type: CalculationServiceEntryType,
    serviceTypeKey?: CalculationServiceType,
  ): string {
    if (
      serviceTypeKey &&
      serviceTypeKey === CalculationServiceType.Maintenance
    ) {
      return this.maintenanceAbbreviations[type];
    }
    if (
      serviceTypeKey &&
      serviceTypeKey === CalculationServiceType.Installation
    ) {
      return this.installationAbbreviations[type];
    }

    return this.abbreviations[type];
  }

  static toLongString(
    type: CalculationServiceEntryType,
    serviceTypeKey?: CalculationServiceType,
  ): string {
    if (
      serviceTypeKey &&
      serviceTypeKey === CalculationServiceType.Maintenance
    ) {
      return this.maintenanceDescriptions[type];
    }
    if (
      serviceTypeKey &&
      serviceTypeKey === CalculationServiceType.Installation
    ) {
      return this.installationDescriptions[type];
    }
    return this.descriptions[type] || "Unknown";
  }
}
