import {
  assessmentsWithoutDelivery,
  HTZ_WORK_ORDER_NAMESPACE,
  Position,
} from "@/services/backend/htz/work-order/work-order";
import { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Plus, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Textarea } from "@/shared/components/ui/textarea";
import { EntityId, UUID } from "@/shared/nidavellir/types/entity-id";
import { useSamplesLaboratoryListQuery } from "@/services/backend/samples/lab/service";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { Card } from "@/shared/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Kind } from "@/services/backend/samples/sample/kind";
import {
  useSamplesAirMicrobialSampleSetListQuery,
  useSamplesAirMicrobialSampleSetUpdateLabMutation,
} from "@/services/backend/samples/sample/air-microbial-service";
import {
  useSamplesDustDensitySampleListQuery,
  useSamplesDustDensitySampleUpdateLabMutation,
} from "@/services/backend/samples/sample/dust-density-sample-service";
import {
  useSamplesSurfaceMicrobialSampleSetListQuery,
  useSamplesSurfaceMicrobialSampleSetUpdateLabMutation,
} from "@/services/backend/samples/sample/surface-microbial-service";
import {
  useSamplesWaterSampleListQuery,
  useSamplesWaterSampleUpdateLabMutation,
} from "@/services/backend/samples/sample/water-sample-service";
import { Checkbox } from "@/shared/components/ui/checkbox";
import {
  AirHandlingUnitComponentName,
  AirHandlingUnitName,
} from "@/shared/components/domain/htz/ahu/air-handling-unit-name";
import { NullUUID } from "@/shared/lib/utilities/uuid";
import { Skeleton } from "@/shared/components/ui/skeleton";
import {
  CreateRequest,
  useSamplesDeliveryCreateMutation,
  useSamplesDeliveryListQuery,
} from "@/services/backend/samples/delivery/service";
import { v4 } from "uuid";
import { useHtzWorkOrderListPositionQuery } from "@/services/backend/htz/work-order/service";
import { Delivery } from "@/services/backend/samples/delivery/delivery";

export function CreateSampleDeliveryDialog({
  workOrderId,
}: {
  workOrderId: string;
}) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<CreateRequest>({
    id: v4(),
    artefactId: workOrderId,
    artefactNamespace: HTZ_WORK_ORDER_NAMESPACE,
    trackingId: "",
    labId: null,
    description: "",
    sampleIds: [],
  });

  const [send, { isLoading, error, isSuccess, reset }] =
    useSamplesDeliveryCreateMutation();

  const doSend = () => {
    if (isLoading) return;

    send(request);
  };

  if (isSuccess) {
    reset();
    setRequest({
      id: v4(),
      artefactId: workOrderId,
      artefactNamespace: HTZ_WORK_ORDER_NAMESPACE,
      trackingId: "",
      labId: null,
      description: "",
      sampleIds: [],
    });
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Lieferung")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] max-w-[90vw] overflow-auto">
        <DialogHeader>
          <DialogTitle>{t("Neue Probenlieferung")}</DialogTitle>
          <DialogDescription>
            {t("Hier kann eine neue Lieferung angekündigt werden.")}
          </DialogDescription>
        </DialogHeader>
        <div className="grid items-start gap-4 md:grid-cols-2">
          <div className="grid gap-4">
            <LaboratorySelect
              value={request.labId}
              onChange={(labId) => setRequest({ ...request, labId })}
              error={error}
            />
            <TrackingIdInput
              value={request.trackingId}
              onChange={(trackingId) => setRequest({ ...request, trackingId })}
              error={error}
            />
          </div>
          <DescriptionInput
            value={request.description}
            onChange={(description) => setRequest({ ...request, description })}
            error={error}
          />
        </div>
        <SampleSelect
          selectedSamples={request.sampleIds}
          onSelectedSamplesChange={(sampleIds) =>
            setRequest({ ...request, sampleIds })
          }
          labId={request.labId}
          error={error}
          workOrderId={workOrderId}
        />
        <RTKQueryErrorAlert error={error} ignoreValidationError={false} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button disabled={isLoading} onClick={doSend}>
            {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
            <span>{t("Senden")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function TrackingIdInput({
  value,
  onChange,
  error,
}: {
  value: string;
  onChange: (value: string) => void;
  error: unknown;
}) {
  return (
    <div className="grid gap-1.5">
      <Label htmlFor="trackingId">{t("Tracking ID (Paketdienst)")}</Label>
      <Input
        id="trackingId"
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <InputValidationErrors error={error} field="trackingId" />
    </div>
  );
}

function DescriptionInput({
  value,
  onChange,
  error,
}: {
  value: string;
  onChange: (value: string) => void;
  error: unknown;
}) {
  return (
    <div className="grid gap-1.5">
      <Label htmlFor="description">{t("Beschreibung")}</Label>
      <Textarea
        id="description"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <InputValidationErrors error={error} field="description" />
    </div>
  );
}

function LaboratorySelect({
  value,
  onChange,
  error,
}: {
  value: EntityId;
  onChange: (value: EntityId) => void;
  error: unknown;
}) {
  const {
    data,
    isLoading,
    error: listError,
  } = useSamplesLaboratoryListQuery({});

  if (listError) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="grid gap-1.5">
      <Label htmlFor="labId">{t("Labor")}</Label>
      <Select value={String(value ?? "")} onValueChange={onChange}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {data?.labs.map((lab) => (
              <SelectItem key={lab.id} value={lab.id}>
                {lab.name}
              </SelectItem>
            ))}
            {isLoading && (
              <div className="px-2">
                <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                <span>{t("Lade")}</span>
              </div>
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputValidationErrors error={error} field="labId" />
    </div>
  );
}

function SampleSelect({
  selectedSamples,
  onSelectedSamplesChange,
  labId,
  error,
  workOrderId,
}: {
  selectedSamples: { id: string; kind: Kind }[];
  onSelectedSamplesChange: (value: { id: string; kind: Kind }[]) => void;
  labId: string | null;
  error: unknown;
  workOrderId: string;
}) {
  // load all samples for the work order
  const {
    samples,
    isLoading,
    error: samplesError,
  } = useSamplesList(workOrderId);

  // any error should be caught further above
  const { data: positions } = useHtzWorkOrderListPositionQuery({
    workOrder: {
      active: true,
      values: [workOrderId],
    },
  });

  const { data: sampleDeliveryList } = useSamplesDeliveryListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  // load the microbial assessments without a delivery
  // (this prevents adding a sample to multiple deliveries)
  const assessments = assessmentsWithoutDelivery(
    sampleDeliveryList?.deliveries ?? ([] as Delivery[]),
    positions?.positions ?? ([] as Position[]),
  );
  // enrich the sample data with the assessment data
  // (this provides the user with the information about the AHU and component)
  const combined = useMemo(() => {
    if (samples) {
      return samples.reduce((acc, sample) => {
        const assessment = assessments.find((a) => a.sampleId === sample.id);
        if (assessment) {
          return [
            ...acc,
            {
              ...sample,
              airHandlingUnit: assessment.airHandlingUnitId,
              component: assessment.componentId,
              createdAt: assessment.createdAt,
            },
          ];
        }
        return acc;
      }, [] as AssessmentSample[]);
    }
    return [] as AssessmentSample[];
  }, [assessments, samples]);

  const samplesForSelectedLab = combined.filter(
    (s) => labId !== null && s.labId === labId,
  );
  const samplesForOtherLab = combined.filter(
    (s) => s.labId !== labId && s.labId !== null,
  );
  const samplesWithoutLab = combined.filter((s) => s.labId === null);

  const toggle = (sample: AssessmentSample) => () => {
    if (selectedSamples.some((selected) => selected.id === sample.id)) {
      onSelectedSamplesChange(
        selectedSamples.filter((selected) => selected.id !== sample.id),
      );
    } else {
      onSelectedSamplesChange([
        ...selectedSamples,
        { id: sample.id, kind: sample.kind },
      ]);
    }
  };

  useEffect(() => {
    const sa =
      samplesForOtherLab.find((s) =>
        selectedSamples.some((ss) => ss.id === s.id),
      ) ||
      samplesWithoutLab.find((s) =>
        selectedSamples.some((ss) => ss.id === s.id),
      );
    if (sa) {
      onSelectedSamplesChange(selectedSamples.filter((ss) => ss.id !== sa.id));
    }
  }, [
    selectedSamples,
    samplesForOtherLab,
    samplesWithoutLab,
    onSelectedSamplesChange,
  ]);

  if (samplesError) {
    return <RTKQueryErrorAlert error={samplesError} />;
  }

  return (
    <div>
      <div className="mb-2 text-lg font-semibold leading-none tracking-tight">
        {t("Nicht versendete Proben des Arbeitsscheins")}
      </div>
      <div className="max-h-96 overflow-auto">
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("Ausgewählt")}</TableHead>
                <TableHead>{t("Art")}</TableHead>
                <TableHead>{t("Nummer")}</TableHead>
                <TableHead>{t("Anlage")}</TableHead>
                <TableHead>{t("Komponente")}</TableHead>
                <TableHead>{t("Labor")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className="border-b-0">
                <TableCell colSpan={6} className="bg-accent py-1 font-medium">
                  <span>
                    {t(
                      "Proben für das ausgewählte Labor (nur diese werden der Sendung hinzugefügt)",
                    )}
                  </span>
                  <SelectAllForLabButton
                    onClick={() =>
                      onSelectedSamplesChange(
                        samplesForSelectedLab.map((s) => ({
                          id: s.id,
                          kind: s.kind,
                        })),
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              {samplesForSelectedLab.map((sample) => (
                <SampleRow
                  key={sample.id}
                  sample={sample}
                  selected={selectedSamples.some((ss) => ss.id === sample.id)}
                  toggle={toggle(sample)}
                />
              ))}
              <TableRow className="border-b-0">
                <TableCell colSpan={6} className="bg-accent py-1 font-medium">
                  <span>{t("Proben ohne zugewiesenes Labor")}</span>
                </TableCell>
              </TableRow>
              {samplesWithoutLab.map((sample) => (
                <SampleRow
                  key={sample.id}
                  sample={sample}
                  selected={selectedSamples.some((ss) => ss.id === sample.id)}
                  toggle={() => null}
                />
              ))}
              <TableRow className="border-b-0">
                <TableCell colSpan={6} className="bg-accent py-1 font-medium">
                  <span>{t("Proben mit anderen Laboren")}</span>
                </TableCell>
              </TableRow>
              {samplesForOtherLab.map((sample) => (
                <SampleRow
                  key={sample.id}
                  sample={sample}
                  selected={selectedSamples.some((ss) => ss.id === sample.id)}
                  toggle={() => null}
                />
              ))}
              {isLoading && (
                <>
                  <TableRowSkeleton />
                  <TableRowSkeleton />
                  <TableRowSkeleton />
                </>
              )}
            </TableBody>
          </Table>
        </Card>
        <RTKQueryErrorAlert
          error={error}
          ignoreValidationError={false}
          className="mt-2"
        />
      </div>
    </div>
  );
}

interface AssessmentSample {
  id: string;
  kind: Kind;
  number: string;
  labId: string | null;
  airHandlingUnit: UUID;
  component: UUID;
  createdAt: string;
}

function useSamplesList(workOrderId: string) {
  const {
    data: airMicrobial,
    isLoading: airMicrobialIsLoading,
    error: airMicrobialError,
  } = useSamplesAirMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const {
    data: dustDensity,
    isLoading: dustDensityIsLoading,
    error: dustDensityError,
  } = useSamplesDustDensitySampleListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const {
    data: surfaceMicrobial,
    isLoading: surfaceMicrobialIsLoading,
    error: surfaceMicrobialError,
  } = useSamplesSurfaceMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const {
    data: water,
    isLoading: waterIsLoading,
    error: waterError,
  } = useSamplesWaterSampleListQuery({
    artefact: {
      active: true,
      values: [workOrderId],
    },
  });

  const isLoading =
    airMicrobialIsLoading ||
    dustDensityIsLoading ||
    surfaceMicrobialIsLoading ||
    waterIsLoading;
  const error =
    airMicrobialError ||
    dustDensityError ||
    surfaceMicrobialError ||
    waterError;

  const samples: {
    id: string;
    kind: Kind;
    number: string;
    labId: string | null;
  }[] = useMemo(() => {
    const list = [];
    if (airMicrobial) {
      list.push(
        ...airMicrobial.sampleSets.map((set) => ({
          id: set.id,
          kind: Kind.AirMicrobial,
          number: set.number,
          labId: set.labId,
        })),
      );
    }
    if (dustDensity) {
      list.push(
        ...dustDensity.samples.map((sample) => ({
          id: sample.id,
          kind: Kind.DustDensity,
          number: sample.number,
          labId: sample.labId,
        })),
      );
    }
    if (surfaceMicrobial) {
      list.push(
        ...surfaceMicrobial.sampleSets.map((set) => ({
          id: set.id,
          kind: Kind.SurfaceMicrobial,
          number: set.number,
          labId: set.labId,
        })),
      );
    }
    if (water) {
      list.push(
        ...water.samples.map((sample) => ({
          id: sample.id,
          kind: Kind.Water,
          number: sample.number,
          labId: sample.labId,
        })),
      );
    }
    return list;
  }, [airMicrobial, surfaceMicrobial, dustDensity, water]);

  return { samples, isLoading, error };
}

function SelectAllForLabButton({ onClick }: { onClick: () => void }) {
  return (
    <Button onClick={onClick} className="ml-4 h-6" variant="outline">
      {t("Alle auswählen")}
    </Button>
  );
}

function SampleRow({
  sample,
  selected,
  toggle,
}: {
  sample: AssessmentSample;
  selected: boolean;
  toggle: () => void;
}) {
  return (
    <TableRow>
      <TableCell className="flex items-center py-1">
        <Checkbox
          checked={selected}
          onCheckedChange={toggle}
          className="h-6 w-6"
        />
      </TableCell>
      <TableCell className="py-0">{t(sample.kind)}</TableCell>
      <TableCell className="py-0">{sample.number}</TableCell>
      <TableCell className="py-0">
        <AirHandlingUnitName id={sample.airHandlingUnit} />
      </TableCell>
      <TableCell className="py-0">
        <AirHandlingUnitComponentName
          id={sample.airHandlingUnit}
          componentId={sample.component}
        />
      </TableCell>
      <TableCell className="py-1">
        <LaboratoryUpdater sample={sample} />
      </TableCell>
    </TableRow>
  );
}

function LaboratoryUpdater({ sample }: { sample: AssessmentSample }) {
  const {
    data: labList,
    error: labListError,
    isLoading: labListIsLoading,
  } = useSamplesLaboratoryListQuery({});

  const [
    patchAirMicrobial,
    { isLoading: airMicrobialIsLoading, error: airMicrobialError },
  ] = useSamplesAirMicrobialSampleSetUpdateLabMutation();

  const [
    patchSurfaceMicrobial,
    { isLoading: surfaceMicrobialIsLoading, error: surfaceMicrobialError },
  ] = useSamplesSurfaceMicrobialSampleSetUpdateLabMutation();

  const [patchWater, { isLoading: waterIsLoading, error: waterError }] =
    useSamplesWaterSampleUpdateLabMutation();

  const [
    patchDustDensity,
    { isLoading: dustDensityIsLoading, error: dustDensityError },
  ] = useSamplesDustDensitySampleUpdateLabMutation();

  const patchSample = (labId: string) => {
    switch (sample.kind) {
      case Kind.AirMicrobial:
        patchAirMicrobial({ id: sample.id, labId });
        break;
      case Kind.SurfaceMicrobial:
        patchSurfaceMicrobial({ id: sample.id, labId });
        break;
      case Kind.Water:
        patchWater({ id: sample.id, labId });
        break;
      case Kind.DustDensity:
        patchDustDensity({ id: sample.id, labId });
        break;
      default:
        break;
    }
  };

  const isLoading =
    airMicrobialIsLoading ||
    labListIsLoading ||
    surfaceMicrobialIsLoading ||
    waterIsLoading ||
    dustDensityIsLoading;
  const error =
    airMicrobialError ||
    labListError ||
    surfaceMicrobialError ||
    waterError ||
    dustDensityError;

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Select value={sample.labId ?? ""} onValueChange={patchSample}>
      <SelectTrigger className="h-6">
        <SelectValue
          placeholder={
            isLoading && <RefreshCw className="h-3 w-3 animate-spin" />
          }
        />
      </SelectTrigger>
      <SelectContent>
        {labList?.labs.map((lab) => (
          <SelectItem key={lab.id} value={lab.id}>
            {lab.name}
          </SelectItem>
        ))}
        <SelectItem value={NullUUID}>{t("- kein Labor -")}</SelectItem>
        {isLoading && (
          <div className="px-2">
            <RefreshCw className="h-4 w-4" />
          </div>
        )}
      </SelectContent>
    </Select>
  );
}

function TableRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton className="h-4 w-52" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-52" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-52" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-52" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-52" />
      </TableCell>
    </TableRow>
  );
}
