import React, { useEffect, useState } from "react";
import { Input } from "@/shared/components/ui/input";
import { IntNumberInput } from "@/routes/gemex/processes/_components/ui/number-input";

export type PropertyFieldProps = {
  value: number;
  displayUnit: string;
  onValueChange: (value: number) => void;
  disabled?: boolean;
};

export function PropertyFields({
  value,
  displayUnit,
  onValueChange,
  disabled = false,
}: PropertyFieldProps) {
  const val = value === null ? 0 : value;

  return (
    <div className="flex items-center gap-2 p-1">
      <IntNumberInput
        value={val}
        onChange={onValueChange}
        disabled={disabled}
      />
      <span>{displayUnit}</span>
    </div>
  );
}

type TimePropertyFieldProps = {
  displayValue: number | undefined;
  displayUnit: string;
  onValueChange: (value: number) => void;
  immutable: boolean;
};

// Utility function to convert nanoseconds to hh:mm:ss
const formatTimeFromNanoseconds = (nanoseconds: number) => {
  const totalSeconds = Math.floor(nanoseconds / 1_000_000_000);
  const hrs = Math.floor(totalSeconds / 3600);
  const mins = Math.floor((totalSeconds % 3600) / 60);
  const secs = totalSeconds % 60;

  return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

// Utility function to parse hh:mm:ss to seconds
const parseTime = (timeString: string) => {
  const [hrs, mins, secs] = timeString.split(":").map(Number);

  // Convert the time to nanoseconds
  return ((hrs || 0) * 3600 + (mins || 0) * 60 + (secs || 0)) * 1_000_000_000;
};

export function PropertyTimeField({
  displayValue,
  displayUnit,
  onValueChange,
  immutable = false,
}: TimePropertyFieldProps) {
  const [formattedValue, setFormattedValue] = useState(
    formatTimeFromNanoseconds(displayValue || 0),
  );

  useEffect(() => {
    setFormattedValue(formatTimeFromNanoseconds(displayValue || 0));
  }, [displayValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeNanoSeconds = parseTime(e.target.value);
    onValueChange(timeNanoSeconds);
    setFormattedValue(e.target.value);
  };

  return (
    <div className="flex items-center gap-2 p-1">
      <Input
        type="time"
        min={0}
        step={1}
        className="rounded-sm border px-1 disabled:bg-muted disabled:opacity-100"
        value={formattedValue}
        onChange={handleChange}
        disabled={immutable}
      />
      <span>{displayUnit}</span>
    </div>
  );
}
