import { useParams } from "react-router-dom";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import t from "@/lang/lang";
import { fMoney } from "@/routes/gesec/processes/_components/ui/number-input";
import { useState } from "react";
import { useQpmCalculationShowQuery } from "@/services/backend/qpm/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import { ChevronDown, ChevronRight, Loader2 } from "lucide-react";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import { MaterialPriceListComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/material-price-list";

export function CalculationMaterialOverviewComponent() {
  const { calculationId } = useParams();

  const displayMaterial = [
    MonitorTypeKey.CheckPoint,
    MonitorTypeKey.FlyExterminator,
    MonitorTypeKey.ALoRa,
  ];
  return (
    calculationId && (
      <div className="overflow-clip rounded-lg border shadow">
        {displayMaterial.map((key) => (
          <MaterialCostsComponent
            key={key}
            calculationId={calculationId}
            monitorTypeKey={key}
          />
        ))}
        <div className="flex justify-between rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">{t("Gesamt")}</span>
          <span className="font-bold">{fMoney(0)}</span>
        </div>
      </div>
    )
  );
}

export type MaterialCostsComponentProps = {
  calculationId: string;
  monitorTypeKey: MonitorTypeKey;
};

function MaterialCostsComponent({
  calculationId,
  monitorTypeKey,
}: MaterialCostsComponentProps) {
  const [open, setOpen] = useState(true);
  const {
    data: calculation,
    isLoading,
    error,
  } = useQpmCalculationShowQuery(
    { artefactMetaId: calculationId! },
    { skip: !calculationId },
  );
  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  let totalCost = 0;
  if (calculation) {
    switch (monitorTypeKey) {
      case MonitorTypeKey.CheckPoint:
        totalCost = calculation.totalCostCheckPointMaterial;
        break;
      case MonitorTypeKey.FlyExterminator:
        totalCost = calculation.totalCostFlyExterminatorMaterial;
        break;
      case MonitorTypeKey.ALoRa:
        totalCost = calculation.totalCostALoRaMaterial;
        break;
      default:
        totalCost = 0; // Fallback value if the key doesn't match any case
    }
  }

  return (
    <Collapsible open={open} onOpenChange={setOpen} key={monitorTypeKey}>
      <div className="border-t bg-gray-50 px-6 py-3">
        <div className="flex justify-between font-bold">
          <CollapsibleTrigger>
            <div className="flex items-center space-x-2">
              {open ? (
                <ChevronDown className="h-5 w-5" />
              ) : (
                <ChevronRight className="h-5 w-5" />
              )}
              <span>
                {t("Materialkosten")}{" "}
                {t(MonitorTypeUtil.toString(monitorTypeKey))}
              </span>
            </div>
          </CollapsibleTrigger>
          <span className="">
            {isLoading && <Loader2 className="animate-spin" />}
            {!isLoading && fMoney(totalCost)}
          </span>
        </div>
      </div>
      <CollapsibleContent>
        <div className="border-t">
          <div className="">
            <MaterialPriceListComponent
              calculationId={calculationId}
              monitorTypeKey={monitorTypeKey}
            />
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}
