import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";

export class MonitorTypeUtil {
  private static descriptions: { [key in MonitorTypeKey]: string } = {
    [MonitorTypeKey.CheckPoint]: "Prüfpunkt",
    [MonitorTypeKey.FlyExterminator]: "Fluginsektenvernichter",
    [MonitorTypeKey.ALoRa]: "ALoRa",
  };

  private static abbreviations: { [key in MonitorTypeKey]: string } = {
    [MonitorTypeKey.CheckPoint]: "PP",
    [MonitorTypeKey.FlyExterminator]: "FIV",
    [MonitorTypeKey.ALoRa]: "ALoRa",
  };

  static toString(type: MonitorTypeKey): string {
    return this.abbreviations[type];
  }

  static toLongString(type: MonitorTypeKey): string {
    return this.descriptions[type] || "Unknown";
  }
}
