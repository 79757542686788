import { Camera, CheckCircle, Eye, Tags } from "lucide-react";
import { StructuralAssessment } from "@/services/backend/htz/inspection/structural-assessment";
import { useMemo } from "react";
import { cn } from "@/shared/lib/utils";
import { Severity } from "@/services/backend/htz/inspection/severity";
import { VisualAssessment } from "@/services/backend/htz/inspection/visual-assessment";
import { Soiling } from "@/services/backend/htz/inspection/soiling";
import { Photo } from "@/services/backend/htz/inspection/photo";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import { useListPosition } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-put-position";

export function InspectionIndicator({ componentId }: { componentId: string }) {
  const { data: positions } = useListPosition();

  if (!positions) {
    return null;
  }

  const assessments = positions.reduce(
    (acc, pos) => {
      // position assessments for component
      const sas =
        pos.structuralAssessments?.filter(
          (a) => a.componentId === componentId,
        ) ?? [];
      const vas =
        pos.visualAssessments?.filter((a) => a.componentId === componentId) ??
        [];
      const mis =
        pos.microbialAssessments?.filter(
          (a) => a.componentId === componentId,
        ) ?? [];
      const photos =
        pos.photos?.filter((p) => p.componentId === componentId) ?? [];
      if (
        sas.length > 0 ||
        vas.length > 0 ||
        mis.length > 0 ||
        photos.length > 0
      ) {
        return {
          sas: [...acc.sas, ...sas],
          vas: [...acc.vas, ...vas],
          mis: [...acc.mis, ...mis],
          photos: [...acc.photos, ...photos],
        };
      }
      return acc;
    },
    {
      sas: [] as StructuralAssessment[],
      vas: [] as VisualAssessment[],
      mis: [] as MicrobialAssessment[],
      photos: [] as Photo[],
    },
  );

  return (
    <div className="flex flex-wrap gap-1">
      {assessments.sas.map((a) => (
        <StructuralAssessmentIndicator assessment={a} key={a.id} />
      ))}
      {assessments.vas.map((a) => (
        <VisualAssessmentIndicator assessment={a} key={a.id} />
      ))}
      {assessments.mis.map((a) => (
        <MicrobialAssessmentIndicator assessment={a} key={a.id} />
      ))}
      {assessments.photos.map((p) => (
        <PhotoIndicator photo={p} key={p.id} />
      ))}
    </div>
  );
}

function StructuralAssessmentIndicator({
  assessment,
}: {
  assessment: StructuralAssessment;
}) {
  const className = useMemo(() => {
    switch (assessment.severity) {
      case Severity.Minor:
        return "bg-yellow-300 border-yellow-300";
      case Severity.Major:
        return "bg-red-500 border-red-500";
      default:
        return "bg-background";
    }
  }, [assessment]);

  return (
    <div
      className={cn(
        "inline-flex h-6 min-w-6 items-center rounded-md border px-1 text-[8pt]",
        className,
      )}
    >
      <CheckCircle className="mr-1 h-3 w-3" />
      <span>{assessment.shorthand}</span>
    </div>
  );
}

function VisualAssessmentIndicator({
  assessment,
}: {
  assessment: VisualAssessment;
}) {
  const className = useMemo(() => {
    switch (assessment.soiling) {
      case Soiling.Moderate:
        return "bg-yellow-300 border-yellow-300";
      case Soiling.Heavy:
        return "bg-red-500 border-red-500";
      default:
        return "bg-background";
    }
  }, [assessment]);

  return (
    <div
      className={cn(
        "inline-flex h-6 min-w-6 items-center justify-center rounded-md border px-1 text-[8pt]",
        className,
      )}
    >
      <Eye className="h-4 w-4" />
    </div>
  );
}

function MicrobialAssessmentIndicator({
  assessment,
}: {
  assessment: MicrobialAssessment;
}) {
  // this indicates that there is no actual sample taken
  if (assessment.sampleId === null) {
    return null;
  }

  return (
    <div
      className={cn(
        "inline-flex h-6 min-w-6 items-center justify-center rounded-md border bg-background px-1 text-[8pt]",
      )}
    >
      <Tags className="h-4 w-4" />
    </div>
  );
}

function PhotoIndicator({ photo }: { photo: Photo }) {
  // this indicates that there is no actual photo taken
  if (photo.imageId === null) {
    return null;
  }

  return (
    <div
      className={cn(
        "inline-flex h-6 min-w-6 items-center justify-center rounded-md border bg-background px-1 text-[8pt]",
      )}
    >
      <Camera className="h-4 w-4" />
    </div>
  );
}
