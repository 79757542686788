import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { useQpmCalculationGetMaintenancePricesQuery } from "@/services/backend/qpm/calculations/service";
import { MaintenanceEntryComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/maintenance-entry-component";

export type MaintenancePriceListComponentProps = {
  calculationId: string;
  monitorTypeKey: MonitorTypeKey;
};

export function MaintenancePriceListComponent({
  calculationId,
  monitorTypeKey,
}: MaintenancePriceListComponentProps) {
  const { data: maintenancePriceList, error: isErrorMaterials } =
    useQpmCalculationGetMaintenancePricesQuery({
      calculationId: {
        active: true,
        values: [calculationId],
      },
      monitorTypeKey: {
        active: true,
        values: [monitorTypeKey],
      },
    });

  if (isErrorMaterials) {
    return <RTKQueryErrorAlert error={isErrorMaterials} />;
  }

  return (
    maintenancePriceList &&
    maintenancePriceList.positions.map((maintenancePrice) => (
      <MaintenanceEntryComponent
        key={maintenancePrice.monitor.id}
        maintenancePrice={maintenancePrice}
      />
    ))
  );
}
