import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export enum OfflineState {
  ONLINE = "ONLINE",
  PREPARING_OFFLINE = "PREPARING_OFFLINE",
  OFFLINE = "OFFLINE",
  SYNCING = "SYNCING",
}

interface OfflineContextInterface {
  offlineState: OfflineState;
  setOfflineState: (offlineState: OfflineState) => Promise<void>;
  synced: string[];
  setSynced: Dispatch<SetStateAction<string[]>>;
}

const OfflineContext = createContext<OfflineContextInterface>({
  offlineState: OfflineState.ONLINE,
  setOfflineState: () => new Promise<void>(() => {}),
  synced: [],
  setSynced: () => {},
});

/**
 * OfflineContext provides the state and actions to manage the offline mode.
 * It should not include any business logic / offline logic specific to an
 * artefact.
 */
export function useOfflineContext() {
  return useContext(OfflineContext);
}

export function OfflineContextProvider({
  workOrderId,
  children,
}: {
  workOrderId: string;
  children: ReactNode;
}) {
  const [dbReady, setDbReady] = useState(false);
  if (!dbReady) {
    initOfflineStateDB().then(() => setDbReady(true));
  }

  const [state, setState] = useState<OfflineState>();

  if (!state && dbReady) {
    getOfflineState(workOrderId).then((idbState) => setState(idbState));
  }

  const setOfflineState = useCallback(
    async (offlineState: OfflineState) => {
      await putOfflineState(offlineState, workOrderId);
      setState(offlineState);
    },
    [workOrderId],
  );

  const [synced, setSynced] = useState<string[]>([]);

  // 1. Need to check "ready for offline"
  //    - derive for an artefact -> list all dependencies and
  //      check if they are ready for offline
  // 2. Enable Button if Ready for Offline
  // 3. Track changes made offline
  // 4. Sync changes when back online

  // Notwendigkeit zum Arbeitsschein navigieren zu können
  // -> Prozess in indexDB speichern, aber serverversion bevorzugen? -> bust via vector clock
  //    -> delete after sync
  // -> Alternativ: Wenn offline: dann immer zu diesem Artefakt navigieren?
  // In beiden Fällen muss App Global in "Offline" gehen.

  // Idea:
  // Global Dependencies like Processes, ... are READ-ONLY and handles by
  // workbox using a server fist strategy (and some update).
  //
  // Artefact Dependencies (Everything Mutable) are stored in their own indexDB.
  // After the sync, the artefact indexDB is deleted.
  // -> This makes explicit if the sync was done.

  const value = useMemo(
    () => ({
      offlineState: state ?? OfflineState.ONLINE,
      setOfflineState,
      synced,
      setSynced,
    }),
    [state, setOfflineState, synced],
  );

  return (
    <OfflineContext.Provider value={value}>{children}</OfflineContext.Provider>
  );
}

function initOfflineStateDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("offline_state", 1);
    request.onerror = () => {
      reject(request.error);
    };
    request.onsuccess = () => {
      resolve(true);
    };
    request.onupgradeneeded = (event) => {
      // @ts-expect-error this is done as specified in the MDN docs. For some reason TS does not like it.
      const db = event.target.result;
      db.createObjectStore("states", { keyPath: "id" });
    };
  });
}

function putOfflineState(state: OfflineState, id: string) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("offline_state", 1);
    request.onerror = () => {
      reject(request.error);
    };
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("states", "readwrite");
      const store = transaction.objectStore("states");
      const putRequest = store.put({ id, state });
      putRequest.onerror = () => {
        reject(putRequest.error);
      };
      putRequest.onsuccess = () => {
        resolve(true);
      };
    };
  });
}

function getOfflineState(id: string) {
  return new Promise<OfflineState>((resolve, reject) => {
    const request = indexedDB.open("offline_state", 1);
    request.onerror = () => {
      reject(request.error);
    };
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("states", "readonly");
      const store = transaction.objectStore("states");
      const getRequest = store.get(id);
      getRequest.onerror = () => {
        reject(getRequest.error);
      };
      getRequest.onsuccess = () => {
        if (!getRequest.result) {
          resolve(OfflineState.ONLINE);
          return;
        }
        resolve(getRequest.result.state);
      };
    };
  });
}
