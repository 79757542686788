import { UUID } from "@/shared/nidavellir/types/entity-id";
import { useSamplesLaboratoryShowQuery } from "@/services/backend/samples/lab/service";

export function LaboratoryName({ id }: { id: UUID }) {
  const { data } = useSamplesLaboratoryShowQuery({
    id: id ?? "",
  });

  if (!data) {
    return null;
  }

  return <span>{data.name}</span>;
}
