import { Navigate, RouteObject } from "react-router-dom";
import { CalculationRoute } from "@/routes/gemex/processes/qpm/calculations/calculation/calculation-route";
import { CalculationIndexRoute } from "@/routes/gemex/processes/qpm/calculations/index/calculation-index-route";
import { CalculationNavLinkType } from "@/services/backend/qpm/calculations/enum";
import { CalculationRentingOverviewComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/rental/renting-view";
import { CalculationMaterialOverviewComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/material/material-view";
import { CalculationInstallationOverviewComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/installation/installation-view";
import { CalculationMaintenanceOverviewComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/maintenance/maintenance-view";
import { CalculationTotalOverviewComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/total/total-view";

export const calculationsRoutes: RouteObject[] = [
  {
    index: true,
    element: <CalculationIndexRoute />,
  },
  {
    path: ":calculationId",
    element: <CalculationRoute />,
    children: [
      {
        index: true,
        element: <Navigate to={CalculationNavLinkType.MaterialCost} replace />,
      },
      {
        path: CalculationNavLinkType.MaterialCost,
        element: <CalculationMaterialOverviewComponent />,
      },

      {
        path: CalculationNavLinkType.Installation,
        element: <CalculationInstallationOverviewComponent />,
      },
      {
        path: CalculationNavLinkType.Maintenance,
        element: <CalculationMaintenanceOverviewComponent />,
      },
      {
        path: CalculationNavLinkType.Rental,
        element: <CalculationRentingOverviewComponent />,
      },
      {
        path: CalculationNavLinkType.TotalOverview,
        element: <CalculationTotalOverviewComponent />,
      },
    ],
  },
];
