import { Position } from "@/services/backend/htz/work-order/work-order";
import { TableCell } from "@/shared/components/ui/table";
import { Input } from "@/shared/components/ui/input";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { cn } from "@/shared/lib/utils";

export function TitleCell({
  position,
  onPositionChange,
  disabled = false,
}: {
  position: Position;
  onPositionChange: (position: Position) => void;
  disabled?: boolean;
}) {
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorKey = `position.${position.id}.title`;
  const hasError = hasFieldError(notReadyError, errorKey);

  return (
    <TableCell className="py-2">
      <Input
        value={position.title}
        onChange={(e) => {
          onPositionChange({ ...position, title: e.target.value });
          resetNotReadyErrorField(errorKey);
        }}
        disabled={disabled}
        className={cn("h-8", hasError && "border-red-500 shadow-md")}
      />
    </TableCell>
  );
}
