import { ComplementaryServiceComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/complementary-service-component";
import { TravelInfoComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/travel-info-component";
import { PestsComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/pests-component";
import { CycleComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/cycle-component";
import { CertificatesComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/certificates-component";
import { AdditionalCommentsComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/additional-comments-component";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  useQpmRequirementAdditionalInformationAddMutation,
  useQpmRequirementAdditionalInformationGetQuery,
} from "@/services/backend/qpm/requirements/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  AddRequirementAdditionalInformationRequest,
  Requirement,
  RequirementAdditionalInformation,
} from "@/services/backend/qpm/requirements/types";
import { useOutletContext } from "react-router-dom";
import { CreateRequirementContext } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import t from "@/lang/lang";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";

export function AdditionalInfoRoute() {
  const requirementContext = useOutletContext<CreateRequirementContext>();
  const { data: dataAdditionalInfo, error: errorAdditionalInformation } =
    useQpmRequirementAdditionalInformationGetQuery({
      artefactMetaId: requirementContext.requirement.artefactData.id,
    });

  if (errorAdditionalInformation) {
    return <RTKQueryErrorAlert error={errorAdditionalInformation} />;
  }

  return (
    dataAdditionalInfo && (
      <AdditionalInformationComponent
        customerId={requirementContext.customerId}
        requirement={requirementContext.requirement}
        additionalInformation={dataAdditionalInfo}
      />
    )
  );
}

export type AdditionalInformationProps = {
  customerId: string;
  requirement: Requirement;
  additionalInformation: RequirementAdditionalInformation;
};

export function AdditionalInformationComponent({
  customerId,
  requirement,
  additionalInformation,
}: AdditionalInformationProps) {
  const [updateAdditionalInfo, { error, isLoading, isSuccess, reset }] =
    useQpmRequirementAdditionalInformationAddMutation();

  const [additionalInfoState, setAdditionalInfoState] =
    useState<AddRequirementAdditionalInformationRequest>({
      ...additionalInformation,
    });

  useEffect(() => {
    if (additionalInformation) {
      setAdditionalInfoState(additionalInformation);
    }
  }, [additionalInformation]);

  const updateAdditionalInfoState = useCallback(
    (updatedInfo: Partial<RequirementAdditionalInformation>) => {
      setAdditionalInfoState((prev) => ({
        ...prev,
        ...updatedInfo,
      }));
    },
    [],
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    additionalInfoState,
    updateAdditionalInfo,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
    { toastError: true },
  );

  if (!additionalInfoState) {
    return <div>Fehler beim Laden der zusätlichen Informationen.</div>;
  }

  return (
    <Card className="">
      <CardHeader>
        <CardTitle className="text-xl">
          {t("Zusätzliche Informationen")}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col space-y-2">
        <ComplementaryServiceComponent
          additionalInformation={additionalInfoState}
          onUpdate={updateAdditionalInfoState}
        />
        <TravelInfoComponent
          additionalInformation={additionalInfoState}
          onUpdate={updateAdditionalInfoState}
        />
        <PestsComponent requirement={requirement} />
        <CycleComponent
          additionalInformation={additionalInfoState}
          onUpdate={updateAdditionalInfoState}
        />
        <CertificatesComponent
          additionalInformation={additionalInfoState}
          customerId={customerId}
          onUpdate={updateAdditionalInfoState}
        />
        <AdditionalCommentsComponent
          additionalInformation={additionalInfoState}
          onUpdate={updateAdditionalInfoState}
        />
      </CardContent>
    </Card>
  );
}
