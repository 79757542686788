import { Position } from "@/services/backend/htz/work-order/work-order";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { TableCell } from "@/shared/components/ui/table";

export function CompletedCheckboxCell({
  position,
  onPositionChange,
  disabled,
}: {
  position: Position;
  onPositionChange: (position: Position) => void;
  disabled?: boolean;
}) {
  const doToggle = () => {
    onPositionChange({
      ...position,
      completed: !position.completed,
    });
  };

  return (
    <TableCell className="py-2">
      <Checkbox
        checked={position.completed}
        onChange={doToggle}
        disabled={disabled}
      />
    </TableCell>
  );
}
