import { Address } from "@/services/backend/addresses/address/address";

export interface Laboratory {
  id: string;
  name: string;
  address: Address;
  api: LabApiKey;
  analysesAirMicrobialSamples: boolean;
  analysesDustDensitySamples: boolean;
  analysesSurfaceMicrobialSamples: boolean;
  analysesWaterSamples: boolean;
}

export enum LabApiKey {
  Zero = "",
  Aysid = "aysid",
}
