import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import t from "@/lang/lang";
import {
  Position,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { useDeletePosition } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-delete-position";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";

export function DeletePositionDialog({
  workOrder,
  position,
}: {
  workOrder: WorkOrder;
  position: Position;
}) {
  const { offlineState } = useOfflineContext();
  const [deletePosition, { isLoading, error }] = useDeletePosition();

  const doDelete = () => {
    if (isLoading) return;

    deletePosition(position);
  };

  const disabled = workOrder.immutable || isLoading;

  // Deleting positions offline is disabled, since this may lead to inconsistencies.
  // If a sample is associated with the position, the position should not be deleted.
  // This is especially important when the sample is already in a delivery.
  // This cannot be reliably checked when offline. Online, the backend will
  // prevent deletion.
  if (offlineState !== OfflineState.ONLINE) {
    return (
      <Button variant="destructive" onClick={doDelete} disabled={disabled}>
        {t("Löschen")}
      </Button>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild disabled={disabled}>
        <Button variant="destructive" className="h-8 w-8 p-0">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Position wirklich löschen?")}</DialogTitle>
          <DialogDescription>
            {position.title + t(" wird unwiderruflich gelöscht.")}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete} disabled={disabled}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
