import {
  Consumable,
  MaintenancePrice,
} from "@/services/backend/qpm/calculations/types";
import t from "@/lang/lang";
import { ConsumableTypeKey } from "@/services/backend/qpm/shared/enums";
import {
  FloatNumberInput,
  IntNumberInput,
  Value,
} from "@/routes/gemex/processes/_components/ui/number-input";
import { useEffect, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import { Button } from "@/shared/components/ui/button";
import { MoreVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Label } from "@/shared/components/ui/label";

export type MaintenanceEntryComponentProps = {
  maintenancePrice: MaintenancePrice;
};

export function MaintenanceEntryComponent({
  maintenancePrice,
}: MaintenanceEntryComponentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [request, setRequest] = useState({
    amount: maintenancePrice.amount,
    amountOfMeasures: maintenancePrice.amountOfMeasures,
  });

  useEffect(() => {
    if (maintenancePrice.amount !== request.amount) {
      setRequest({ ...request, amount: maintenancePrice.amount });
    }
  }, [maintenancePrice.amount, request]);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
      <div className="flex items-center justify-between space-x-4 border-b px-4">
        <div className="grid h-12 w-full grid-cols-[300px_auto] items-center gap-4">
          <div className="truncate">{maintenancePrice.monitor.name}</div>
          <div className="grid grid-cols-5 gap-4">
            <div className="flex items-center justify-start gap-2">
              <Label
                htmlFor={`${maintenancePrice.calculationId}-amount-of-measures-input`}
              >
                {t("Anzahl")}
              </Label>
              <IntNumberInput
                id={`${maintenancePrice.calculationId}-amount-input`}
                onChange={(val) =>
                  setRequest({
                    amount: val,
                    amountOfMeasures: request.amountOfMeasures,
                  })
                }
                value={request.amount}
              />
            </div>
            <div className="flex items-center justify-start gap-2">
              <Label
                htmlFor={`${maintenancePrice.calculationId}-amount-of-measures-input`}
              >
                {t("Maßnahmen / Jahr")}
              </Label>
              <IntNumberInput
                onChange={(val) =>
                  setRequest({
                    amount: request.amount,
                    amountOfMeasures: val,
                  })
                }
                value={request.amountOfMeasures}
              />
            </div>
            <div className="flex items-center justify-start gap-2">
              <Label
                htmlFor={`${maintenancePrice.calculationId}-measure-rate-input`}
              >
                {t("Preis pro Maßnahme")}
              </Label>
              <FloatNumberInput
                id={`${maintenancePrice.calculationId}-measure-rate-input`}
                value={maintenancePrice.measureRate}
                fractions={2}
              />
            </div>
            <div className="flex items-center justify-start gap-2">
              <Label
                htmlFor={`${maintenancePrice.calculationId}-price-per-measure-input`}
              >
                {t("Einzelpreis je Maßnahme pro Gerät")}
              </Label>
              <Value
                id={`${maintenancePrice.calculationId}-price-per-measure-input`}
                value={maintenancePrice.pricePerMeasure}
                fractions={2}
              />
            </div>
            <div className="grid grid-cols-2 items-center justify-end gap-2 text-end">
              <Label
                htmlFor={`${maintenancePrice.calculationId}-total-price-input`}
              >
                {t("Gesamtpreis")}
              </Label>
              <Value
                id={`${maintenancePrice.calculationId}-total-price-input`}
                value={maintenancePrice.totalPrice}
              />
            </div>
          </div>
        </div>

        {/* Dropdown Menu */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="icon" variant="outline" className="h-8 w-8">
              <MoreVertical className="h-3.5 w-3.5" />
              <span className="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>
              <CollapsibleTrigger asChild>
                <Button variant="ghost">
                  <span className="">
                    {t("Verbrauchsmaterialien bearbeiten")}
                  </span>
                </Button>
              </CollapsibleTrigger>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <CollapsibleContent className="space-y-2 border-b">
        <div className="px-4 py-1.5">
          {[ConsumableTypeKey.Adhesive, ConsumableTypeKey.Tube].map(
            (key) =>
              maintenancePrice.consumables[key] &&
              maintenancePrice.consumables[key].map((consumable) => (
                <MaintenanceConsumableComponent
                  key={key}
                  typeKey={key}
                  consumable={consumable}
                />
              )),
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

export type MaintenanceConsumableComponentProps = {
  typeKey: ConsumableTypeKey;
  consumable: Consumable;
};

export function MaintenanceConsumableComponent({
  typeKey,
  consumable,
}: MaintenanceConsumableComponentProps) {
  const [request, setRequest] = useState({
    requiredAmount: consumable.requiredAmount,
    recommendedAmountOfMeasuresPerYear:
      consumable.recommendedAmountOfMeasuresPerYear,
  });
  return (
    <div
      key={consumable.consumableId}
      className="grid w-full grid-cols-4 gap-4 py-1.5"
    >
      <div>{t(typeKey)}</div>
      <div className="flex items-center justify-start gap-2">
        <Label htmlFor={`${consumable.consumableId}-amount-input`}>
          {t("Anzahl")}
        </Label>
        <IntNumberInput
          id={`${consumable.consumableId}-amount-input`}
          onChange={(val) =>
            setRequest({
              requiredAmount: val,
              recommendedAmountOfMeasuresPerYear:
                request.recommendedAmountOfMeasuresPerYear,
            })
          }
          value={request.requiredAmount}
        />
      </div>
      <div className="flex items-center justify-start gap-2">
        <Label htmlFor={`${consumable.consumableId}-amount-of-measures-input`}>
          {t("Maßnahmen / Jahr")}
        </Label>
        <IntNumberInput
          id={`${consumable.consumableId}-amount-of-measures-input`}
          onChange={(val) =>
            setRequest({
              requiredAmount: request.requiredAmount,
              recommendedAmountOfMeasuresPerYear: val,
            })
          }
          value={request.recommendedAmountOfMeasuresPerYear}
        />
      </div>
      <div className="flex items-center justify-start gap-2">
        <Label htmlFor={`${consumable.consumableId}-price-input`}>
          {t("Preis")}
        </Label>
        <FloatNumberInput
          id={`${consumable.consumableId}-price-input`}
          value={consumable.price}
          fractions={2}
        />
      </div>
    </div>
  );
}
