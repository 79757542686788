import { Position } from "@/services/backend/htz/work-order/work-order";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { useStaleContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/stale-context";
import { useCallback, useState } from "react";
import { artefactDbDelete } from "@/shared/indexdb/artefact-db";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { useHtzWorkOrderDeletePositionMutation } from "@/services/backend/htz/work-order/service";
import { Mutation } from "@/shared/indexdb/artefact-db-hooks";

export function useDeletePosition(): Mutation<Position, null> {
  const { offlineState } = useOfflineContext();
  const { addStale } = useStaleContext();

  const [idbIsLoading, setIdbIsLoading] = useState(false);
  const [idbError, setIdbError] = useState<unknown>();
  const [idbIsSuccess, setIdbIsSuccess] = useState<boolean>(false);
  const idbReset = useCallback(() => {
    setIdbError(undefined);
    setIdbIsSuccess(false);
  }, [setIdbError]);

  const deletePositionIndexDB = useCallback(
    (position: Position) => {
      setIdbIsLoading(true);

      // reset
      setIdbError(undefined);
      setIdbIsSuccess(false);

      artefactDbDelete(
        ArtefactKind.WorkOrder,
        position.workOrderId,
        "position",
        position.id,
      )
        .then(() => {
          addStale("position");
        })
        .catch(() => {
          setIdbError({ status: 500 });
        })
        .finally(() => {
          setIdbIsLoading(false);
        });
    },
    [addStale],
  );

  const [deletePositionMutation, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderDeletePositionMutation();

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [deletePositionMutation, { isLoading, error, isSuccess, reset }];
    case OfflineState.OFFLINE:
      return [
        deletePositionIndexDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        (position: Position) => {
          throw new Error(
            `Delete of ${position} not possible is state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}
