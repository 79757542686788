import { DocumentationComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/documentation-component";
import {
  ServiceCost,
  UpdateTravelingDurationRequest,
} from "@/services/backend/qpm/calculations/types";
import t from "@/lang/lang";
import {
  TimeNumberInput,
  TimeUnit,
} from "@/routes/gemex/processes/_components/ui/time-number-input";
import { CalculationServiceTypeUtil } from "@/services/backend/qpm/calculations/ServiceTypeUtil";
import { Label } from "@/shared/components/ui/label";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useQpmCalcServiceCostTravelingDurationPostMutation } from "@/services/backend/qpm/calculations/service";

export type AdditionalCostComponentProps = {
  calculationId: string;
  serviceCost: ServiceCost;
};

export function AdditionalCostComponent({
  calculationId,
  serviceCost,
}: AdditionalCostComponentProps) {
  const [request, setRequest] = useState<UpdateTravelingDurationRequest>({
    calculationId: serviceCost.calculationId,
    serviceCostId: serviceCost.id,
    travelingDuration: serviceCost.parameters.totalTravelingTime,
  });

  const [updateTargetRevenue, { isLoading, error, isSuccess, reset }] =
    useQpmCalcServiceCostTravelingDurationPostMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateTargetRevenue,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-start p-4">
        <div className="flex items-center justify-between gap-2">
          <Label
            htmlFor={`${serviceCost.id}-${serviceCost.serviceTypeKey}-total-traveling-time-input`}
          >
            {t("Gesamtfahrzeit")}
          </Label>
          <TimeNumberInput
            id={`${serviceCost.id}-${serviceCost.serviceTypeKey}-total-traveling-time-input`}
            value={serviceCost.parameters.totalTravelingTime}
            onChange={(val) => {
              setRequest({ ...request, travelingDuration: val });
            }}
            timeUnit={TimeUnit.Minutes}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="flex flex-col items-start gap-4 border-y p-4">
        <div className="flex items-center justify-between gap-2">
          <Label
            htmlFor={`${serviceCost.id}-${serviceCost.serviceTypeKey}-total-duration-documentation-input`}
          >
            {`${t(
              "Zusatzzeit-Dokumentation",
            )} ${CalculationServiceTypeUtil.toString(
              serviceCost.serviceTypeKey,
            )}`}
          </Label>
          <TimeNumberInput
            id={`${serviceCost.id}-${serviceCost.serviceTypeKey}-total-duration-documentation-input`}
            value={serviceCost.parameters.totalDurationDocumentation}
            timeUnit={TimeUnit.Minutes}
            disabled={isLoading}
          />
        </div>
        <DocumentationComponent
          calculationId={calculationId}
          serviceCostId={serviceCost.id}
          serviceTypeKey={serviceCost.serviceTypeKey}
        />
      </div>
    </div>
  );
}
