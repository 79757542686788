import { Kind } from "@/services/backend/samples/sample/kind";
import { useSamplesAirMicrobialSampleSetShowQuery } from "@/services/backend/samples/sample/air-microbial-service";
import t from "@/lang/lang";
import { useSamplesDustDensitySampleShowQuery } from "@/services/backend/samples/sample/dust-density-sample-service";
import { useSamplesSurfaceMicrobialSampleSetShowQuery } from "@/services/backend/samples/sample/surface-microbial-service";
import { useSamplesWaterSampleShowQuery } from "@/services/backend/samples/sample/water-sample-service";
import { Skeleton } from "@/shared/components/ui/skeleton";

export function SampleNumber({ kind, id }: { kind: Kind; id: string }) {
  switch (kind) {
    case Kind.AirMicrobial:
      return <AirMicrobialSampleSetNumber id={id} />;
    case Kind.DustDensity:
      return <DustDensitySampleNumber id={id} />;
    case Kind.SurfaceMicrobial:
      return <SurfaceMicrobialSampleSetNumber id={id} />;
    case Kind.Water:
      return <WaterSampleNumber id={id} />;
    default:
      return <span>{t("Nicht verfügbar")}</span>;
  }
}

function AirMicrobialSampleSetNumber({ id }: { id: string }) {
  const { data, isLoading, error } = useSamplesAirMicrobialSampleSetShowQuery({
    id,
  });

  if (isLoading) {
    return <Skeleton className="h-8 w-32" />;
  }

  if (error) {
    return <span className="text-destructive">{t("Nicht verfügbar")}</span>;
  }

  return <span>{data!.number}</span>;
}

function DustDensitySampleNumber({ id }: { id: string }) {
  const { data, isLoading, error } = useSamplesDustDensitySampleShowQuery({
    id,
  });

  if (isLoading) {
    return <Skeleton className="h-8 w-32" />;
  }

  if (error) {
    return <span className="text-destructive">{t("Nicht verfügbar")}</span>;
  }

  return <span>{data!.number}</span>;
}

function SurfaceMicrobialSampleSetNumber({ id }: { id: string }) {
  const { data, isLoading, error } =
    useSamplesSurfaceMicrobialSampleSetShowQuery({
      id,
    });

  if (isLoading) {
    return <Skeleton className="h-8 w-32" />;
  }

  if (error) {
    return <span className="text-destructive">{t("Nicht verfügbar")}</span>;
  }

  return <span>{data!.number}</span>;
}

function WaterSampleNumber({ id }: { id: string }) {
  const { data, isLoading, error } = useSamplesWaterSampleShowQuery({
    id,
  });

  if (isLoading) {
    return <Skeleton className="h-8 w-32" />;
  }

  if (error) {
    return <span className="text-destructive">{t("Nicht verfügbar")}</span>;
  }

  return <span>{data!.number}</span>;
}
