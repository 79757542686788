import { useOfflineContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import {
  useHtzAhuComponentCatalogShowActiveCatalogQuery,
  useHtzAhuComponentCatalogShowQuery,
} from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import { Query, useArtefactDBGetAll } from "@/shared/indexdb/artefact-db-hooks";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";
import { ArtefactKind } from "@/services/backend/artefacts/kind";

export function useGetAhuComponentCatalog(
  workOrderId: string,
): Query<AhuComponentCatalog> {
  const { offlineState } = useOfflineContext();

  const {
    data: activeAhuComponentCatalog,
    isLoading: activeAhuComponentCatalogIsLoading,
    error: activeAhuComponentCatalogError,
  } = useHtzAhuComponentCatalogShowActiveCatalogQuery({});

  const {
    data: ahuComponentCatalog,
    isLoading: ahuComponentCatalogIsLoading,
    error: ahuComponentCatalogError,
  } = useHtzAhuComponentCatalogShowQuery(
    {
      id: activeAhuComponentCatalog?.catalogId ?? "",
    },
    {
      skip:
        activeAhuComponentCatalog === undefined ||
        activeAhuComponentCatalog?.catalogId === null,
    },
  );

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGetAll<AhuComponentCatalog>(
    ArtefactKind.WorkOrder,
    workOrderId,
    "ahuComponentCatalog",
  );

  switch (offlineState) {
    case "ONLINE":
      return {
        data: ahuComponentCatalog,
        isLoading:
          activeAhuComponentCatalogIsLoading || ahuComponentCatalogIsLoading,
        error: activeAhuComponentCatalogError || ahuComponentCatalogError,
      };
    case "OFFLINE":
      return {
        data: indexedDBData ? indexedDBData[0] : undefined,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: { status: 500 },
      };
  }
}
