import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { X } from "lucide-react";
import t from "@/lang/lang";
import { useEffect, useState } from "react";
import { useToast } from "@/shared/hooks/use-toast";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Delivery } from "@/services/backend/samples/delivery/delivery";
import { useSamplesDeliveryCancelMutation } from "@/services/backend/samples/delivery/service";

export function CancelDeliveryDialog({ delivery }: { delivery: Delivery }) {
  const [open, setOpen] = useState(false);
  const [cancel, { isLoading, error, isSuccess, reset }] =
    useSamplesDeliveryCancelMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Lieferung storniert"),
        description: t("Die Lieferung wurde erfolgreich storniert."),
        variant: "success",
      });
      setOpen(false);
      reset();
    }
  }, [isSuccess, toast, reset]);

  const doCancel = () => {
    if (isLoading) return;

    cancel({ id: delivery.id });
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
        reset();
      }}
    >
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          <X />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Lieferung stornieren")}</DialogTitle>
          <DialogDescription>
            {t(
              "Ein Stornieren der Lieferung informiert das Labor, dass diese Lieferung nicht ankommen wird. Es ist danach möglich die in der Lieferung erfassten Proben in einer neuen Lieferung zu versenden. Die Stornierung kann nicht rückgängig gemacht werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" disabled={isLoading} onClick={doCancel}>
            {t("Lieferung stornieren")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
