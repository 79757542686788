import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import {
  DeleteRequest,
  PutRequest,
  useSamplesSurfaceMicrobialSampleSetDeleteMutation,
  useSamplesSurfaceMicrobialSampleSetListQuery,
  useSamplesSurfaceMicrobialSampleSetPutMutation,
  useSamplesSurfaceMicrobialSampleSetShowQuery,
} from "@/services/backend/samples/sample/surface-microbial-service";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  Mutation,
  useArtefactDBDelete,
  useArtefactDBGet,
  useArtefactDBGetAll,
  useArtefactDBPut,
} from "@/shared/indexdb/artefact-db-hooks";
import { SurfaceMicrobialSampleSet } from "@/services/backend/samples/sample/surface-microbial-sample";
import { FilterMode } from "@/shared/lib/filter/filter";
import { useParams } from "react-router-dom";

export function usePutSurfaceMicrobialSampleSet(): Mutation<
  SurfaceMicrobialSampleSet,
  SurfaceMicrobialSampleSet
> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [
    putSurfaceMicrobialSampleSetMutation,
    { isLoading, error, isSuccess, reset },
  ] = useSamplesSurfaceMicrobialSampleSetPutMutation();

  const [
    putSurfaceMicrobialSampleSetIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBPut<PutRequest, SurfaceMicrobialSampleSet>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "surfaceMicrobialSampleSet",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        putSurfaceMicrobialSampleSetMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        putSurfaceMicrobialSampleSetIndexedDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Put of sample set not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}

export function useGetSurfaceMicrobialSampleSet(id: string) {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesSurfaceMicrobialSampleSetShowQuery({
    id,
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGet<SurfaceMicrobialSampleSet>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "surfaceMicrobialSampleSet",
    id,
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useListSurfaceMicrobialSampleSets() {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesSurfaceMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrderId!],
    },
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGetAll<SurfaceMicrobialSampleSet>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "surfaceMicrobialSampleSet",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData?.sampleSets,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useDeleteSurfaceMicrobialSampleSet(): Mutation<
  DeleteRequest,
  null
> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [
    deleteSurfaceMicrobialSampleSetMutation,
    { isLoading, error, isSuccess, reset },
  ] = useSamplesSurfaceMicrobialSampleSetDeleteMutation();

  const [
    deleteSurfaceMicrobialSampleSetIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBDelete(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "surfaceMicrobialSampleSet",
  );
  const deleteIdb = async (request: DeleteRequest) => {
    const result = await deleteSurfaceMicrobialSampleSetIndexedDB(request.id);
    if (result) {
      return result;
    }
    // This should never happen.
    throw new Error("Delete of sample set failed.");
  };

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        deleteSurfaceMicrobialSampleSetMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        deleteIdb,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Delete of sample set not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}
