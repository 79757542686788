import { Button } from "@/shared/components/ui/button";
import { Plus, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Item } from "@/services/backend/htz/position-catalog/position-catalog";
import {
  newFromCatalog,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { usePutPosition } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-put-position";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useGetPositionCatalog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-get-position-catalog";

export function AddPositionDropdown({ workOrder }: { workOrder: WorkOrder }) {
  const { data: positionCatalog } = useGetPositionCatalog(
    workOrder.id,
    workOrder.companyId,
  );

  const [putPosition, { isLoading, error, reset }] = usePutPosition();
  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  if (!positionCatalog) {
    return null;
  }

  const { items: catalogPositions } = positionCatalog;
  const categories = uniqueCategories(catalogPositions);

  const onAdd = (catPos: Item) => {
    if (isLoading) {
      return;
    }

    const newPosition = newFromCatalog(catPos, workOrder.id);
    putPosition(newPosition);
  };

  const disabled = isLoading || workOrder.immutable;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button size="sm" disabled={disabled}>
          {!isLoading && <Plus className="mr-2 h-4 w-4" />}
          {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
          <span>{t("Position")}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {categories.map((category) => (
          <DropdownMenuGroup key={category}>
            <DropdownMenuLabel>{category}</DropdownMenuLabel>
            {catalogPositions.filter(catFltr(category)).map((pos) => (
              <DropdownMenuItem key={pos.id} onClick={() => onAdd(pos)}>
                {pos.title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function uniqueCategories(positions: Item[]): string[] {
  return positions.reduce((acc, cur) => {
    if (acc.indexOf(cur.category) !== -1) {
      return acc;
    }
    return [...acc, cur.category];
  }, [] as string[]);
}

function catFltr(cat: string) {
  return (pos: Item) => pos.category === cat;
}
