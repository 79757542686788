import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { Component } from "@/services/backend/htz/ahu/air-handling-unit";
import { Position } from "@/services/backend/htz/work-order/work-order";
import { Mutation } from "@/shared/indexdb/artefact-db-hooks";

interface InspectionContextInterface {
  initialized: boolean;
  active: boolean;
  setActive: (value: boolean) => void;
  activeComponent: Component | null;
  setActiveComponent: (c: Component | null) => void;
  position: Position;
  onPositionChange: Mutation<Position, Position>;
  disabled?: boolean;
}

const InspectionContext = createContext<InspectionContextInterface>({
  initialized: false,
  active: false,
  setActive: () => null,
  activeComponent: null,
  setActiveComponent: () => null,
  position: {} as Position,
  onPositionChange: [
    () => new Promise(() => {}),
    {
      isLoading: false,
      error: undefined,
      isSuccess: false,
      reset: () => null,
    },
  ],
  disabled: false,
});

export function useInspectionContext() {
  return useContext(InspectionContext);
}

export function InspectionContextProvider({
  position,
  onPositionChange,
  disabled,
  children,
}: {
  position: Position;
  onPositionChange: Mutation<Position, Position>;
  disabled?: boolean;
  children: ReactNode;
}) {
  const [active, setActive] = useState<boolean>(false);
  const [activeComponent, setActiveComponent] = useState<Component | null>(
    null,
  );

  const value = useMemo(
    () => ({
      initialized: true,
      active,
      setActive,
      activeComponent,
      setActiveComponent,
      position,
      onPositionChange,
      disabled,
    }),
    [active, activeComponent, position, onPositionChange, disabled],
  );

  return (
    <InspectionContext.Provider value={value}>
      {children}
    </InspectionContext.Provider>
  );
}
